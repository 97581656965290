@font-face {
  font-family: 'icomoon';
  src:  url('fonts/icomoon78ed.eot?orkqwr');
  src:  url('fonts/icomoon78ed.eot?orkqwr#iefix') format('embedded-opentype'),
    url('fonts/icomoon78ed.ttf?orkqwr') format('truetype'),
    url('fonts/icomoon78ed.woff?orkqwr') format('woff'),
    url('fonts/icomoon78ed.svg?orkqwr#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}








.icon-right-quotation-mark:before {
  content: "\e900";
}
.icon-down-arrow:before {
  content: "\e901";
}
.icon-search:before {
  content: "\e902";
}
.icon-agenda:before {
  content: "\e903";
}
.icon-checked:before {
  content: "\e904";
}
.icon-pin:before {
  content: "\e905";
}
.icon-letter:before {
  content: "\e906";
}
.icon-plus:before {
  content: "\e907";
}
.icon-coffee-cup:before {
  content: "\e908";
}
.icon-advisor:before {
  content: "\e909";
}
.icon-dashboard:before {
  content: "\e90a";
}
.icon-success:before {
  content: "\e90b";
}
.icon-phone-call:before {
  content: "\e90c";
}
.icon-envelope:before {
  content: "\e90d";
}
.icon-clock:before {
  content: "\e90e";
}
.icon-brainstorm:before {
  content: "\e90f";
}
.icon-compliant:before {
  content: "\e910";
}
.icon-analysis:before {
  content: "\e911";
}
.icon-right-arrow .path1:before {
  content: "\e912";
  color: rgb(0, 150, 136);
}
.icon-right-arrow .path2:before {
  content: "\e913";
  margin-left: -1em;
  color: rgb(0, 150, 136);
}
.icon-right-arrow .path3:before {
  content: "\e914";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-right-arrow .path4:before {
  content: "\e915";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-left:before {
  content: "\e916";
}
.icon-download:before {
  content: "\e917";
}
.icon-pdf-file:before {
  content: "\e918";
}
.icon-right-arrow-angle:before {
  content: "\e919";
}
.icon-dollar:before {
  content: "\e91a";
}
.icon-browser:before {
  content: "\e91b";
}
.icon-idea:before {
  content: "\e91c";
}
.icon-product:before {
  content: "\e91d";
}
.icon-data-security:before {
  content: "\e91e";
}
.icon-setting:before {
  content: "\e91f";
}
.icon-strategy:before {
  content: "\e920";
}
.icon-magnifying-glass:before {
  content: "\e921";
}
.icon-piggy-bank:before {
  content: "\e922";
}
.icon-user:before {
  content: "\e923";
}
.icon-right:before {
  content: "\e924";
}
.icon-left-arrow:before {
  content: "\e925";
}
.icon-left-arrow-1 .path1:before {
  content: "\e926";
  color: rgb(0, 150, 136);
}
.icon-left-arrow-1 .path2:before {
  content: "\e927";
  margin-left: -1em;
  color: rgb(0, 150, 136);
}
.icon-left-arrow-1 .path3:before {
  content: "\e928";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-left-arrow-1 .path4:before {
  content: "\e929";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}





