/*--------------------------------------------------------------
>>> TABLE OF CONTENTS:
----------------------------------------------------------------
# Utility
# Cards
# Common
# Form
# Navigations
# Animations
# Mobile Nav
# Search Popup
# Page Header
# Google Map
# Client Carousel
--------------------------------------------------------------*/
@import url("https://fonts.googleapis.com/css2?family=Manrope:wght@300;400;500;600;700;800&amp;display=swap");
@import url("https://fonts.googleapis.com/css2?family=DM+Sans:ital,wght@0,400;0,500;0,700;1,400;1,500;1,700&amp;display=swap");
@import url("https://fonts.googleapis.com/css2?family=Italianno&amp;display=swap");
/*--------------------------------------------------------------
# Common
--------------------------------------------------------------*/
:root {
    --thm-font: 'DM Sans', sans-serif;
    --thm-manrope-font: 'Manrope', sans-serif;
    --thm-italianno-font: 'Italianno', cursive;
    --thm-base: #f94d1c;
    --thm-base-rgb: 149, 77, 28;
    --thm-primary: #f94d1c;
    --thm-primary-rgb: 249, 77, 28;
    --thm-gray: #74787c;
    --thm-gray-rgb: 116, 120, 124;
    --thm-black: #1a1a1a;
    --thm-black-rgb: 26, 26, 26;
}



.row {
    --bs-gutter-x: 30px;
}

.gutter-y-30 {
    --bs-gutter-y: 30px;
}

body {
    font-family: var(--thm-font);
    color: var(--thm-gray);
    font-size: 17px;
    line-height: 32px;
    font-weight: 400;
}

body.locked {
    overflow: hidden;
}

a {
    color: var(--thm-black);
}

a,
a:hover,
a:focus,
a:visited {
    text-decoration: none;
}

::-webkit-input-placeholder {
    color: inherit;
    opacity: 1;
}

::-moz-placeholder {
    color: inherit;
    opacity: 1;
}

:-ms-input-placeholder {
    color: inherit;
    opacity: 1;
}

::-ms-input-placeholder {
    color: inherit;
    opacity: 1;
}

::placeholder {
    color: inherit;
    opacity: 1;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    color: var(--thm-black);
    font-weight: 700;
    font-family: var(--thm-manrope-font);
    letter-spacing: -0.03em;
    margin: 0;
}


.noSelect {
    -ms-user-select: none;
    user-select: none;
    -o-user-select: none;
    -moz-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none;
}


dl,
ol,
ul {
    margin-top: 0;
    margin-bottom: 0;
}

::-webkit-input-placeholder {
    color: inherit;
    opacity: 1;
}

::-moz-placeholder {
    color: inherit;
    opacity: 1;
}

:-ms-input-placeholder {
    color: inherit;
    opacity: 1;
}

::-ms-input-placeholder {
    color: inherit;
    opacity: 1;
}

::placeholder {
    color: inherit;
    opacity: 1;
}


.auto-container {
    position: static;
    max-width: 1200px;
    padding: 0px 15px;
    margin: 0 auto;
}


.page-wrapper {
    position: relative;
    margin: 0 auto;
    width: 100%;
    min-width: 300px;
    overflow: hidden;
}

.container {
    padding-left: 15px;
    padding-right: 15px;
}

@media (min-width: 1200px) {
    .container {
        max-width: 1200px;
    }
}




.bootstrap-select .btn-light:not(:disabled):not(.disabled).active,
.bootstrap-select .btn-light:not(:disabled):not(.disabled):active,
.bootstrap-select .show>.btn-light.dropdown-toggle {
    box-shadow: none !important;
    outline: none !important;
}

.bootstrap-select>.dropdown-toggle {
    box-shadow: none !important;
}

.bootstrap-select .dropdown-toggle:focus,
.bootstrap-select>select.mobile-device:focus+.dropdown-toggle {
    outline: none !important;
}

.bootstrap-select .dropdown-menu {
    border: 0;
    padding-top: 0;
    padding-bottom: 0;
    margin-top: 0;
    z-index: 991;
    border-radius: 0;
}

.bootstrap-select .dropdown-menu>li+li>a {
    border-top: 1px solid rgba(255, 255, 255, 0.2);
}

.bootstrap-select .dropdown-menu>li.selected>a {
    background: var(--thm-primary);
    color: #fff;
}

.bootstrap-select .dropdown-menu>li>a {
    font-size: 16px;
    font-weight: 500;
    padding: 4px 20px;
    color: #ffffff;
    background: var(--thm-black);
    transition: all 0.4s ease;
}

.bootstrap-select .dropdown-menu>li>a:hover {
    background: var(--thm-primary);
    color: #fff;
    cursor: pointer;
}





::-webkit-input-placeholder {
    color: inherit;
    opacity: 1;
}





::-moz-placeholder {
    color: inherit;
    opacity: 1;
}





:-ms-input-placeholder {
    color: inherit;
    opacity: 1;
}





::-ms-input-placeholder {
    color: inherit;
    opacity: 1;
}





::placeholder {
    color: inherit;
    opacity: 1;
}

.section-separator {
    border-color: var(--thm-border);
    border-width: 1px;
    margin-top: 0;
    margin-bottom: 0;
}

.thm-btn {
    position: relative;
    display: inline-block;
    vertical-align: middle;
    -webkit-appearance: none;
    border: none;
    outline: none !important;
    background-color: var(--thm-base);
    color: #ffffff;
    font-size: 16px;
    font-weight: 700;
    text-transform: capitalize;
    padding: 14px 40px 15px;
    border-radius: 7px;
    font-family: var(--thm-manrope-font);
    transition: all 0.3s linear;
    z-index: 1;
    overflow: hidden;
}

.thm-btn:after {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: var(--thm-black);
    content: "";
    border-radius: 7px;
    transform: perspective(200px) scaleX(0.1) rotateX(90deg) translateZ(-10px);
    transform-origin: bottom center;
    transition: transform 0.4s linear, transform 0.4s linear;
    z-index: -1;
}

.thm-btn:hover:after {
    transform: perspective(200px) scaleX(1.05) rotateX(0deg) translateZ(0);
    transition: transform 0.4s linear, transform 0.4s linear;
}

.thm-btn:hover {
    color: #ffffff;
}

.thm-btn i::before {
    position: relative;
    font-size: 18px;
    top: 3px;
    padding-left: 5px;
}


.section-title {
    position: relative;
    display: block;
    margin-top: -8px;
    margin-bottom: 50px;
}

.section-title__tagline {
    position: relative;
    display: block;
    margin-bottom: 17px;
}

.section-title__tagline span.left {
    position: relative;
    display: inline-block;
    width: 40px;
    height: 3px;
    background: var(--thm-base);
    margin-right: 15px;
    top: -5px;
}

.section-title__tagline span.right {
    position: relative;
    display: inline-block;
    width: 40px;
    height: 3px;
    background: var(--thm-base);
    margin-left: 14px;
    top: -5px;
}

.section-title__tagline h4 {
    position: relative;
    display: inline-block;
    color: var(--thm-base);
    font-size: 20px;
    line-height: 28px;
    font-weight: 700;
    text-transform: capitalize;
    font-family: var(--thm-font);
}

.section-title__title {
    font-size: 52px;
    line-height: 1.2em;
    font-weight: 800;
    text-transform: capitalize;
    letter-spacing: -0.03em;
}




.bootstrap-select .dropdown-menu {
    padding-top: 0;
    padding-bottom: 0;
    border-radius: 0;
}

.bootstrap-select .dropdown-item.active,
.bootstrap-select .dropdown-item:active {
    background-color: var(--thm-base);
}

.preloader {
    position: fixed;
    background-color: var(--thm-black);
    background-position: center center;
    background-repeat: no-repeat;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 999999999999999;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.preloader__image {
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    -webkit-animation-name: flipInY;
    animation-name: flipInY;
    -webkit-animation-duration: 2s;
    animation-duration: 2s;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    background-blend-mode: multiply;
}

/* scroll to top */
.scroll-to-top {
    display: inline-block;
    width: 45px;
    height: 45px;
    background: var(--thm-base);
    position: fixed;
    bottom: 40px;
    right: 40px;
    z-index: 99;
    text-align: center;
    transition: all 0.4s ease;
    display: none;
    border-radius: 50%;
    transition: all 0.4s ease;
}

.scroll-to-top i {
    color: #ffffff;
    font-size: 18px;
    line-height: 45px;
}

.scroll-to-top:hover {
    background-color: var(--thm-black);
}

.scroll-to-top:hover i {
    color: #fff;
}




/*----------------------------------------
# Main Header One
-----------------------------------------*/

.main-header {
    position: relative;
    display: block;
}

.main-header--one {
    position: absolute;
    left: 0px;
    top: 0px;
    width: 100%;
    z-index: 999;
    transition: all 500ms ease;
    -moz-transition: all 500ms ease;
    -webkit-transition: all 500ms ease;
    -ms-transition: all 500ms ease;
    -o-transition: all 500ms ease;
}


.main-header-one__bottom {
    position: relative;
    display: block;
    background: transparent;
}

.main-header-one__bottom::before {
    position: absolute;
    left: 0;
    bottom: 0;
    right: 0;
    height: 1px;
    background: #e5e5e5;
    content: "";
}

.main-header-one__bottom .auto-container {
    max-width: 100%;
    padding: 0;
}

.main-header-one__bottom-inner {
    position: relative;
    display: block;
    z-index: 10;
}

.main-header-one__bottom-inner .main-menu {
    position: relative;
    display: block;
}


.main-menu {
    position: relative;
    z-index: 91;
}

.main-menu__inner {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    -ms-flex-wrap: wrap;
    flex-direction: row;
    flex-wrap: wrap;
}

.main-menu__inner .left {
    position: relative;
    display: block;
}

.logo-box1 {
    position: relative;
    display: block;
    float: left;
    width: 330px;
    height: 100px;
    background: var(--thm-base);
    display: flex;
    align-items: center;
    justify-content: center;
}

.logo-box1_bg {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-size: cover;
    background-repeat: no-repeat;
    opacity: 0.15;
}

.logo-box1 img {
    image-rendering: -moz-crisp-edges;
    image-rendering: -o-crisp-edges;
    image-rendering: -webkit-optimize-contrast;
    image-rendering: crisp-edges;
    -ms-interpolation-mode: nearest-neighbor;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    -ms-transform: translateZ(0);
    -webkit-transform: translateZ(0);
    transform: translateZ(0);

}

.logo-box1 a {
    position: relative;
    display: inline-block;
}


.main-menu__inner .middle {
    display: flex;
    align-items: center;
}

.main-menu .main-menu__list,
.main-menu .main-menu__list ul,
.stricky-header .main-menu__list,
.stricky-header .main-menu__list ul {
    margin: 0;
    padding: 0;
    list-style-type: none;
    align-items: center;
    display: none;
}

.main-menu .main-menu__list>li,
.stricky-header .main-menu__list>li {
    position: relative;
    padding-top: 34px;
    padding-bottom: 34px;
}

.main-menu .main-menu__list>li+li,
.stricky-header .main-menu__list>li+li {
    margin-left: 35px;
}

.main-menu .main-menu__list>li>a,
.stricky-header .main-menu__list>li>a {
    position: relative;
    color: var(--thm-black);
    font-size: 17px;
    font-weight: 800;
    display: flex;
    align-items: center;
    text-transform: none;
    transition: all 500ms ease;
    font-family: var(--thm-manrope-font);
}

.main-menu .main-menu__list>li.current>a,
.main-menu .main-menu__list>li:hover>a,
.stricky-header .main-menu__list>li.current>a,
.stricky-header .main-menu__list>li:hover>a {
    color: var(--thm-base);
}

.main-menu .main-menu__list>li.dropdown a,
.stricky-header .main-menu__list>li.dropdown a {
    padding-right: 15px;
}

.main-menu .main-menu__list>li.dropdown>a:after,
.stricky-header .main-menu__list>li.dropdown>a:after {
    font-family: 'icomoon' !important;
    content: "\e901";
    position: absolute;
    right: 0;
    top: 2px;
    display: block;
    line-height: 30px;
    font-size: 10px;
    z-index: 5;
}


.main-menu .main-menu__list>li>a::before,
.stricky-header .main-menu__list>li>a::before {
    content: '';
    width: 100%;
    height: 3px;
    background-color: var(--thm-base);
    position: absolute;
    left: 0;
    bottom: -34px;
    transition: transform 500ms ease;
    transform: scale(0, 1);
    transform-origin: left center;
    z-index: -1;
    border-radius: 1.5px;
}

.main-menu .main-menu__list>li.current>a::before,
.main-menu .main-menu__list>li:hover>a::before,
.stricky-header .main-menu__list>li.current>a::before,
.stricky-header .main-menu__list>li:hover>a::before {
    transform: scale(1, 1);
    transform-origin: right center;
}

.main-menu .main-menu__list li ul,
.stricky-header .main-menu__list li ul {
    position: absolute;
    top: 100%;
    left: 0;
    min-width: 220px;
    background-color: #fff;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    opacity: 0;
    visibility: hidden;
    transition: 500ms ease;
    z-index: 99;
    box-shadow: 0px 0px 65px 0px rgba(0, 0, 0, 0.1);
}

.main-menu .main-menu__list li:hover>ul,
.stricky-header .main-menu__list li:hover>ul {
    opacity: 1;
    visibility: visible;
}

.main-menu .main-menu__list li ul li,
.stricky-header .main-menu__list li ul li {
    flex: 1 1 100%;
    width: 100%;
    position: relative;
}

.main-menu .main-menu__list li ul li+li,
.stricky-header .main-menu__list li ul li+li {
    border-top: 1px solid RGBA(var(--thm-black), 0.1);
}

.main-menu .main-menu__list li ul li a,
.stricky-header .main-menu__list li ul li a {
    font-size: 16px;
    line-height: 28px;
    color: var(--thm-black);
    font-weight: 500;
    display: flex;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 10px;
    padding-bottom: 10px;
    transition: 500ms;
    font-family: var(--thm-manrope-font);
}

.main-menu .main-menu__list li ul li:hover>a,
.stricky-header .main-menu__list li ul li:hover>a {
    background-color: var(--thm-base);
    color: #fff;
}

.main-menu .main-menu__list li ul li>ul,
.stricky-header .main-menu__list li ul li>ul {
    top: 0;
    left: 100%;
}

.main-menu .main-menu__list li ul li>ul.right-align,
.stricky-header .main-menu__list li ul li>ul.right-align {
    top: 0;
    left: auto;
    right: 100%;
}

.main-menu .main-menu__list li ul li>ul ul,
.stricky-header .main-menu__list li ul li>ul ul {
    display: none;
}


.search-box {
    position: relative;
    display: block;
    padding-left: 23px;
    margin-top: 2px;
}

.main-menu__search {
    position: relative;
    display: block;
    color: #2e2e36;
    font-size: 15px;
    transition: all 500ms ease;
    font-weight: 700;
}

.main-menu__search:hover {
    color: var(--thm-base);
}


.main-menu__inner .right {
    position: relative;
    width: 340px;
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.main-menu__inner .right:before {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    width: 1px;
    background: #e0e0e0;
    content: "";
}

.main-menu__right {
    position: relative;
    display: flex;
    align-items: center;
}

.main-menu__right .phone_number {
    position: relative;
    display: flex;
    align-items: center;
}

.main-menu__right .phone_number .icon {
    position: relative;
    display: inline-block;
    color: var(--thm-base);
    font-size: 40px;
}

.main-menu__right .phone_number .number {
    position: relative;
    display: block;
    padding-left: 11px;
}

.main-menu__right .phone_number .number p {
    font-size: 18px;
    line-height: 24px;
    font-weight: 700;
    letter-spacing: -0.03em;
    margin: 0;
    font-family: var(--thm-manrope-font);
}

.main-menu__right .phone_number .number a {
    color: #2e2e36;
    font-size: 18px;
    font-weight: 700;
    letter-spacing: -0.03em;
    transition: all 500ms ease;
}

.main-menu__right .phone_number .number a:hover {
    color: var(--thm-base);
}





.stricky-header {
    position: fixed;
    z-index: 991;
    top: 0;
    left: 0;
    background-color: #fff;
    width: 100%;
    visibility: hidden;
    transform: translateY(-120%);
    transition: transform 500ms ease, visibility 500ms ease;
    box-shadow: 0px 10px 60px 0px rgba(0, 0, 0, 0.05);
}

.stricky-header.main-menu {
    padding: 0 0px;
}

.stricky-header.stricky-fixed {
    transform: translateY(0);
    visibility: visible;
}

.stricky-header .main-menu__inner {
    box-shadow: none;
    padding-right: 0;
    max-width: 1200px;
    width: 100%;
    margin: 0 auto;
    padding: 0 15px
}

.mobile-nav__buttons {
    display: flex;
    margin-left: auto;
    margin-right: 10px;
}

.stricky-header .main-menu__list>li {
    padding-top: 34px;
    padding-bottom: 34px;
}

.stricky-header .main-menu__inner .right {
    display: none;
}



@media (min-width: 1200px) {

    .main-menu .main-menu__list,
    .main-menu .main-menu__list ul,
    .stricky-header .main-menu__list,
    .stricky-header .main-menu__list ul {
        display: flex;
    }

}


@media (max-width: 1199px) {

    .stricky-header {
        display: none !important
    }

}


@media (min-width: 1200px) {

    .mobile-nav__buttons {
        display: none;
    }

}


@media (min-width: 1200px) {

    .main-menu .mobile-nav__toggler {
        display: none;
    }

}





.mobile-nav__buttons a {
    font-size: 20px;
    color: var(--thm-black);
    cursor: pointer;
}

.mobile-nav__buttons a+a {
    margin-left: 10px;
}

.mobile-nav__buttons a:hover {
    color: var(--thm-base);
}

.main-menu .mobile-nav__toggler {
    font-size: 20px;
    color: var(--thm-base);
    cursor: pointer;
    transition: 500ms;
    margin-right: 0px;
}

.main-menu .mobile-nav__toggler:hover {
    color: var(--thm-black);
}







/*----------------------------------------
# Main Header Two
-----------------------------------------*/

.main-header--two {
    position: absolute;
    left: 0px;
    top: 0px;
    width: 100%;
    z-index: 999;
    transition: all 500ms ease;
    -moz-transition: all 500ms ease;
    -webkit-transition: all 500ms ease;
    -ms-transition: all 500ms ease;
    -o-transition: all 500ms ease;
}

.stricky-header--style2 .main-menu__inner .left {
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
}



.stricky-header--style2 .search-box {
    display: none;
}



.main-header-two__bottom {
    position: relative;
    display: block;
    background: transparent;
}

.main-header-two__bottom::before {
    position: absolute;
    left: 0;
    bottom: 0;
    right: 0;
    height: 1px;
    background: #ececec;
    content: "";
}

.main-header-two__bottom .auto-container {
    max-width: 100%;
    padding: 0;
    padding-left: 70px;
}

.main-header-two__bottom-inner {
    position: relative;
    display: block;
    z-index: 10;
}

.main-header-two__bottom-inner .main-menu {
    position: relative;
    display: block;
}

.main-header-two__bottom-inner .main-menu .left {
    position: relative;
    display: flex;
    align-items: center;
}

.main-header-two__bottom-inner .main-menu .left .main-menu__list {
    margin-left: 150px;
}

.logo-box2 {
    position: relative;
    display: block;
    float: left;
}

.logo-box2 img {
    image-rendering: -moz-crisp-edges;
    image-rendering: -o-crisp-edges;
    image-rendering: -webkit-optimize-contrast;
    image-rendering: crisp-edges;
    -ms-interpolation-mode: nearest-neighbor;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    -ms-transform: translateZ(0);
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
}

.logo-box2 a {
    position: relative;
    display: inline-block;
}

.main-header-two__bottom-inner .main-menu__inner .right {
    width: 300px;
}




/*----------------------------------------
# Main Header Three
-----------------------------------------*/
.main-header--three {
    position: relative;
    left: 0px;
    top: 0px;
    width: 100%;
    z-index: 999;
    transition: all 500ms ease;
    -moz-transition: all 500ms ease;
    -webkit-transition: all 500ms ease;
    -ms-transition: all 500ms ease;
    -o-transition: all 500ms ease;
}

.stricky-header--style3 .main-menu__inner .left {
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
}

.stricky-header--style3 .main-header-three__bottom_right {
    display: none;
}


.main-header-three__top {
    position: relative;
    display: block;
    background: #ffffff;
    border-bottom: 1px solid #ececec;
}

.main-header-three__top-inner {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    -ms-flex-wrap: wrap;
    flex-direction: row;
    flex-wrap: wrap;
}


.main-header-three__top-left {
    position: relative;
    display: block;
    width: 100%;
}

.main-header-three__top-left ul {
    position: relative;
    display: flex;
}

.main-header-three__top-left ul li {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 33.3333333333%;
    padding-left: 60px;
    border-left: 1px solid #ececec;
    padding: 20px 0px 15px;
}

.main-header-three__top-left ul li:last-child {
    border-right: 1px solid #ececec;
}


.main-header-three__top-left ul li .icon {
    position: relative;
    display: inline-block;
    padding-right: 10px;
}

.main-header-three__top-left ul li .icon span::before {
    color: var(--thm-base);
    font-size: 20px;
    font-weight: 700;
}

.main-header-three__top-left ul li .text {
    position: relative;
    display: block;
}

.main-header-three__top-left ul li .text p {
    color: #1b1d21;
    font-size: 18px;
    line-height: 28px;
    font-weight: 700;
    margin: 0;
    font-family: var(--thm-manrope-font);
}

.main-header-three__top-left ul li .text p a {
    color: #60626d;
    font-weight: 400;
    transition: all 500ms ease;
}

.main-header-three__top-left ul li .text p a:hover {
    color: var(--thm-base);
}

.main-header-three__top-left ul li .text p span {
    color: #60626d;
    font-weight: 400;
}



.main-header-three__top-right {
    position: absolute;
    top: 0;
    right: -305px;
}

.main-header-three__top-right .search-box-two {
    position: relative;
    display: block;
    width: 275px;
}

.main-header-three__top-right .search-box-two form {
    position: relative;
    display: block;
}



.main-header-three__top-right .search-box-two form .form-group input[type="search"] {
    color: #1b1d21;
    font-size: 18px;
    height: 67px;
    width: 100%;
    font-weight: 700;
    padding-left: 0px;
    padding-right: 30px;
    background: #ffffff;
    border-radius: 0px;
    outline: none;
    border: none;
    font-family: var(--thm-manrope-font);
}

.main-header-three__top-right .search-box-two form .form-group button {
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    padding: 0;
    outline: none;
    border: none;
    background: transparent;
}

.main-header-three__top-right .search-box-two form .form-group button i::before {
    font-size: 15px;
    color: #1b1d21;
    font-weight: 700;
}



.main-header-three__bottom {
    position: relative;
    display: block;
    background: #ffffff;
}

.main-header-three__bottom-inner {
    position: relative;
    display: block;
    border-right: 1px solid #ececec;
    z-index: 10;
}

.main-header-three__bottom-inner .main-menu {
    position: relative;
    display: block;
}

.main-header-three__bottom-inner .main-menu .left {
    position: relative;
    display: flex;
    align-items: center;
}

.main-header-three__bottom-inner .main-menu .left .main-menu__list {
    margin-left: 100px;
}



.logo-box3 {
    position: relative;
    display: block;
    float: left;
    width: 300px;
    text-align: center;
    padding-top: 33px;
    padding-bottom: 2px;
    z-index: 1;
}

.logo-box3:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    bottom: -30px;
    right: 0;
    background: var(--thm-base);
    z-index: -1;
}

.logo-box3 a {
    position: relative;
    display: inline-block;
}

.logo-box3 img {
    image-rendering: -moz-crisp-edges;
    image-rendering: -o-crisp-edges;
    image-rendering: -webkit-optimize-contrast;
    image-rendering: crisp-edges;
    -ms-interpolation-mode: nearest-neighbor;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    -ms-transform: translateZ(0);
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    height: 45px;
}

.main-header-three__bottom_right {
    position: absolute;
    top: 0;
    right: -305px;
    padding: 19px 0 20px;
}

.main-menu__right .btn-box {
    position: relative;
    display: inline-block;
}

.main-menu__right .btn-box .thm-btn {
    background: #f7f7f7;
    color: #1b1d21;
    width: 275px;
    text-align: center;
}

.main-menu__right .btn-box .thm-btn::after {
    background: var(--thm-base);
}

.main-menu__right .btn-box .thm-btn:hover {
    color: #ffffff;
}







/*----------------------------------------
# Main Header Four
-----------------------------------------*/

.stricky-header--style4 .main-menu__inner .left {
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
}

.stricky-header--style4 .main-header-four__bottom_right {
    display: none;
}


.main-header-four__top {
    position: relative;
    display: block;
}

.main-header-four__top .auto-container {
    max-width: 1410px;
}

.main-header-four__top-inner {
    border-right: 1px solid #ececec;
}

.main-header-four__top-left {
    width: 90%;
}

.main-header-four__top-left ul li {
    justify-content: left;
    padding-left: 60px;
}

.main-header-four__top-right {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    flex-wrap: wrap;
    top: 0;
    right: 0;
    width: 10%;
    float: right;
}

.main-header-four__top-right .search-box-two {
    width: auto;
}

.main-header-four__top-right .search-box-two span {
    position: relative;
    float: left;
    color: #2e2e36;
    font-size: 18px;
    font-weight: 700;
    padding-right: 10px;
}

.main-header-four__top-right .search-box-two .main-menu__search {
    position: relative;
    float: left;
    color: var(--thm-base);
    font-size: 15px;
    line-height: 32px;
}


.main-header-four__bottom {
    position: relative;
    display: block;
}

.main-header-four__bottom .auto-container {
    max-width: 1410px;
}

.main-header-four__bottom-inner {
    border-right: 0px solid;
}

.logo-box3--style2 {
    width: 300px;
}

.logo-box3--style2 img {
    height: 48px;
}

.main-header-four__bottom_right {
    position: relative;
    top: 0;
    right: 0;
}

.main-header-four__bottom_right .main-menu__right .btn-box .thm-btn {
    padding: 14px 0px 15px;
    width: 240px;
    text-align: center;
}











/*--------------------------------------------------------------
# Animations
--------------------------------------------------------------*/
@-webkit-keyframes bubbleMover {
    0% {
        transform: translateY(0px) translateX(0) rotate(0);
    }

    30% {
        transform: translateY(30px) translateX(50px) rotate(15deg);
        transform-origin: center center;
    }

    50% {
        transform: translateY(50px) translateX(100px) rotate(45deg);
        transform-origin: right bottom;
    }

    80% {
        transform: translateY(30px) translateX(50px) rotate(15deg);
        transform-origin: left top;
    }

    100% {
        transform: translateY(0px) translateX(0) rotate(0);
        transform-origin: center center;
    }
}

@keyframes bubbleMover {
    0% {
        transform: translateY(0px) translateX(0) rotate(0);
    }

    30% {
        transform: translateY(30px) translateX(50px) rotate(15deg);
        transform-origin: center center;
    }

    50% {
        transform: translateY(50px) translateX(100px) rotate(45deg);
        transform-origin: right bottom;
    }

    80% {
        transform: translateY(30px) translateX(50px) rotate(15deg);
        transform-origin: left top;
    }

    100% {
        transform: translateY(0px) translateX(0) rotate(0);
        transform-origin: center center;
    }
}

@-webkit-keyframes shapeMover {

    0%,
    100% {
        transform: perspective(400px) translateY(0) rotate(0deg) translateZ(0px) translateX(0);
    }

    50% {
        transform: perspective(400px) rotate(0deg) translateZ(20px) translateY(20px) translateX(20px);
    }
}

@keyframes shapeMover {

    0%,
    100% {
        transform: perspective(400px) translateY(0) rotate(0deg) translateZ(0px) translateX(0);
    }

    50% {
        transform: perspective(400px) rotate(0deg) translateZ(20px) translateY(20px) translateX(20px);
    }
}

@-webkit-keyframes banner3Shake {
    0% {
        transform: rotate3d(0, 1, 0, 0deg);
    }

    30% {
        transform: rotate3d(0, 0, 1, 5deg);
    }

    60% {
        transform: rotate3d(1, 0, 0, 0deg);
    }

    80% {
        transform: rotate3d(0, 0, 1, 5deg);
    }

    100% {
        transform: rotate3d(0, 1, 0, 0deg);
    }
}

@keyframes banner3Shake {
    0% {
        transform: rotate3d(0, 1, 0, 0deg);
    }

    30% {
        transform: rotate3d(0, 0, 1, 5deg);
    }

    60% {
        transform: rotate3d(1, 0, 0, 0deg);
    }

    80% {
        transform: rotate3d(0, 0, 1, 5deg);
    }

    100% {
        transform: rotate3d(0, 1, 0, 0deg);
    }
}

@-webkit-keyframes squareMover {

    0%,
    100% {
        transform: translate(0, 0) rotate(0);
    }

    20%,
    60% {
        transform: translate(20px, 40px) rotate(180deg);
    }

    30%,
    80% {
        transform: translate(40px, 60px) rotate(0deg);
    }
}

@keyframes squareMover {

    0%,
    100% {
        transform: translate(0, 0) rotate(0);
    }

    20%,
    60% {
        transform: translate(20px, 40px) rotate(180deg);
    }

    30%,
    80% {
        transform: translate(40px, 60px) rotate(0deg);
    }
}

@-webkit-keyframes treeMove {

    0%,
    100% {
        transform: rotate(0deg) translateX(0);
    }

    25%,
    75% {
        transform: rotate(5deg) translateX(15px);
    }

    50% {
        transform: rotate(10deg) translateX(30px);
    }
}

@keyframes treeMove {

    0%,
    100% {
        transform: rotate(0deg) translateX(0);
    }

    25%,
    75% {
        transform: rotate(5deg) translateX(15px);
    }

    50% {
        transform: rotate(10deg) translateX(30px);
    }
}


/*--------------------------------------------------------------
#     Zoom Fade Css
--------------------------------------------------------------*/
.zoom-fade {
    animation-name: zoom-fade;
    animation-duration: 5s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;

    -webkit-animation-name: zoom-fade;
    -webkit-animation-duration: 5s;
    -webkit-animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;

    -moz-animation-name: zoom-fade;
    -moz-animation-duration: 5s;
    -moz-animation-iteration-count: infinite;
    -moz-animation-timing-function: linear;

    -ms-animation-name: zoom-fade;
    -ms-animation-duration: 5s;
    -ms-animation-iteration-count: infinite;
    -ms-animation-timing-function: linear;

    -o-animation-name: zoom-fade;
    -o-animation-duration: 5s;
    -o-animation-iteration-count: infinite;
    -o-animation-timing-function: linear;
}

@-webkit-keyframes zoom-fade {
    0% {
        transform: scale(0.9);
    }

    50% {
        transform: scale(1);
    }

    100% {
        transform: scale(0.9);
    }
}

@keyframes zoom-fade {
    0% {
        transform: scale(0.9);
    }

    50% {
        transform: scale(1);
    }

    100% {
        transform: scale(0.9);
    }
}








/*--------------------------------------------------------------
# Mobile Nav
--------------------------------------------------------------*/
.mobile-nav__wrapper {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    opacity: 0;
    transform: translateX(-50%);
    transform-origin: right center;
    transition: transform 500ms ease-in, opacity 500ms linear, visibility 500ms ease-in;
    z-index: 999;
    visibility: hidden;
}

.mobile-nav__wrapper .container {
    padding-left: 0;
    padding-right: 0;
}

.mobile-nav__wrapper.expanded {
    opacity: 1;
    transform: translateX(0%);
    visibility: visible;
}

.mobile-nav__overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #000000;
    opacity: 0.5;
    cursor: pointer;
}





.mobile-nav__content {
    width: 300px;
    background-color: #1e1e1e;
    z-index: 10;
    position: relative;
    height: 100%;
    overflow-y: auto;
    padding-top: 30px;
    padding-bottom: 30px;
    padding-left: 15px;
    padding-right: 15px;
}

.mobile-nav__content .thm-btn {
    padding: 8px 0;
    width: 100%;
    text-align: center;
}

.mobile-nav__content .logo-box {
    margin-bottom: 40px;
    display: flex;
}

.mobile-nav__close {
    position: absolute;
    top: 20px;
    right: 15px;
    font-size: 18px;
    color: #fff;
    cursor: pointer;
}

.mobile-nav__content .main-menu__list,
.mobile-nav__content .main-menu__list ul {
    margin: 0;
    padding: 0;
    list-style-type: none;
}

.mobile-nav__content .main-menu__list ul {
    display: none;
    border-top: 1px solid rgba(255, 255, 255, 0.1);
}

.mobile-nav__content .main-menu__list li:not(:last-child) {
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.mobile-nav__content .main-menu__list li ul {
    padding-left: 10px;
}

.mobile-nav__content .main-menu__list li a {
    display: flex;
    justify-content: space-between;
    line-height: 30px;
    color: #ffffff;
    font-size: 14px;
    font-family: var(--thm-font);
    font-weight: 400;
    height: 46px;
    align-items: center;
    transition: 500ms;
}

.mobile-nav__content .main-menu__list li a.expanded {
    color: var(--thm-base);
}

.mobile-nav__content .main-menu__list li a:hover {
    color: var(--thm-base);
}



.mobile-nav__content .main-menu__list li a button {
    width: 28px;
    height: 28px;
    background-color: var(--thm-base);
    border: none;
    outline: none;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    transform: rotate(-90deg);
    border-radius: 7px;
    transition: transform 500ms ease;
}

.mobile-nav__content .main-menu__list li a button.expanded {
    transform: rotate(0deg);
    background-color: #fff;
    color: var(--thm-black);
}

.mobile-nav__content .main-menu__list li.cart-btn span {
    position: relative;
    top: auto;
    right: auto;
    transform: translate(0, 0);
}

.mobile-nav__content .main-menu__list li.cart-btn i {
    font-size: 16px;
}

.mobile-nav__top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 20px;
    margin-bottom: 30px;
}

.mobile-nav__top .main-menu__login a {
    color: var(--thm-text-dark);
}

.mobile-nav__container {
    border-top: 1px solid rgba(255, 255, 255, 0.1);
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.mobile-nav__social {
    display: flex;
    align-items: center;
}

.mobile-nav__social a {
    font-size: 16px;
    color: var(--thm-base);
    transition: 500ms;
}

.mobile-nav__social a+a {
    margin-left: 30px;
}

.mobile-nav__social a:hover {
    color: #ffffff;
}

.mobile-nav__contact {
    margin-bottom: 0;
    margin-top: 20px;
    margin-bottom: 20px;
}

.mobile-nav__contact li {
    color: var(--thm-text-dark);
    font-size: 14px;
    font-weight: 400;
    position: relative;
    display: flex;
    align-items: center;
    letter-spacing: 0.07em;
}

.mobile-nav__contact li+li {
    margin-top: 15px;
}

.mobile-nav__contact li a {
    color: #ffffff;
    transition: 500ms;
}

.mobile-nav__contact li a:hover {
    color: var(--thm-base);
}

.mobile-nav__contact li>i {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: var(--thm-base);
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-size: 16px;
    margin-right: 10px;
    color: #fff;
}

.mobile-nav__container .main-logo,
.mobile-nav__container .topbar__buttons,
.mobile-nav__container .main-menu__language,
.mobile-nav__container .main-menu__login {
    display: none;
}

/*--------------------------------------------------------------
# Search Popup
--------------------------------------------------------------*/
.search-popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 999;
    padding-left: 20px;
    padding-right: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    transform: translateY(-110%);
    transition: transform 500ms ease, opacity 500ms ease;
}

.search-popup.active {
    transform: translateY(0%);
}

.search-popup__overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: var(--thm-black);
    opacity: 0.75;
    cursor: pointer;
}

.search-popup__content {
    width: 100%;
    max-width: 560px;
}

.search-popup__content form {
    display: flex;
    flex-wrap: wrap;
    position: relative;
    background-color: #fff;
    border-radius: 0px;
    overflow: hidden;
}

.search-popup__content form input[type="search"],
.search-popup__content form input[type="text"] {
    width: 100%;
    background-color: #fff;
    font-size: 16px;
    border: none;
    outline: none;
    height: 66px;
    padding-left: 30px;
    padding-right: 90px;
}

.search-popup__content .thm-btn2 {
    padding: 0;
    width: 68px;
    height: 68px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    position: absolute;
    top: 0;
    right: -1px;
    border-radius: 0;
    background-color: var(--thm-base);
    border: none;
    transition: all 200ms linear;
    transition-delay: 0.1s;
}

.search-popup__content .thm-btn2::before {
    display: none;
}

.search-popup__content .thm-btn2:hover {
    background-color: var(--thm-black);
    color: #ffffff;
}

.search-popup__content .thm-btn2 i::before {
    margin: 0;
    color: #ffffff;
}









/***
=============================================
    Slider
=============================================
***/
.main-slider {
    position: relative;
    display: block;
    overflow: hidden;
}



.main-slider .swiper-slide {
    position: relative;
}

.main-slider .swiper-slide .container {
    position: relative;
    z-index: 30;
}

.main-slider .image-layer {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center top;
    transform: scale(1);
    transition: transform 7000ms ease;
    z-index: 1;
}

.main-slider .swiper-slide-active .image-layer {
    transform: scale(1.3);
}


.main-slider .image-layer-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    content: "";
    background: #0d0d0d;
    opacity: 0.70;
    z-index: 2;
}

.main-slider .container {
    position: relative;
    padding-top: 230px;
    padding-bottom: 230px;
    z-index: 2;
}

.main-slider .main-slider__content {
    position: relative;
    display: block;
    z-index: 1;
}

.main-slider .main-slider__content .shape1 {
    position: absolute;
    right: -260px;
    top: -225px;
    opacity: 0;
    transform: translateY(-100%);
    z-index: -1;
}

.main-slider .swiper-slide-active .main-slider__content .shape1 {
    opacity: 1;
    transform: translate(0);
    transition: all 1000ms ease;
    transition-delay: 200ms;
}

.main-slider .main-slider__content .section-title {
    margin-bottom: 27px;
    opacity: 0;
    transform: perspective(400px) rotateY(0deg) translateY(-120px);
    transform-origin: bottom;
    transition: all 1000ms ease;
}

.main-slider .swiper-slide-active .main-slider__content .section-title {
    opacity: 1;
    transform: perspective(400px) rotateY(0deg) translateY(0px);
    transition-delay: 500ms;
}

.main-slider .main-slider__content .main-slider__button-box {
    opacity: 0;
    transform: perspective(400px) rotateY(0deg) translateY(80px);
    transform-origin: bottom;
    transition: all 1500ms ease;
}

.main-slider .swiper-slide-active .main-slider__content .main-slider__button-box {
    opacity: 1;
    transform: perspective(400px) rotateY(0deg) translateY(0px);
    transition-delay: 1000ms;
}

.main-slider__content .section-title__tagline span.left {
    background: #ffffff;
}

.main-slider__content .section-title__tagline span.right {
    background: #ffffff;
}

.main-slider__content .section-title__tagline h4 {
    color: #ffffff;
}

.main-slider__content .section-title__title {
    color: #ffffff;
}

.main-slider__button-box {
    position: relative;
    display: block;
}

.main-slider .main-slider__content .banner-video-gallery {
    position: relative;
    display: inline-block;
    top: 24px;
}

.main-slider__content .banner-video-gallery a {
    position: relative;
    display: block;
    height: 60px;
    width: 60px;
    background: #ffffff;
    font-size: 15px;
    line-height: 60px;
    text-align: center;
    border-radius: 50%;
    transition: all 500ms ease;
    color: var(--thm-base);
}

.banner-video-gallery .video-box,
.banner-video-gallery .title-box {
    display: table-cell;
    vertical-align: middle;
    padding-left: 15px;
}

.banner-video-gallery .video-box,
.banner-video-gallery .title-box h5 {
    color: #ffffff;
    font-size: 16px;
    line-height: 28px;
    font-weight: 700;
    text-transform: capitalize;
}

.main-slider .btns-box {
    position: relative;
    display: inline-block;
}

.main-slider .btns-box .thm-btn {
    position: relative;
    display: inline-block;
    padding: 17px 55px 18px;
}





.main-slider__nav {
    position: absolute;
    top: 50%;
    left: 0%;
    right: 0;
    max-width: 100%;
    padding-left: 30px;
    padding-right: 30px;
    transform: translateY(-50%);
    z-index: 100;
}

.main-slider__nav .swiper-button-next,
.main-slider__nav .swiper-button-prev {
    position: relative;
    display: block;
    height: 60px;
    width: 60px;
    background: rgba(255, 255, 255, 0.30);
    border-radius: 50%;
    color: #ffffff;
    font-size: 20px;
    text-align: center;
    line-height: 60px;
    opacity: 1;
    margin: 0;
    transition: all 500ms ease;
    z-index: 100;
}

.main-slider__nav .swiper-button-prev {
    float: left;
    left: 0;
}

.main-slider__nav .swiper-button-next {
    float: right;
    right: 0;
}

.main-slider__nav .swiper-button-next:hover,
.main-slider__nav .swiper-button-prev:hover {
    color: #ffffff;
    background: var(--thm-base);
}

.main-slider__nav .swiper-button-next::after,
.main-slider__nav .swiper-button-prev::after {
    display: none;
}

.main-slider__nav .swiper-button-prev {
    margin-bottom: 0px;
}

.main-slider__nav .swiper-button-prev i::before {
    position: relative;
    display: inline-block;
    font-size: 15px;
    font-weight: 700;
}

.main-slider__nav .swiper-button-next i::before {
    position: relative;
    display: inline-block;
    font-size: 15px;
    font-weight: 700;
}



.main-slider-one #main-slider-pagination {
    display: none;
}



#main-slider-pagination {
    position: absolute;
    top: 0;
    left: auto;
    right: 0;
    bottom: 0;
    width: 80px;
    transform: none;
    counter-reset: count;
    z-index: 10;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
}

#main-slider-pagination .swiper-pagination-bullet {
    position: relative;
    display: block;
    float: none;
    width: 20px;
    height: 35px;
    border-radius: 0%;
    opacity: 1;
    background-color: transparent;
    transition: all 200ms linear;
    transition-delay: 0.1s;
}

#main-slider-pagination .swiper-pagination-bullet:before {
    position: absolute;
    width: 20px;
    height: 35px;
    text-align: center;
    color: #747474;
    font-size: 20px;
    line-height: 35px;
    font-weight: 700;
    border-bottom: 0px solid #ffffff;
    counter-increment: count;
    content: ""counter(count);
    transition: all 200ms linear;
    transition-delay: 0.1s;
}

#main-slider-pagination .swiper-pagination-bullet+.swiper-pagination-bullet {
    margin-left: 0px;
}

.swiper-container-horizontal .swiper-pagination-bullets .swiper-pagination-bullet {
    margin: 0 0px;
}



#main-slider-pagination .swiper-pagination-bullet.swiper-pagination-bullet-active:before {
    color: #ffffff;
}

#main-slider-pagination .swiper-pagination-bullet.swiper-pagination-bullet-active:focus,
#main-slider-pagination .swiper-pagination-bullet.swiper-pagination-bullet-active:hover {
    outline: none;
    border: none;
}


/*--------------------------------------------------------------
# Main Slider Two
--------------------------------------------------------------*/


.main-slider-two .image-layer-overlay {
    display: none;
}

.main-slider-two .vertical-line {
    margin: 0;
}

.main-slider-two .main-slider__nav {
    display: none;
}

.main-slider .slider-bottom-shape {
    position: absolute;
    left: 0;
    bottom: -2px;
    right: 0;
    height: 60px;
    background-attachment: scroll;
    background-position: center top;
    background-repeat: no-repeat;
    background-size: cover;
    z-index: 10;
}


.main-slider .swiper-slide .shape2 {
    position: absolute;
    top: 30%;
    right: 5%;
    width: 60px;
    height: 60px;
    background: #182343;
    border-radius: 50%;
    z-index: 5;
}

.main-slider .swiper-slide .shape3 {
    position: absolute;
    bottom: 22%;
    left: 5%;
    width: 60px;
    height: 60px;
    background: #182343;
    border-radius: 50%;
    z-index: 5;
}

.main-slider .slide-image-box {
    position: absolute;
    right: 100px;
    bottom: 0;
    z-index: 1;
    opacity: 0;
    transform: perspective(400px) translateX(220px);
    transform-origin: bottom;
    transition: all 1500ms ease;
}

.main-slider .swiper-slide-active .slide-image-box {
    opacity: 1;
    transform: perspective(400px) translateX(0px);
    transition-delay: 2000ms;
}

.main-slider .slide-image-box .slider-image-bg {
    position: absolute;
    top: 120px;
    left: -120px;
    right: 0;
    text-align: center;
    z-index: -1;
    opacity: 0.80;
}

.main-slider .image-layer--bg {
    background: #111b39;
}

.main-slider .swiper-slide-active .image-layer {
    transform: scale(1.3);
}




/***
=============================================
    Banner One
=============================================
***/
.banner-one {
    position: relative;
    display: block;
    padding: 260px 0px 0px;
    background: #fafafa;
    min-height: 950px;
    z-index: 1;
}

.banner-one__big-title {
    position: absolute;
    left: -205px;
    bottom: -236px;
    color: rgba(46, 46, 54, 0.03);
    font-size: 500px;
    line-height: 0.8em;
    font-weight: 800;
    text-transform: capitalize;
    z-index: -1;
}

.banner-one .shape1 {
    position: absolute;
    top: 0;
    right: 0;
    z-index: -1;
    opacity: 0.03;
}

.banner-one__inner-box {
    position: relative;
    display: block;
}

.banner-one__content {
    position: relative;
    display: block;
    max-width: 600px;
    width: 100%;
    padding-right: 100px;
}

.banner-one__content .section-title {
    margin-bottom: 28px;
}

.banner-one__content .section-title h2 {
    font-size: 80px;
    line-height: 1.1em;
    font-weight: 800;
}

.banner-one__text {
    font-size: 20px;
    margin: 0;
}

.banner-one__btn {
    position: relative;
    display: block;
    margin-top: 35px;
}

.banner-one__banner-img {
    position: absolute;
    top: -122px;
    right: -75px;
}

.banner-one__banner-img img {
    width: auto;
}




/***
=============================================
    Banner Two
=============================================
***/
.banner-two {
    position: relative;
    display: block;
    padding: 300px 0px 0px;
    background: #ffffff;
    min-height: 870px;
    z-index: 1;
}

.banner-two__inner-box {
    position: relative;
    display: block;
}

.banner-two__inner-box .shape1 {
    position: absolute;
    top: 0;
    left: -255px;
    z-index: -1;
}

.banner-two__inner-box .shape2 {
    position: absolute;
    left: -225px;
    bottom: -90px;
    z-index: -1;
}

.banner-two__inner-box .shape3 {
    position: absolute;
    top: -50px;
    right: -50px;
    z-index: 2;
}

.banner-two__inner-box .shape4 {
    position: absolute;
    bottom: -100px;
    right: 405px;
    z-index: -1;
}

.banner-two__content {
    position: relative;
    display: block;
    max-width: 600px;
    width: 100%;
    padding-right: 100px;
}

.banner-two__content .section-title {
    margin-bottom: 28px;
}

.banner-two__content .section-title h2 {
    font-size: 80px;
    line-height: 1.1em;
    font-weight: 800;
}

.banner-two__text {
    font-size: 20px;
    margin: 0;
}

.banner-two__btn {
    position: relative;
    display: block;
    margin-top: 35px;
}

.banner-two__banner-img {
    position: absolute;
    display: block;
    top: 0;
    right: 0;
    z-index: 1;
}

.banner-two__banner-img1 {
    position: absolute;
    top: -300px;
    right: 140px;
    width: 445px;
}

.banner-two__banner-img1 img {
    width: 100%;
}

.banner-two__banner-img2 {
    position: absolute;
    left: -215px;
    top: -65px;
    width: 640px;
}

.banner-two__banner-img2 img {
    width: 100%;
}





/***
=============================================
    Page Header
=============================================
***/
.page-header {
    position: relative;
    display: block;
    padding: 147px 0 140px;
    background-repeat: no-repeat;
    background-size: cover;
    z-index: 1;
}

.page-header:before {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    content: "";
    background: rgba(0, 0, 12, 0.70);
    z-index: -1;
}

.page-header__inner {
    position: relative;
    display: block;
    text-align: center;
    z-index: 3;
}

.page-header__inner h2 {
    font-size: 80px;
    color: #ffffff;
    line-height: 0.8em;
    font-weight: 800;
    text-transform: capitalize;
}

.thm-breadcrumb {
    padding-top: 29px;
}

.thm-breadcrumb li {
    position: relative;
    display: inline-block;
    color: var(--thm-base);
    font-size: 20px;
    font-weight: 500;
    text-transform: capitalize;
    transition: all 500ms ease;
}

.thm-breadcrumb li+li {
    margin-left: 8px;
}

.thm-breadcrumb li a {
    color: #ffffff;
    font-size: 20px;
    font-weight: 500;
    text-transform: capitalize;
    transition: all 500ms ease;
}

.thm-breadcrumb li:hover a {
    color: var(--thm-base);
}

.thm-breadcrumb li i::before {
    font-size: 15px;
    color: #ffffff;

}

/***
=============================================
    Features One
=============================================
***/
.features-one {
    position: relative;
    display: block;
    padding: 0px 0px 0px;
    margin-top: -75px;
}

.features-one .row {
    margin-left: 0px;
    margin-right: 0px;
}

.features-one .row [class*=col-] {
    padding-left: 0px;
    padding-right: 0px;
}

.features-one__single {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 10px;
    background-color: #ffffff;
    box-shadow: 0px 10px 30px 0px rgba(226, 226, 226, 0.35);
    padding: 40px 40px 40px;
    z-index: 2;
    margin-bottom: 40px;
}

.features-one__single-left {
    position: relative;
    display: flex;
    align-items: center;
}

.features-one__single-left-icon {
    position: relative;
    display: inline-block;
    padding-right: 30px;
}

.features-one__single-left-icon img {
    transition: all 500ms ease;
}

.features-one__single:hover .features-one__single-left-icon img {
    transform: rotateY(180deg);
}

.features-one__single-left-text {
    position: relative;
    display: block;
}

.features-one__single-left-text h4 {
    font-size: 20px;
    line-height: 32px;
    font-weight: 800;
}

.features-one__single-left-text h4 a {
    color: #2e2e36;
    transition: all 200ms linear;
    transition-delay: 0.1s;
}

.features-one__single-left-text h4 a:hover {
    color: var(--thm-base);
}

.features-one__single-right {
    position: relative;
    display: block;
}

.features-one__single-right-btn {
    position: relative;
    display: inline-block;
}

.features-one__single-right-btn a {
    position: relative;
    display: inline-block;
    border-radius: 7px;
    background-color: rgba(var(--thm-base-rgb), 0.07);
    width: 60px;
    height: 60px;
    line-height: 60px;
    text-align: center;
    color: var(--thm-base);
    transition: all 200ms linear;
    transition-delay: 0.1s;
}

.features-one__single-right-btn a:hover {
    background: var(--thm-base);
    color: #ffffff;
    box-shadow: 0px 10px 30px 0px rgba(var(--thm-base-rgb), 0.35);
}



/***
=============================================
    About One
=============================================
***/
.about-one {
    position: relative;
    display: block;
    background: #ffffff;
    padding: 80px 0 120px;
}

.about-one__left {
    position: relative;
    display: block;
    max-width: 570px;
    width: 100%;
    z-index: 1;
}

.about-one__left .shape1 {
    position: absolute;
    top: 80px;
    right: 30px;
    z-index: -1;
}

.about-one__left-img {
    position: relative;
    display: block;
    width: 373px;
    overflow: hidden;
}

.about-one__left-img::before {
    position: absolute;
    top: 0;
    left: -100%;
    display: block;
    content: '';
    width: 50%;
    height: 100%;
    background: linear-gradient(to right, rgba(255, 255, 255, .7) 0%, rgba(255, 255, 255, .3) 100%);
    transform: skewX(25deg);
    z-index: 2;
}

.about-one__left-img:hover:before {
    -webkit-animation: shine 1.5s;
    animation: shine 1.5s;
}

.about-one__left-img img {
    width: 100%;
}

.about-one__left-bottom {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.about-one__logo {
    position: relative;
    display: block;
}

.about-one__logo img {
    width: 100%;
}

.about-one__video-gallery {
    position: relative;
    display: block;
}

.about-one__video-gallery::before {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 198px;
    height: 216px;
    background: rgba(15, 15, 29, 0.65);
    border-bottom-right-radius: 11px;
    content: "";
}

.about-one__video-gallery img {
    width: 100%;
}

.about-one__video-gallery .video-box {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}

.about-one__video-gallery .video-box a {
    position: relative;
    display: inline-block;
    width: 45px;
    height: 45px;
    background: #ffffff;
    text-align: center;
    line-height: 45px;
    border-radius: 50%;
    color: var(--thm-base);
    font-size: 15px;
    transition: all 500ms ease;
}

.about-one__video-gallery .video-box a:hover {
    background-color: var(--thm-base);
    color: #fff;
}

.about-one__video-gallery .video-box a::before,
.about-one__video-gallery .video-box a::after {
    width: 45px;
    height: 45px;
    border-radius: 50%;
    background: transparent;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -webkit-animation-delay: .9s;
    animation-delay: .9s;
    content: "";
    position: absolute;
    box-shadow: 0 0 0 0 rgb(255 255 255 / 60%);
    -webkit-animation: ripple 3s infinite;
    animation: ripple 3s infinite;
    transition: all .4s ease;
}

.about-one__video-gallery .video-box a::after {
    -webkit-animation-delay: .6s;
    animation-delay: .6s;
}



.about-one__right {
    position: relative;
    display: block;
    margin-left: 85px;
}

.about-one__right-inner {
    position: relative;
    display: block;
}

.about-one__right-tagline {
    color: var(--thm-base);
    font-size: 20px;
    line-height: 30px;
    font-weight: 500;
    font-family: var(--thm-font);
    margin-bottom: 11px;
}

.about-one__right-text {
    margin: 0;
}

.about-one__author-box {
    position: relative;
    display: flex;
    align-items: center;
    margin-top: 18px;
}

.about-one__author-box .img-box {
    position: relative;
    display: block;
}



.about-one__author-box .title {
    position: relative;
    display: block;
    padding-left: 30px;
}

.about-one__author-box .title h3 {
    color: #2e2e36;
    font-size: 22px;
    line-height: 32px;
}

.about-one__author-box .title p {
    margin: 0;
}

.about-one__author-box .title h5 {
    position: relative;
    display: inline-block;
    color: #2e2e36;
    font-size: 18px;
    line-height: 32px;
    font-weight: 800;
    padding-left: 45px;
}

.about-one__author-box .title h5::before {
    position: absolute;
    top: 15px;
    left: 0;
    width: 30px;
    height: 3px;
    background: var(--thm-base);
    content: "";
}

.about-one__btn {
    position: relative;
    display: block;
    margin-top: 50px;
}

.about-one--about {
    position: relative;
    display: block;
    padding: 120px 0px 120px;
}

.about-one--about__big-title {
    position: absolute;
    top: 230px;
    left: -360px;
    transform: rotate(-90deg);
}

.about-one--about__big-title h2 {
    color: #222631;
    font-size: 220px;
    line-height: 0.8em;
    font-weight: 800;
    font-family: var(--thm-font);
    letter-spacing: -0.036em;
    opacity: 0.07;
}

.about-one--about .about-one__left .shape1 {
    position: absolute;
    top: auto;
    bottom: -20px;
    right: 30px;
    z-index: -1;
}

.about-one--about .about-one__left-img {
    width: 570px;
}

.about-one--about .about-one__left-img img {
    width: 100%;
    border-radius: 27px;
}

.about-one--about .about-one__video-gallery {
    margin-top: -75px;
    z-index: 10;
}

.about-one--about .about-one__video-gallery::before {
    border-radius: 12px;
}

.about-one--about .about-one__video-gallery img {
    width: 100%;
    border-radius: 12px;
}

.about-one--about .about-one__left-bottom {
    margin-left: 35px;
    margin-right: 35px;
}

.about-one--about .about-one__right-buttom li {
    position: relative;
    display: block;
    margin-top: 33px;
}

.about-one--about .about-one__right-buttom li .icon {
    position: absolute;
    top: 7px;
    left: 0;
}



.about-one--about .about-one__right-buttom li .title {
    position: relative;
    display: block;
    padding-left: 115px;
}

.about-one--about .about-one__right-buttom li .title h3 {
    color: #2e2e36;
    font-size: 22px;
    line-height: 30px;
    font-weight: 800;
    margin-bottom: 10px;
}

.about-one--about .about-one__right-buttom li .title p {
    margin: 0;
}

.about-one--about .about-one__btn {
    position: relative;
    display: block;
    margin-top: 63px;
}

.about-one--about .about-one__btn .thm-btn {
    color: #222631;
    border: 2px solid #e8e9ea;
    background: #ffffff;
}

.about-one--about .about-one__btn .thm-btn:hover {
    color: #fff;
}

.about-one--about .about-one__btn .thm-btn::after {
    background: var(--thm-base);
}

/***
=============================================
    Feauters One
=============================================
***/
.feauters-two {
    position: relative;
    display: block;
    background: #fafafa;
    padding: 120px 0px 195px;
    z-index: 3;
}

.feauters-two__big-title {
    position: absolute;
    bottom: -195px;
    z-index: -1;
    left: -200px;
}

.feauters-two__big-title h2 {
    color: rgba(46, 46, 54, 0.03);
    font-size: 500px;
    font-weight: 800;
    line-height: 0.8em;
}

.feauters-two__bg {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-attachment: scroll;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center top;
    opacity: 0.04;
}

.feauters-two__content-box {
    position: relative;
    display: block;
}

.feauters-two__content-box .shape1 {
    position: absolute;
    top: -225px;
    right: -190px;
    z-index: -1;
}

.feauters-two__content-box .shape2 {
    position: absolute;
    top: -215px;
    right: -190px;
    z-index: 1;
}

.feauters-two .section-title {
    margin-bottom: 58px;
}

.feauters-two__single {
    position: relative;
    display: block;
    margin-bottom: 40px;
    z-index: 2;
}

.feauters-two__single-inner {
    position: relative;
    display: block;
    overflow: hidden;
}

.feauters-two__single .img-holder {
    position: relative;
    display: block;
}

.feauters-two__single .img-holder .inner {
    position: relative;
    display: block;
    overflow: hidden;
}

.feauters-two__single .img-holder .inner::before {
    position: absolute;
    top: 50%;
    left: 50%;
    display: block;
    content: '';
    width: 0;
    height: 0;
    background: rgba(255, 255, 255, .2);
    border-radius: 50%;
    transform: translate(-50%, -50%);
    opacity: 0;
    z-index: 10;
}

.feauters-two__single:hover .img-holder .inner::before {
    -webkit-animation: circle .75s;
    animation: circle .75s;
}

.feauters-two__single .img-holder .inner img {
    width: 100%;
    transform: scale(1);
    transition: all 0.7s ease 0s;
}

.feauters-two__single:hover .img-holder .inner img {
    transform: scale(1.1);
}

.feauters-two__single .title-holder {
    position: relative;
    display: block;
    padding: 22px 0px 52px;
    background: transparent;
    transform: scale(1.0) translateY(0px);
    transition: all .4s ease-in-out .1s;
}

.feauters-two__single:hover .title-holder {
    opacity: 0;
    transform: scale(1.0) translateY(-40px);
}

.feauters-two__single .title-holder .btn {
    position: absolute;
    top: -22px;
    right: 25px;
    width: 45px;
    height: 45px;
    background: #ffffff;
    border-radius: 5px;
    text-align: center;
    line-height: 45px;
    box-shadow: 0px 10px 30px 0px rgb(235, 235, 235);
}

.feauters-two__single .title-holder .btn a {
    color: var(--thm-base);
}

.feauters-two__single .title-holder .btn a i::before {
    position: relative;
    display: block;
    font-size: 15px;
    line-height: 30px;
    overflow: hidden;
}

.feauters-two__single .title-holder h3 {
    font-size: 20px;
    line-height: 30px;
    font-weight: 800;
}

.feauters-two__single .title-holder h3 a {
    color: var(--thm-black);

}

.feauters-two__single .overlay {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0px;
    opacity: 0;
    visibility: hidden;
    transform-origin: center;
    transform-style: preserve-3d;
    transform: scale(1.0) translateY(65px);
    transition: all .4s ease-in-out .1s;
}

.feauters-two__single:hover .overlay {
    opacity: 1.0;
    visibility: visible;
    transform: scale(1.0) translateY(0%);
}

.feauters-two__single .overlay .title {
    position: relative;
    display: block;
    background-color: #ffffff;
    box-shadow: 0px 10px 30px 0px rgb(235, 235, 235);
    padding: 22px 30px 20px;
    border-radius: 7px;
}

.feauters-two__single .overlay .title h3 {
    font-size: 20px;
    line-height: 30px;
    font-weight: 800;
}

.feauters-two__single .overlay .title h3 a {
    color: var(--thm-black);
    transition: all 200ms linear;
    transition-delay: 0.1s;
}

.feauters-two__single .overlay .title h3 a:hover {
    color: var(--thm-base);
}

.feauters-two__single .overlay .title p {
    margin: 0;
    margin-top: 7px;
}

.feauters-two__single .overlay .title .btn {
    position: absolute;
    top: -22px;
    right: 25px;
    width: 45px;
    height: 45px;
    background: var(--thm-base);
    border-radius: 5px;
    text-align: center;
    box-shadow: 0px 10px 30px 0px rgb(249, 77, 28);
}

.feauters-two__single .overlay .title .btn a {
    color: #ffffff;
}

.feauters-two__single .overlay .title .btn a i::before {
    position: relative;
    display: inline-block;
    font-size: 15px;
    line-height: 30px;
}

/***
=============================================
    Counter One
=============================================
***/
.counter-one {
    position: relative;
    display: block;
    margin-top: -115px;
    z-index: 3;
}

.counter-one .row {
    margin-left: 0px;
    margin-right: 0px;
}

.counter-one .row [class*=col-] {
    padding-left: 0px;
    padding-right: 0px;
}

.counter-one .auto-container {
    max-width: 1360px;
}

.counter-one__wrapper {
    position: relative;
    display: block;
    background: var(--thm-base);
    border-radius: 12px;
    z-index: 1;
    padding-left: 80px;
}

.counter-one--counter-three .counter-one__wrapper {
    padding-left: 0;
}

.counter-one__wrapper .shape1 {
    position: absolute;
    left: 10px;
    bottom: 0;
    z-index: -1;
}

.counter-one__single {
    position: relative;
    display: flex;
    align-items: flex-start;
    padding-top: 80px;
    margin-bottom: 40px;
}

.counter-one__single-icon {
    position: relative;
    display: inline-block;

}



.counter-one__outer {
    position: relative;
    display: block;
    top: -15px;
    padding-left: 15px;
}

.counter-one__box {
    position: relative;
    display: block;
}

.counter-one__box .icon {
    position: relative;
    display: inline-block;
}

.counter-one__single-text {
    position: relative;
    display: inline-block;
    color: #ffffff;
    font-size: 52px;
    font-weight: 800;
}

.counter-one__single-text .odometer.odometer-auto-theme {
    line-height: 40px;
}

.counter-one__single-text span {
    display: inline-block;
}

.counter-one__single-text .icon {
    position: absolute;
    top: 18px;
    right: -25px;
    font-size: 27px;
}

/*
.counter-one__box i::before {
    position: absolute;
    top: 21px;
    right: 5px;
    color: #ffffff;
    font-size: 25px;
}
*/
.counter-one__title {
    position: relative;
    display: block;
}

.counter-one__title h6 {
    color: #ffffff;
    font-size: 17px;
    line-height: 32px;
    font-weight: 500;
    font-family: 'DM Sans', sans-serif;
}

.counter-one__company-chievement-box {
    position: relative;
    display: block;
    min-height: 235px;
}

.counter-one__company-chievement-img {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-position: center center;
    background-attachment: scroll;
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 235px;
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
}

.counter-one__company-chievement-img::before {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: rgba(226, 50, 0, 0.70);
    content: "";
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
    z-index: 1;
}

.counter-one__company-chievement-img img {
    width: 100%;
}

.counter-one__company-chievement-img .overly-content {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    flex-direction: column;
    z-index: 3;
}

.counter-one__company-chievement-img .overly-content h2 {
    color: #ffffff;
    font-size: 26px;
    line-height: 35px;
    font-weight: 800;
}

.counter-one__company-chievement-img .overly-content .button {
    position: relative;
    display: block;
    margin-top: 17px;
}

.counter-one__company-chievement-img .overly-content .button .company-chievement-btn {
    background: #ffffff;
    color: var(--thm-base);
}

.counter-one__company-chievement-img .overly-content .button .company-chievement-btn:hover {
    color: #fff;
}

.counter-one__company-chievement-img.style2::before {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: rgba(21, 177, 192, 0.70);
    content: "";
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
    z-index: 1;
}

.counter-one__company-chievement-img.style2 .overly-content .button .company-chievement-btn {
    color: #222631;
}

.counter-one__company-chievement-img.style2 .overly-content .button .company-chievement-btn:hover {
    color: #ffffff;
}

/***
=============================================
    Service One
=============================================
***/
.service-one {
    position: relative;
    display: block;
    background: #ffffff;
    padding: 120px 0px 120px;
}

.service-one .section-title {
    margin-bottom: 59px;
}

.service-one__tabs {
    position: relative;
}

.service-one__tabs .tab-buttons {
    position: relative;
    display: block;
    overflow: hidden;
    margin-bottom: 80px;
}

.service-one__tabs .tab-buttons .tab-btn {
    position: relative;
    background: #f4f4f5;
    color: #2e2e36;
}

.service-one__tabs .tab-buttons .tab-btn.active-btn {
    background: var(--thm-base);
    color: #ffffff;
}

.service-one__tabs .tab-buttons .tab-btn::after {
    background: var(--thm-base);
}

.service-one__tabs .tab-buttons .tab-btn span {
    position: relative;
    display: inline-block;
    transition: all 200ms linear;
    transition-delay: 0.1s;
}

.service-one__tabs .tab-buttons .tab-btn:hover span {
    color: #ffffff;
}

.service-one__tabs .tab-buttons li {
    position: relative;
    display: inline-block;
    cursor: pointer;
    margin-right: 25px;
    width: 265px;
    text-align: center;
}
.service-one__tabs .tab-buttons li:last-child a,
.service-one__tabs .tab-buttons li:last-child {
    margin-right: 0;
}



.service-one__tabs .tabs-content .tab {
    position: relative;
    display: none;
    transform: translateY(35px);
    transition: all 600ms ease;
    z-index: 10;
}

.service-one__tabs .tabs-content .tab.active-tab {
    display: block;
    margin-top: 0px;
    transform: translateY(0px);
}

.service-one__tabs .image-col {
    position: relative;
}

.service-one__tabs .image-col .inner {
    position: relative;
    display: block;
}

.service-one__img {
    position: relative;
    display: block;
    overflow: hidden;
}

.service-one__img::before {
    position: absolute;
    top: 50%;
    left: 50%;
    display: block;
    content: '';
    width: 0;
    height: 0;
    background: rgba(255, 255, 255, .2);
    border-radius: 50%;
    transform: translate(-50%, -50%);
    opacity: 0;
    z-index: 10;
}

.service-one__img:hover:before {
    -webkit-animation: circle .75s;
    animation: circle .75s;
}

.service-one__img img {
    width: 100%;
}

.service-one__tabs .text-col {
    position: relative;
}

.service-one__tabs .text-col .inner {
    position: relative;
    display: block;
}

.service-one__tabs .tabs-content .service-one__right {
    position: relative;
    display: block;
    margin-left: 70px;
}

.service-one__tabs .tabs-content .service-one__right-title {
    color: #2e2e36;
    font-size: 30px;
    line-height: 40px;
    font-weight: 800;
    margin-bottom: 26px;
}

.service-one__tabs .tabs-content .service-one__right-text {
    margin: 0;
}

.service-one__tabs .tabs-content .service-one__right-list {
    position: relative;
    display: block;
    overflow: hidden;
    margin-top: 31px;
}

.service-one__tabs .tabs-content .service-one__right-list li {
    position: relative;
    display: block;
    color: var(--thm-gray);
    font-size: 20px;
    line-height: 30px;
    font-weight: 500;
    margin-bottom: 15px;
}

.service-one__tabs .tabs-content .service-one__right-list li:last-child {
    margin-bottom: 0;
}

.service-one__tabs .tabs-content .service-one__right-list li i::before {
    position: relative;
    display: inline-block;
    top: 3px;
    color: var(--thm-base);
    font-size: 25px;
    padding-right: 15px;
}

.service-one__tabs .tabs-content .service-one__right .buttons {
    position: relative;
    display: block;
    margin-top: 43px;
}

.service-one__tabs .tabs-content .service-one__right .buttons .service-one-btn {
    background: #f4f4f5;
    color: #2e2e36;
    transition: all 200ms linear;
    transition-delay: 0.1s;
}

.service-one__tabs .tabs-content .service-one__right .buttons .service-one-btn:hover {
    color: #ffffff;
}

.service-one__tabs .tabs-content .service-one__right .buttons .service-one-btn::after {
    background: var(--thm-base);
}

/***
=============================================
    Team One
=============================================
***/
.team-one {
    position: relative;
    display: block;
    background: #ffffff;
    border-top: 1px solid #ededed;
    padding: 120px 0px 120px;
    z-index: 1;
}

.team-one__content-wrapper {
    position: relative;
    display: block;
    z-index: 1;
}

.team-one__big-title {
    position: absolute;
    top: -260px;
    left: 200px;
    z-index: -1;
}

.team-one__big-title h2 {
    color: rgba(46, 46, 54, 0.03);
    font-size: 500px;
    font-weight: 800;
    line-height: 0.8em;
}

.team-one .section-title {
    margin-bottom: 59px;
}

.team-one__single {
    position: relative;
    display: block;
    margin-bottom: 30px;
}

.team-one__single-wrapper {
    position: relative;
    display: block;
}

.team-one__single-img {
    position: relative;
    display: block;
    overflow: hidden;
    z-index: 3;
}

.team-one__single-img::before {
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: var(--thm-base);
    opacity: 0;
    border-radius: 3px;
    transition: .5s;
    transform: perspective(400px) rotateX(-90deg);
    transform-origin: top;
    border-radius: 6px;
    z-index: 2;
}

.team-one__single:hover .team-one__single-img:before {
    opacity: 0.7;
    transform: perspective(400px) rotateX(0deg);
}

.team-one__single-img img {
    width: 100%;
    transform: scale(1);
    transition: all 0.7s ease 0s;
}

.team-one__single:hover .team-one__single-img img {
    transform: scale(1.1);
}

.team-one__single-title {
    position: relative;
    display: block;
    padding: 27px 0px 50px;
}

.team-one__single-title h4 {
    font-size: 22px;
    line-height: 32px;
    font-weight: 800;
}

.team-one__single-title h4 a {
    color: var(--thm-black);
    transition: all 200ms linear;
    transition-delay: 0.1s;
}

.team-one__single-title h4 a:hover {
    color: var(--thm-base);
}

.team-one__single-title p {
    margin: 0;
}

.team-one__single-title-overly {
    border-radius: 7px;
    background-color: rgb(255, 255, 255);
    box-shadow: 0px 10px 30px 0px rgb(235, 235, 235);
    padding: 27px 30px 35px;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0px;
    opacity: 0;
    visibility: hidden;
    transform-origin: center;
    transform-style: preserve-3d;
    transform: scale(1.0) translateY(65px);
    transition: all .4s ease-in-out .1s;
    z-index: 3;
}

.team-one__single:hover .team-one__single-title-overly {
    opacity: 1.0;
    visibility: visible;
    transform: scale(1.0) translateY(0%);
}

.team-one__single-title-overly h4 {
    font-size: 22px;
    line-height: 32px;
    font-weight: 800;
}

.team-one__single-title-overly h4 a {
    color: var(--thm-black);
    transition: all 200ms linear;
    transition-delay: 0.1s;
}

.team-one__single-title-overly h4 a:hover {
    color: var(--thm-base);
}

.team-one__single-title-overly p {
    margin: 0;
}

.team-one__single-title-overly .social-icon {
    position: relative;
    display: block;
    overflow: hidden;
    margin-top: 10px;
}

.team-one__single-title-overly .social-icon li {
    position: relative;
    float: left;
    margin-right: 5px;
}

.team-one__single-title-overly .social-icon li:last-child {
    margin-right: 0;
}

.team-one__single-title-overly .social-icon li a {
    position: relative;
    display: inline-block;
    width: 35px;
    height: 35px;
    line-height: 35px;
    border-radius: 50%;
    text-align: center;
    background: #f4f4f5;
    font-size: 15px;
    color: #74787c;
    transition: all 200ms linear;
    transition-delay: 0.1s;
}

.team-one__single-title-overly .social-icon li a:hover {
    background: var(--thm-base);
    color: #ffffff;
}

.team-one__single-title-overly .icon {
    position: absolute;
    top: -22px;
    right: 25px;
}

.team-one__single-title-overly .icon a {
    position: relative;
    display: inline-block;
    color: #ffffff;
    width: 45px;
    height: 45px;
    line-height: 45px;
    background: var(--thm-base);
    border-radius: 5px;
    text-align: center;
    box-shadow: 0px 10px 30px 0px rgb(249 77 28);
}

.team-one__button {
    position: relative;
    display: block;
    text-align: center;
    margin-top: 40px;
}

.team-one__button .team-one__btn {
    background: #f4f4f5;
    color: #2e2e36;
    transition: all 200ms linear;
    transition-delay: 0.1s;
}

.team-one__button .team-one__btn:hover {
    color: #fff;
}

.team-one .team-one__button .team-one__btn::after {
    background: var(--thm-base);
}

.team-one__button-team {
    text-align: left;
}

.team-one__button-team {
    position: relative;
    display: block;
    z-index: 10;
    margin-top: 0;
}

/***
=============================================
    Cta One
=============================================
***/
.cta-one {
    position: relative;
    display: block;
    background-position: center center;
    background-attachment: scroll;
    background-size: cover;
    background-repeat: no-repeat;
    padding: 120px 0px 370px;
}

.cta-one::before {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: rgba(15, 15, 29, 0.85);
    content: "";
}

.cta-one .section-title {
    margin-bottom: 59px;
}

.cta-one__wrapper {
    position: relative;
    display: block;
}

.cta-one .section-title__tagline h4 {
    color: #ffffff;
}

.cta-one .section-title__tagline span.left {
    background: #ffffff;
}

.cta-one .section-title__tagline span.right {
    background: #ffffff;
}

.cta-one .section-title__title {
    color: #ffffff;
}

.cta-one .video-button {
    position: relative;
    display: inline-block;
    padding-left: 10px;
}

.cta-one .video-button .video-btn {
    position: relative;
    display: inline-block;
    background: #ffffff;
    padding: 13px 40px 15px;
    border-radius: 7px;
    color: #2e2e36;
    transition: all 500ms ease;
}

.cta-one .video-button .video-btn:hover {
    background-color: var(--thm-base);
    color: #fff;
}

.cta-one .video-button .video-btn .txt {
    font-size: 16px;
    line-height: 26px;
    font-weight: 700;
    text-transform: capitalize;
}

.cta-one .video-button .video-btn i {
    font-size: 15px;
    color: var(--thm-base);
    padding-left: 10px;
    transition: all 500ms ease;
}

.cta-one .video-button .video-btn:hover i {
    color: #fff;
}


/***
=============================================
    Testimonial One
=============================================
***/
.testimonial-one {
    position: relative;
    display: block;
    background: #fafafa;
    padding: 120px 0px 120px;
    margin-left: 70px;
    margin-right: 70px;
    margin-top: -250px;
    border-radius: 12px;
    z-index: 1;
}

.testimonial-one-bg {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-position: center top;
    background-attachment: scroll;
    background-repeat: no-repeat;
    background-size: contain;
    opacity: 0.04;
}

.testimonial-one__content-box {
    position: relative;
    display: block;
}

.testimonial-one__big-title {
    position: absolute;
    left: -200px;
    bottom: -130px;
    z-index: -1;
}

.testimonial-one__big-title h2 {
    color: rgba(46, 46, 54, 0.03);
    font-size: 500px;
    line-height: 0.8em;
    font-weight: 800;
}

.testimonial-one .section-title {
    margin-bottom: 58px;
}

.testimonial-one__single {
    position: relative;
    display: block;
}

.testimonial-one__text-box {
    position: relative;
    display: block;
    background: #ffffff;
    border-top-left-radius: 7px;
    border-bottom-left-radius: 7px;
    padding: 39px 50px 39px;
    box-shadow: 0px 10px 30px 0px rgb(235 235 235);
    margin-right: 150px;
}

.testimonial-one__icon {
    position: absolute;
    left: 50px;
    bottom: 20px;
    opacity: 0.03;
}

.testimonial-one__text-box p {
    margin: 0;
}

.testimonial-one__text-box-botton {
    position: relative;
    display: flex;
    align-items: flex-start;
    margin-top: 14px;
}

.testimonial-one__text-box-botton .icon {
    position: relative;
    display: inline-block;
    padding-right: 15px;
    padding-top: 7px;
}

.testimonial-one__text-box-botton .icon i::before {
    position: relative;
    display: inline-block;
    color: var(--thm-base);
    font-size: 25px;
    transform: rotate(180deg);
}

.testimonial-one__text-box-botton .title {
    position: relative;
    display: block;
}

.testimonial-one__text-box-botton .title h3 {
    color: #2e2e36;
    font-size: 22px;
    line-height: 32px;
    font-weight: 800;
}

.testimonial-one__text-box-botton .title p {
    margin: 0;
    font-weight: 500;
}

.testimonial-one-img {
    position: absolute;
    top: 0;
    right: 0;
    width: 170px;
    height: 285px;
}

.testimonial-one-img img {
    width: 100%;
}

.testimonial-one .owl-carousel .owl-item img {
    width: auto;
}

.testimonial-one .owl-theme .owl-nav {
    margin-top: 10px;
    position: absolute;
    top: -154px;
    right: 0;
}

.testimonial-one .owl-carousel .owl-nav .owl-prev {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 60px;
    border-radius: 7px;
    background-color: #ffffff;
    color: var(--thm-base);
    box-shadow: 0px 10px 30px 0px rgb(235, 235, 235);
    outline: none;
    transition: all 200ms linear;
    transition-delay: 0.1s;
}

.testimonial-one .owl-carousel .owl-nav .owl-next {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 60px;
    border-radius: 7px;
    background-color: #ffffff;
    color: var(--thm-base);
    box-shadow: 0px 10px 30px 0px rgb(235, 235, 235);
    outline: none;
    transition: all 200ms linear;
    transition-delay: 0.1s;
}

.testimonial-one .owl-carousel .owl-nav .owl-prev:hover,
.testimonial-one .owl-carousel .owl-nav .owl-next:hover {
    background: var(--thm-base);
    color: #ffffff;
}

.testimonial-one--testimonial {
    position: relative;
    display: block;
    margin-top: 0;
}

/***
=============================================
    Dot Style
=============================================
***/
.owl-dot-type1 .owl-carousel .owl-stage-outer {
    position: relative;
    display: block;
    padding-top: 0px;
    padding-bottom: 0px;
}

.owl-dot-type1 .owl-carousel .owl-dots {
    position: relative;
    display: block;
    text-align: center;
    line-height: 12px;
    margin: 0 !important;
}

.owl-dot-type1 .owl-carousel .owl-dots .owl-dot {
    position: relative;
    display: inline-block;
    width: 18px;
    height: 18px;
    background: #ffffff;
    border: 4px solid #cacacc;
    margin: 0px 5px;
    margin-top: 80px;
    padding: 0px;
    border-radius: 50%;
    transition: all 100ms linear;
    transition-delay: 0.1s;
}

.owl-dot-type1 .owl-carousel .owl-dots .owl-dot span {
    display: none;
}

.owl-dot-type1 .owl-carousel .owl-dots .owl-dot.active {
    border-color: var(--thm-base);
    outline: none;
}



/***
=============================================
    Blog One
=============================================
***/
.blog-one {
    position: relative;
    display: block;
    padding: 90px 0px 90px;
    background: #ffffff;
    z-index: 2;
}

.blog-one .shape1 {
    position: absolute;
    right: -224px;
    bottom: -200px;
    z-index: 1;
}

.blog-one .section-title {
    margin-bottom: 58px;
}

.blog-one__single {
    position: relative;
    display: block;
    margin-bottom: 30px;
    z-index: 2;
}

.blog-one__single-img {
    position: relative;
    display: block;
    overflow: hidden;
}

.blog-one__single-img::after {
    position: absolute;
    content: "";
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%;
    background: var(--thm-base);
    transition-delay: .1s;
    transition-timing-function: ease-in-out;
    transition-duration: .5s;
    transition-property: all;
    opacity: 1;
    transform-origin: bottom;
    transform-style: preserve-3d;
    transform: scaleY(0);
    z-index: 1;
}

.blog-one__single:hover .blog-one__single-img::after {
    opacity: 0.8;
    transform: scaleY(1.0);
}

.blog-one__single-img img {
    width: 100%;
    transform: scale(1.0);
    transition-delay: .1s;
    transition-timing-function: ease-in-out;
    transition-duration: .7s;
}

.blog-one__single:hover .blog-one__single-img img {
    transform: scale(1.2) rotate(1deg);
}

.blog-one__single-img .overlay-icon {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 3;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    transition: .9s;
    transform: perspective(400px) scaleY(0);
    transform-origin: bottom;
}

.blog-one__single:hover .blog-one__single-img .overlay-icon {
    transform: perspective(400px) scaleY(1.0);
}

.blog-one__single .blog-one__single-img .overlay-icon a {
    position: relative;
    display: inline-block;
}

.blog-one__single .blog-one__single-img .overlay-icon span {
    color: #ffffff;
    font-size: 40px;
    line-height: 40px;
}

.blog-one__single-text-box {
    position: relative;
    display: block;
    background: #ffffff;
    border: 1px solid #ededed;
    border-top: none;
    border-bottom-left-radius: 7px;
    border-bottom-right-radius: 7px;
    padding: 32px 35px 32px;
    transition: all 200ms linear;
    transition-delay: 0.1s;
}

.blog-one__single:hover .blog-one__single-text-box {
    box-shadow: 0px 10px 30px 0px rgb(235, 235, 235);
    border: 0 px solid #ffffff;
}

.blog-one__single-title {
    position: relative;
    display: block;
    font-size: 22px;
    line-height: 32px;
    font-weight: 800;
    margin-bottom: 7px;
}

.blog-one__single-title::before {
    position: absolute;
    top: 0;
    left: -35px;
    width: 3px;
    height: 100%;
    background: var(--thm-base);
    content: "";
}

.blog-one__single-title a {
    color: var(--thm-black);
    transition: all 200ms linear;
    transition-delay: 0.1s;
}

.blog-one__single-title a:hover {
    color: var(--thm-base);
}

.blog-one__single-text {
    margin: 0;
}

.blog-one__meta-info {
    position: relative;
    display: block;
    overflow: hidden;
    margin-top: 13px;
}

.blog-one__meta-info li {
    position: relative;
    float: left;
    color: #74787c;
    font-size: 17px;
    line-height: 32px;
    font-weight: 400;
    margin-right: 30px;
}

.blog-one__meta-info li:last-child {
    margin-right: 0;
}

.blog-one__meta-info li i::before {
    color: #74787c;
    font-size: 17px;
    padding-right: 10px;
}

.blog-one__meta-info li a {
    color: #74787c;
}




/***
=============================================
    partner One
=============================================
***/
.partner-one {
    position: relative;
    display: block;
    padding: 0px 0px 0px;
}

.partner-one .section-title {
    margin-bottom: 59px;
}

.partner-one .row {
    margin-left: 0px;
    margin-right: 0px;
}

.partner-one .row [class*=col-] {
    padding-left: 0px;
    padding-right: 0px;
}

.partner-one__content {
    position: relative;
    display: block;
    overflow: hidden;
    border-radius: 7px;
    border-right: 1px solid #ededed;
}

.partner-one__single {
    position: relative;
    display: block;
    float: left;
    padding: 60px 0px 60px;
    min-height: 170px;
    background: #ffffff;
    border-top: 1px solid #ededed;
    border-bottom: 1px solid #ededed;
    border-left: 1px solid #ededed;
    text-align: center;
    z-index: 1;
    transition: all 200ms linear;
    transition-delay: 0.3s;
    width: 100%;
}

.partner-one__single::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: #fafafa;
    z-index: -1;
    transform-origin: bottom;
    transform-style: preserve-3d;
    transform: scaleY(0);
    transition-delay: .1s;
    transition-timing-function: ease-in-out;
    transition-duration: .5s;
    transition-property: all;
    z-index: -1;
}

.partner-one__single:hover:before {
    opacity: 1;
    transform: scaleY(1.0);
}

.partner-one__single:hover {
    border-color: #fafafa;
}

.partner-one__single:first-child {
    border-left: 1px solid #ededed;
    border-top-left-radius: 7px;
    border-bottom-left-radius: 7px;
    transition: all 200ms linear;
    transition-delay: 0.1s;
}

.partner-one__single:hover:first-child {
    border-left: 1px solid #fafafa;
}

.partner-one__single:last-child {
    border-top-right-radius: 7px;
    border-bottom-right-radius: 7px;
}

.partner-one__img {
    position: relative;
    display: block;
}

.partner-one__img a {
    position: relative;
    display: inline-block;
    overflow: hidden;
    transition: all 0.2s ease-in-out 0.1s;
}

.partner-one__single:hover .partner-one__img a {
    transform: scale(1.1);
}

.partner-one__img a img {
    transition: all 0.2s ease-in-out 0.1s;
}

.partner-one__single:hover .partner-one__img a img {
    -webkit-filter: grayscale(1);
    filter: grayscale(1);
    transition: all 0.8s ease-in-out 0.1s;
}

.partner-one--about {
    position: relative;
    display: block;
    padding: 0px 0px 120px;
}

.partner-one--services {
    position: relative;
    display: block;
    padding: 120px 0px 120px;
}

/***
=============================================
    Info One
=============================================
***/
.info-one {
    position: relative;
    display: block;
    padding: 120px 0px 0px;
    z-index: 2;
    margin-bottom: -160px;
}

.info-one__single {
    position: relative;
    display: block;
    margin-bottom: 40px;
    overflow: hidden;
    border-radius: 7px;
}

.info-one__single::before {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: rgba(15, 15, 29, 0.75);
    content: "";
    border-radius: 7px;
    z-index: 1;
}

.info-one__single::after {
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 1;
    display: block;
    content: '';
    width: 0;
    height: 0;
    background: rgba(255, 255, 255, .2);
    border-radius: 0%;
    transform: translate(-50%, -50%);
    border-radius: 7px;
    opacity: 0;
}

.info-one__single:hover:after {
    -webkit-animation: circle .95s;
    animation: circle .95s;
}

.info-one__img {
    position: relative;
    display: block;
}

.info-one__img img {
    width: 100%;
    transform: scale(1);
    transition: all 0.7s ease 0s;
}

.info-one__single:hover .info-one__img img {
    transform: scale(1.1);
}

.info-one__overly {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    padding: 45px 55px 55px;
    z-index: 3;
}

.info-one__overly h2 {
    color: #ffffff;
    font-size: 25px;
    line-height: 40px;
    font-weight: 700;
}

.info-one__overly .button {
    position: relative;
    display: inline-block;
    margin-top: 19px;
}

.info-one__overly .button .info-one__btn {
    background: transparent;
    border: 2px solid #62656d;
    padding: 5px 25px 5px
}

.info-one__overly .button .info-one__btn:hover {
    border-color: transparent;
}

.info-one__overly .button .info-one__btn::after {
    background: var(--thm-base);
}


.info-one--contact {
    position: relative;
    display: block;
    padding: 120px 0px 80px;
    margin-bottom: 0px;
}

/***
=============================================
    Footer One
=============================================
***/
.footer-one {
    position: relative;
    display: block;
    background: #fafafa;
    z-index: 1;
}

.footer-one__bg {
    position: absolute;
    top: 0;
    left: -30px;
    bottom: 0;
    right: -30px;
    background-position: center center;
    background-attachment: scroll;
    background-repeat: no-repeat;
    background-size: cover;
    opacity: 0.07;
    z-index: -1;
}

.footer-two__bg.style2 {
    opacity: 0.03;
}

.footer-one .big-title {
    position: absolute;
    left: 35px;
    bottom: -60px;
}

.footer-one .big-title h2 {
    color: rgba(46, 46, 54, 0.03);
    font-size: 500px;
    line-height: 0.8em;
    font-weight: 800;
}

.footer-one__top {
    position: relative;
    display: block;
    padding: 200px 0 70px;
}

.footer-widget__column {
    position: relative;
    display: block;
}

.footer-widget__column.mar-top {
    margin-top: -7px;
}

.footer-widget__title {
    color: #2e2e36;
    font-size: 20px;
    line-height: 30px;
    font-weight: 800;
    margin-bottom: 18px;
}

.footer-widget__overview {
    position: relative;
    display: block;
}

.footer-widget__overview-list {
    position: relative;
    display: block;
    overflow: hidden;
}

.footer-widget__overview-list li {
    position: relative;
    display: block;
    margin-bottom: 13px;
}

.footer-widget__overview-list li:last-child {
    margin-bottom: 0px;
}

.footer-widget__overview-list li i::before {
    font-size: 16px;
    padding-right: 10px;
}

.footer-widget__overview-list li a {
    color: #74787c;
    font-size: 17px;
    line-height: 27px;
    font-weight: 400;
    transition: all 200ms linear;
    transition-delay: 0.1s;
}

.footer-widget__overview-list li a:hover {
    color: var(--thm-base);
}





.footer-widget__company {
    position: relative;
    display: block;
    margin-left: 20px;
}

.footer-widget__company-list {
    position: relative;
    display: block;
    overflow: hidden;
}

.footer-widget__company-list li {
    position: relative;
    display: block;
    margin-bottom: 13px;
}

.footer-widget__company-list li:last-child {
    margin-bottom: 0px;
}

.footer-widget__company-list li i::before {
    font-size: 16px;
    padding-right: 10px;
}

.footer-widget__company-list li a {
    color: #74787c;
    font-size: 17px;
    line-height: 27px;
    font-weight: 400;
    transition: all 200ms linear;
    transition-delay: 0.1s;
}

.footer-widget__company-list li a:hover {
    color: var(--thm-base);
}


.footer-widget__newsletter {
    position: relative;
    display: block;
}

.footer-widget__newsletter-text {
    margin: 0;
    margin-bottom: 25px;
}

.footer-widget__newsletter .footer-widget__title {
    margin-bottom: 17px;
}

.footer-widget__newsletter-form {
    position: relative;
    display: block;
}

.footer-widget__newsletter-input-box {
    position: relative;
    display: block;
}

.footer-widget__newsletter-input-box input[type="email"] {
    color: #74787c;
    font-size: 17px;
    height: 55px;
    width: 100%;
    font-weight: 400;
    padding-left: 30px;
    padding-right: 30px;
    background: #ffffff;
    border-radius: 7px;
    outline: none;
    border: none;
    box-shadow: 0px 10px 60px 0px rgb(209, 209, 209, 0.4);
}

.footer-widget__newsletter .newsletter__button {
    position: relative;
    display: block;
    margin-top: 10px;
}

.footer-widget__newsletter .newsletter__button .footer-btn {
    outline: none;
    border: none;
    padding: 0;
    border-radius: 7px;
}



.footer-widget__about {
    position: relative;
    display: block;
    float: right;
}

.footer-widget__about-logo {
    position: relative;
    display: block;
    margin-bottom: 27px;
}



.footer-widget__about-logo a img {
    image-rendering: -moz-crisp-edges;
    image-rendering: -o-crisp-edges;
    image-rendering: -webkit-optimize-contrast;
    image-rendering: crisp-edges;
    -ms-interpolation-mode: nearest-neighbor;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    -ms-transform: translateZ(0);
    -webkit-transform: translateZ(0);
    transform: translateZ(0);

}

.footer-contact-info {
    position: relative;
    display: block;
}

.footer-contact-info ul {
    position: relative;
    display: block;
}

.footer-contact-info ul li {
    position: relative;
    display: flex;
    align-items: flex-start;
    margin-bottom: 12px;
}

.footer-contact-info ul li:last-child {
    margin-bottom: 0;
}

.footer-contact-info ul li .icon {
    position: relative;
    display: inline-block;
}

.footer-contact-info ul li .icon i::before {
    position: relative;
    display: inline-block;
    top: 3px;
    color: var(--thm-base);
    font-size: 25px;
    line-height: 35px;
    padding-right: 15px;
}

.footer-contact-info ul li .text {
    position: relative;
    display: block;
}

.footer-contact-info ul li .text p {
    margin-bottom: 0;
}

.footer-contact-info ul li .text a {
    color: #74787c;
    font-size: 17px;
    line-height: 30px;
    font-weight: 500;
    transition: all 200ms linear;
    transition-delay: 0.1s;
}

.footer-contact-info ul li .text a:hover {
    color: var(--thm-base);
}

.footer-one__bottom {
    position: relative;
    display: block;
    background: #f1f1f1;
    padding: 20px 0px 20px;
    border-radius: 7px;
}

.footer-one__bottom-inner {
    position: relative;
    display: block;
}

.footer-one__bottom-text {
    position: relative;
    display: block;
}

.footer-one__bottom-text p {
    margin: 0;
}

.footer-one__bottom-text p a {
    color: var(--thm-base);
}


/***
=============================================
    Footer Two
=============================================
***/
.footer-one--footer-two {
    position: relative;
    display: block;
    background: #222631;
}

.footer-two__bg {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-attachment: scroll;
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    opacity: 0.07;
}

.footer-one--footer-two .footer-one__top {
    padding: 120px 0px 80px;
}

.footer-one--footer-two .footer-widget__title {
    color: #ffffff;
}

.footer-one--footer-two .footer-widget__company-list li a {
    color: #ffffff;
    transition: all 200ms linear;
    transition-delay: 0.1s;
}

.footer-one--footer-two .footer-widget__company-list li:hover a {
    color: var(--thm-base);
}



.footer-widget__lastest-news {
    position: relative;
    display: block;
}

.footer-one--footer-two .footer-widget__newsletter-text {
    color: #ffffff;
}

.footer-widget__lastest-news ul {
    position: relative;
    display: block;
    overflow: hidden;
}

.lastest-news__single {
    position: relative;
    display: block;
    margin-bottom: 8px;
}

.lastest-news__single:last-child {
    margin-bottom: 0;
}

.lastest-news__single-img {
    position: absolute;
    top: 7px;
    left: 0;
    width: 70px;
    height: 70px;
}

.lastest-news__single-title {
    position: relative;
    display: block;
    padding-left: 85px;
}

.lastest-news__single-title h6 {
    font-size: 16px;
    line-height: 26px;
    font-weight: 600;
    margin-bottom: 2px;
}

.lastest-news__single-title h6 a {
    color: #ffffff;
    transition: all 200ms linear;
    transition-delay: 0.1s;
}

.lastest-news__single-title h6 a:hover {
    color: var(--thm-base);
}

.lastest-news__single-title p {
    color: #a6a9b3;
    font-size: 14px;
    line-height: 28px;
    margin: 0;
}

.lastest-news__single-title p i::before {
    color: #a6a9b3;
    font-size: 15px;
    line-height: 25px;
    padding-right: 10px;
}

.footer-widget__lastest-news-btn {
    position: relative;
    display: inline-block;
}

.footer-widget__lastest-news-btn a {
    color: #ffffff;
}

.footer-widget__lastest-news-btn a::before {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 1px;
    background: #ffffff;
}

.footer-widget__lastest-news-btn a i::before {
    position: relative;
    display: inline-block;
    font-size: 15px;
    line-height: 25px;
    padding-left: 5px;
}

.footer-one--footer-two .footer-contact-info ul li .text p {
    color: #ffffff;
}

.footer-one--footer-two .footer-contact-info ul li .text a {
    color: #ffffff;
}

.footer-one--footer-two .footer-contact-info ul li .text a:hover {
    color: var(--thm-base);
}

.footer-one--footer-two .footer-widget__lastest-news {
    margin-left: -30px;
}

.footer-one--footer-two .footer-widget__newsletter {
    margin-left: -30px;
}

.footer-one--footer-two .footer-widget__newsletter-input-box input[type="email"] {
    width: 245px;
}

.footer-one--footer-two .footer-one__bottom {
    position: relative;
    display: block;
    background: transparent;
    padding: 20px 0px 20px;
    border-top: 1px solid #393c46;
}

/***
=============================================
    Features Three
=============================================
***/
.features-three {
    position: relative;
    display: block;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-position: center top;
    background-attachment: scroll;
    background-size: cover;
    background-repeat: no-repeat;
}

.features-three__content-box {
    position: relative;
    display: block;
    padding: 220px 0px 80px;
}

.features-three .section-title {
    margin-bottom: 59px;
}

.features-three__single {
    position: relative;
    display: block;
    background: #ffffff;
    padding: 45px 35px 45px;
    border-radius: 7px;
    margin-bottom: 40px;
    z-index: 1;
}

.features-three__single .layer-outer {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-attachment: scroll;
    background-size: cover;
    background-position: center top;
    transition-delay: .1s;
    transition-timing-function: ease-in-out;
    transition-duration: .5s;
    transition-property: all;
    opacity: 1;
    transform-origin: top;
    transform-style: preserve-3d;
    transform: scaleY(0);
    z-index: -1;
}

.features-three__single:hover .layer-outer {
    transform: scaleY(1.0);
}

.features-three__single .layer-outer::before {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: rgba(34, 38, 49, 0.8);
    content: "";
    border-radius: 7px;
}

.features-three__single-icon {
    position: relative;
    display: inline-block;
    margin-bottom: 17px;
}



.features-three__single-title {
    font-size: 20px;
    line-height: 30px;
    font-weight: 800;
    text-transform: capitalize;
    margin-bottom: 7px;
    transition: all 200ms linear;
    transition-delay: 0.1s;
}

.features-three__single-title a {
    color: #222631;
    transition: all 200ms linear;
    transition-delay: 0.1s;

}

.features-three__single:hover .features-three__single-title a {
    color: #ffffff;
}

.features-three__single:hover .features-three__single-title a:hover {
    color: var(--thm-base);
}

.features-three__single-title a:hover {
    color: var(--thm-base);
}

.features-three__single-text {
    margin: 0;
    transition: all 200ms linear;
    transition-delay: 0.1s;
}

.features-three__single:hover .features-three__single-text {
    color: #ffffff;
}

.features-three__single-btn {
    position: relative;
    display: inline-block;
    margin-top: 20px;
}

.features-three__single-btn a {
    position: relative;
    display: inline-block;
    width: 45px;
    height: 45px;
    line-height: 45px;
    text-align: center;
    border-radius: 7px;
    background: #f3f6f8;
    color: #222631;
    transition: all 200ms linear;
    transition-delay: 0.1s;
}

.features-three__single:hover .features-three__single-btn a {
    color: var(--thm-base);
    background: #ffffff;
}

.features-three__single-btn a i::before {
    font-size: 15px;
}

.features-three--about {
    position: relative;
    display: block;
    background-attachment: scroll;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    background: #fafafa;
}

.features-three--about .features-three__content-box {
    position: relative;
    display: block;
    padding: 120px 0px 80px;
}

.features-three--about .features-three__single-btn a {
    background-color: rgba(var(--thm-base-rgb), .10);
    color: var(--thm-base);
}

.features-three--services {
    position: relative;
    display: block;
    background-attachment: scroll;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
}

.features-three--services .features-three__content-box {
    padding: 120px 0px 80px;
}

.features-three--services .features-three__single-btn a {
    background-color: rgba(var(--thm-base-rgb), .10);
    color: var(--thm-base);
}

/***
=============================================
    About Two
=============================================
***/
.about-two {
    position: relative;
    display: block;
    background: #ffffff;
    padding: 0px 0px 40px;
}

.about-two__left {
    position: relative;
    display: block;
}

.about-two__big-title {
    position: absolute;
    left: -400px;
    bottom: 170px;
    transform: rotate(-90deg);
    z-index: 1;
}

.about-two__big-title h2 {
    color: #222631;
    font-size: 220px;
    line-height: 0.6em;
    font-weight: 800;
    font-family: var(--thm-font);
    letter-spacing: -0.036em;
    opacity: 0.07;
}

.about-two__left-img-box {
    position: relative;
    display: block;
}

.about-two__left-img1 {
    position: relative;
    display: inline-block;
    overflow: hidden;
}

.about-two__left-img1::before {
    position: absolute;
    top: 50%;
    left: 50%;
    display: block;
    content: '';
    width: 0;
    height: 0;
    background: rgba(255, 255, 255, .2);
    border-radius: 50%;
    transform: translate(-50%, -50%);
    opacity: 0;
    z-index: 10;
}

.about-two__left-img1:hover:before {
    -webkit-animation: circle .75s;
    animation: circle .75s;
}

.about-two__left-img1 img {
    width: 100%;
}

.about-two__left-img2 {
    position: absolute;
    bottom: -120px;
    left: 270px;
    z-index: 10;
}

.about-two__left-img2::before {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: rgba(34, 38, 49, 0.8);
    content: "";
    border-radius: 50%;
}

.about-two__left-img2 .video-box {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}

.about-two__left-img2 .video-box a {
    position: relative;
    display: inline-block;
    width: 45px;
    height: 45px;
    background: #ffffff;
    text-align: center;
    line-height: 45px;
    border-radius: 50%;
    font-size: 15px;
    transition: all 500ms ease;
}


.about-two__left-img2 .video-box a::before,
.about-two__left-img2 .video-box a::after {
    width: 45px;
    height: 45px;
    border-radius: 50%;
    background: transparent;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -webkit-animation-delay: .9s;
    animation-delay: .9s;
    content: "";
    position: absolute;
    box-shadow: 0 0 0 0 rgb(255 255 255 / 60%);
    -webkit-animation: ripple 3s infinite;
    animation: ripple 3s infinite;
    transition: all .4s ease;
}

.about-two__left-img2 .video-box a::after {
    -webkit-animation-delay: .6s;
    animation-delay: .6s;
}


.about-two__left-img2 .video-box a i::before {
    color: var(--thm-base);
}

.about-two__left-img2 img {
    border-radius: 50%;
}

.about-two__right {
    position: relative;
    display: block;
    padding-top: 120px;
    margin-left: 85px;
}

.about-two__right-inner {
    position: relative;
    display: block;
}

.about-two__right-text {
    margin: 0;
}

.about-two__right-bottom {
    position: relative;
    display: block;
    margin-top: 30px;
}

.about-two__right-bottom .icon {
    position: absolute;
    top: 10px;
    left: 0;
}

.about-two__right-bottom .text {
    position: relative;
    display: block;
    padding-left: 115px;
}

.about-two__right-bottom .text p {
    margin: 0;
}

.about-two__right-btn {
    position: relative;
    display: block;
    margin-top: 50px;
}

.about-two__right-btn .thm-btn {
    color: #222631;
    border: 2px solid #e8e9ea;
    background: #ffffff;
}

.about-two__right-btn .thm-btn::after {
    background: var(--thm-base);
}




/***
=============================================
    Service Two
=============================================
***/
.service-two {
    position: relative;
    display: block;
    background: #ffffff;
    padding: 120px 0px 120px;
}

.service-two__bg {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-attachment: scroll;
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
}

.service-two__content-box {
    position: relative;
    display: block;
}

.service-two__inner {
    position: relative;
    display: block;
}

.service-two__single {
    position: relative;
    display: block;
    margin-bottom: 44px;
}

.service-two__single-icon {
    position: absolute;
    top: 6px;
    left: 0;
}

.service-two__single-icon img {
    transition: all 500ms ease;
}

.service-two__single:hover .service-two__single-icon img {
    transform: rotateY(180deg);
}

.service-two__single-text {
    position: relative;
    display: block;
    padding-left: 115px;
}

.service-two__single-text h4 {
    color: #222631;
    font-size: 22px;
    line-height: 28px;
    font-weight: 800;
    margin-bottom: 9px;
    transition: all 500ms ease;
}

.service-two__single-text h4 a:hover {
    color: var(--thm-base);
}

.service-two__single-text p {
    margin: 0;
}

.service-two__btn {
    position: relative;
    display: block;
    margin-top: 6px;
}

.service-two__btn .thm-btn {
    color: #222631;
    border: 2px solid #e8e9ea;
    background: #ffffff;
}

.service-two__btn .thm-btn:hover {
    color: #fff;
}

.service-two__btn .thm-btn::after {
    background: var(--thm-base);
}

.service-two__img-box {
    position: relative;
    display: block;
}

.service-two__img {
    position: relative;
    display: block;
    width: 597px;
    height: 693px;
    overflow: hidden;
}

.service-two__img::before {
    position: absolute;
    top: 50%;
    left: 50%;
    display: block;
    content: '';
    width: 0;
    height: 0;
    background: rgba(255, 255, 255, .2);
    border-radius: 50%;
    transform: translate(-50%, -50%);
    opacity: 0;
    z-index: 10;
}

.service-two__img:hover:before {
    -webkit-animation: circle .75s;
    animation: circle .75s;
}

.service-two__img img {
    width: 100%;
}

/***
=============================================
   Case Studies One
=============================================
***/
.case-studies-one {
    position: relative;
    display: block;
    padding: 120px 0px 205px;
    background: #f3f6f8;
}

.case-studies-one__big-title {
    position: absolute;
    left: 48%;
    top: 30px;
}

.case-studies-one__big-title h2 {
    font-size: 350px;
    line-height: 1em;
    font-weight: 800;
    opacity: 0.03;
}

.case-studies-one .container-fluid {
    position: relative;
    display: block;
    padding-left: 70px;
    padding-right: 70px;
}

.case-studies-one .section-title {
    margin-bottom: 59px;
}

.case-studies-one__single {
    position: relative;
    display: block;
    overflow: hidden;
    margin-bottom: 30px;
    border-radius: 7px;
}

.swiper-slide .case-studies-one__single {
    margin-bottom: 0;
}

.case-studies-one__single::before {
    position: absolute;
    left: 0;
    bottom: 0;
    right: 0;
    border-radius: 7px;
    background-image: linear-gradient(0deg, rgba(19, 22, 31, 0.94902) 0%, rgba(34, 38, 49, 0) 100%);
    height: 450px;
    content: "";
    z-index: 1;
}

.case-studies-one__single-img {
    position: relative;
    display: block;
}

.case-studies-one__single-img img {
    width: 100%;
    transform: scale(1);
    transition: all 0.7s ease 0s;
}

.case-studies-one__single:hover .case-studies-one__single-img img {
    transform: scale(1.1);
}

.case-studies-one__single-img .overly-text {
    position: absolute;
    left: 40px;
    bottom: 35px;
    z-index: 2;
}

.case-studies-one__single-img .overly-text h3 {
    font-size: 24px;
    line-height: 34px;
    font-weight: 700;
    margin-bottom: 2px;
}

.case-studies-one__single-img .overly-text h3 a {
    color: #ffffff;
    transition: all 200ms linear;
    transition-delay: 0.1s;
}

.case-studies-one__single-img .overly-text h3 a:hover {
    color: #fff;
    opacity: 0.8;
}

.case-studies-one__single-img .overly-text p {
    color: #ffffff;
    margin: 0;
}

.case-studies-one__single-img .overly-btn {
    position: absolute;
    top: 20px;
    right: 20px;
    z-index: 2;
}

.case-studies-one__single-img .overly-btn a {
    position: relative;
    display: inline-block;
    color: #222631;
    width: 45px;
    height: 45px;
    line-height: 45px;
    background: #f3f6f8;
    border-radius: 7px;
    text-align: center;
    transition: all 200ms linear;
    transition-delay: 0.1s;
}

.case-studies-one__single-img .overly-btn a:hover {
    background: var(--thm-base);
    color: #ffffff;
}






/***
=============================================
   Related Portfolio
=============================================
***/

.related-portfolio {
    position: relative;
    background-color: #fafafa;
    padding-top: 120px;
    padding-bottom: 120px;
}

.related-portfolio__bg {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-repeat: no-repeat;
    background-position: center top;
    background-size: cover;
    opacity: 0.04;
}


/***
=============================================
   Portfolio Details
=============================================
***/

.portfolio-details {
    padding-top: 120px;
    padding-bottom: 120px;
}

.portfolio-details__content__title {
    font-size: 30px;
    color: var(--thm-black);
    margin: 0;
    margin-top: 45px;
    margin-bottom: 35px;
}

.portfolio-details__content p {
    margin: 0;
    color: var(--thm-gray);
    font-size: 17px;
    line-height: 32px;
}

.portfolio-details__content p.letter-caps {
    margin-top: 20px;
}

.portfolio-details__content p.letter-caps::first-letter {
    font-size: 30px;
    padding: 5px 10px;
    background-color: var(--thm-base);
    border-radius: 7px;
    color: #fff;
    font-weight: 800;
    line-height: 1;
    margin-right: 15px;
}

.portfolio-details__content p+p {
    margin-top: 25px;
}

.portfolio-details__content blockquote {
    border-left: 3px solid;
    border-color: var(--thm-base);
    padding-left: 25px;
    font-family: var(--thm-manrope-font);
    font-size: 16px;
    line-height: 32px;
    color: var(--thm-black);
    margin: 0;
    margin-top: 25px;
    margin-bottom: 25px;
}

.portfolio-details__gallery .row {
    --bs-gutter-y: 30px;
}


.portfolio-details__gallery figure {
    margin: 0;
}

.portfolio-details__gallery figure img {
    width: 100%;
    display: block;
    border-radius: 7px;
}

.portfolio-details__info {
    margin-top: 50px;
    border-radius: 7px;
    box-shadow: 0px 10px 60px 0px rgba(212, 212, 212, 0.38);
}

.portfolio-details__info__title {
    margin: 0;
    border-top-left-radius: 7px;
    border-top-right-radius: 7px;
    padding-top: 17px;
    padding-bottom: 17px;
    color: #fff;
    font-size: 22px;
    font-family: var(--thm-manrope-font);
    font-weight: 700;
    background-color: var(--thm-base);
    padding-left: 40px;
    padding-right: 40px;
    background-image: url(../images/shapes/portfolio-d-heading-bg.png);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

.portfolio-details__info ul {
    margin: 0;
    padding: 0;
    list-style: none;
    padding-left: 40px;
    padding-right: 40px;
    padding-top: 40px;
    padding-bottom: 40px;
}

.portfolio-details__info ul li {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 18px;
    font-weight: 500;
    color: var(--thm-gray);
}

.portfolio-details__info ul li+li {
    margin-top: 15px;
}

.portfolio-details__info ul li span {
    width: 100px;
    display: block;
    position: relative;
}

.portfolio-details__info ul li strong {
    font-weight: 500;
    color: #2e2e36;
}

.portfolio-details__info ul li span::after {
    content: ':';
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 0;
}

.portfolio-details__info ul li .social {
    display: flex;
    align-items: center;
    margin: 0;
}

.portfolio-details__info ul li .social a {
    font-size: 15px;
    color: var(--thm-gray);
    transition: all 500ms ease;
}

.portfolio-details__info ul li .social a+a {
    margin-left: 20px;
}

.portfolio-details__info ul li .social a:hover {
    color: var(--thm-base);
}


/***
=============================================
   Testimonial Two
=============================================
***/
.testimonial-two {
    position: relative;
    display: block;
    padding: 120px 0px 120px;
    border-bottom: 1px solid #ececec;
}

.testimonial-two__left {
    position: relative;
    display: block;
    z-index: 1;
}

.testimonial-two .shape1 {
    position: absolute;
    top: 40px;
    left: 410px;
    z-index: -1;
}

.testimonial-two .shape2 {
    position: absolute;
    top: 240px;
    left: -80px;
    z-index: -1;
}

.testimonial-two .shape3 {
    position: absolute;
    left: 120px;
    bottom: -210px;
    z-index: -1;
}

.testimonial-two .shape4 {
    position: absolute;
    top: 40px;
    left: 310px;
    z-index: -1;
}

.testimonial-two__img-box {
    position: relative;
    display: block;
}

.testimonial-two__img1 {
    position: relative;
    display: inline-block;
    overflow: hidden;
}

.testimonial-two__img1::before {
    position: absolute;
    top: 50%;
    left: 50%;
    display: block;
    content: '';
    width: 0;
    height: 0;
    background: rgba(255, 255, 255, .2);
    border-radius: 50%;
    transform: translate(-50%, -50%);
    opacity: 0;
    z-index: 10;
}

.testimonial-two__img1:hover:before {
    -webkit-animation: circle .75s;
    animation: circle .75s;
}

.testimonial-two__img1 img {
    width: 100%;
}

.testimonial-two__img2 {
    position: absolute;
    display: inline-block;
    top: 140px;
    left: 215px;
}

.testimonial-two__img2 img {
    width: 100%;
}

.testimonial-two__right {
    position: relative;
    display: block;
    margin-left: 105px;
}

.testimonial-two__content {
    position: relative;
    display: block;
}

.testimonial-two__single {
    position: relative;
    display: block;
}

.testimonial-two__single-quote-icon {
    position: absolute;
    left: 0;
    bottom: -15px;
}

.testimonial-two__single-quote-icon span::before {
    font-size: 120px;
    line-height: 1em;
    color: rgba(0, 0, 0, 0.03);
}

.testimonial-two__single-text1 {
    color: #222631;
    font-size: 20px;
    line-height: 32px;
    font-weight: 400;
    font-style: italic;
    margin: 0;
    margin-bottom: 18px;
}

.testimonial-two__single-text2 {
    margin: 0;
}

.testimonial-two__client-info {
    position: relative;
    display: block;
    margin-top: 24px;
}

.testimonial-two__client-info .icon {
    position: absolute;
    top: 0;
    left: 0;
}

.testimonial-two__client-info .icon span::before {
    font-size: 30px;
    color: var(--thm-base);
    line-height: 42px;
}

.testimonial-two__client-info .title {
    position: relative;
    display: block;
    padding-left: 50px;
}

.testimonial-two__client-info .title h4 {
    color: #222631;
    font-size: 22px;
    line-height: 32px;
    font-weight: 800;
}

.testimonial-two__client-info .title p {
    margin: 0;
}

.owl-dot-type1.style2 .owl-carousel .owl-dots {
    float: left;
}

.owl-dot-type1.style2 .owl-carousel .owl-dots .owl-dot {
    margin-top: 65px;
}


/***
=============================================
   Working Process One
=============================================
***/
.working-process-one {
    position: relative;
    display: block;
    background: #ffffff;
    padding: 120px 0px 155px;
}

.working-process-one .section-title {
    margin-bottom: 70px;
}

.working-process-one__wrapper {
    position: relative;
    display: block;
}

.working-process-one__shape {
    position: absolute;
    top: -70px;
    left: -90px;
}

.working-process-one__wrapper ul {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-wrap: wrap;
    flex-direction: row;
    counter-reset: count;
}

.working-process-one__single {
    position: relative;
    display: block;
}

.working-process-one__single .count-box {
    position: absolute;
    top: -35px;
    left: 70px;
    width: 50px;
    height: 50px;
    line-height: 50px;
    text-align: center;
    font-size: 15px;
    background: var(--thm-base);
    color: #ffffff;
    border-radius: 50%;
    font-family: var(--thm-manrope-font);
    font-weight: 700;
    z-index: 1;
    transition: all 200ms linear;
    transition-delay: 0.1s;
}

.working-process-one__single:hover .count-box {
    background: #ffffff;
    color: var(--thm-base);
    box-shadow: 0px 10px 30px 0px rgb(21, 177, 192, 0.3);
}

.working-process-one__single .count-box::before {
    top: -35px;
    left: 70px;
    width: 50px;
    height: 50px;
    line-height: 50px;
    text-align: center;
    font-size: 15px;
    border-radius: 50%;
    font-family: var(--thm-manrope-font);
    font-weight: 700;
    counter-increment: count;
    content: "0"counter(count);
}

/*
.working-process-one__single .count-box::after{
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: #ffffff;
    color: var(--thm-base);
    content: "";
    border-radius: 50%;
    transform: scale(0.0);
    transform-origin: center;
    transform-style: preserve-3d;
    transition: all 0.4s cubic-bezier(0.62, 0.21, 0.45, 1.52);
    box-shadow: 0px 10px 30px 0px rgb( 21, 177, 192, 0.3);
}
.working-process-one__single:hover .count-box::after{
    transform: scaleX(1.0);
}
*/
.working-process-one__single .count-box span {
    color: #ffffff;
}

.working-process-one__single:hover .count-box span {
    color: var(--thm-base);
}

.working-process-one__single.style2 .count-box {
    position: absolute;
    top: -20px;
    left: 106px;
    width: 50px;
    height: 50px;
    line-height: 50px;
    text-align: center;
    font-size: 15px;
    color: #ffffff;
    background: var(--thm-base);
    border-radius: 50%;
    font-family: var(--thm-manrope-font);
    font-weight: 700;
    transition: all 200ms linear;
    transition-delay: 0.1s;
}

.working-process-one__single.style2:hover .count-box {
    background: #ffffff;
    color: var(--thm-base);
    box-shadow: 0px 10px 30px 0px rgb(21, 177, 192, 0.3);
}

.working-process-one__single-icon {
    position: relative;
    display: inline-block;
    width: 100px;
    height: 100px;
    background: #f3f6f8;
    border-radius: 50%;
    line-height: 100px;
    text-align: center;
    font-size: 50px;
    color: var(--thm-base);
    margin-bottom: 45px;
    z-index: 1;
}

.working-process-one__single-icon::before {
    position: absolute;
    top: -18px;
    left: -18px;
    bottom: -18px;
    right: -18px;
    border: 3px dashed #eff0f0;
    content: "";
    border-radius: 50%;
}

.working-process-one__single-icon::after {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: var(--thm-base);
    content: "";
    border-radius: 50%;
    z-index: -1;
    transform: scale(0.0);
    transform-origin: center;
    transform-style: preserve-3d;
    transition: all 0.4s cubic-bezier(0.62, 0.21, 0.45, 1.52);
}

.working-process-one__single:hover .working-process-one__single-icon::after {
    transform: scaleX(1.0);
}

.working-process-one__single.top50 {
    position: relative;
    display: block;
    top: 45px;
}

.working-process-one__single.top65 {
    position: relative;
    display: block;
    top: 65px;
}

.working-process-one__single.style2.top20 {
    position: relative;
    display: block;
    top: 22px;
}

.working-process-one__single.style2 .working-process-one__single-icon {
    position: relative;
    display: inline-block;
    width: 140px;
    height: 140px;
    background: #f3f6f8;
    border-radius: 50%;
    line-height: 140px;
    text-align: center;
    font-size: 75px;
    color: var(--thm-base);
    margin-bottom: 45px;
}

.working-process-one__single.style2 .working-process-one__single-icon::before {
    position: absolute;
    top: -13px;
    left: -13px;
    bottom: -13px;
    right: -13px;
    border: 3px dashed #eff0f0;
    content: "";
    border-radius: 50%;
}

.working-process-one__single-icon span::before {
    position: relative;
    display: block;
    font-size: 50px;
    line-height: 100px;
    transition: all 200ms linear;
    transition-delay: 0.1s
}

.working-process-one__single:hover .working-process-one__single-icon span::before {
    color: #ffffff;
}

.working-process-one__single.style2 .working-process-one__single-icon span::before {
    position: relative;
    display: block;
    font-size: 75px;
    line-height: 140px;
}

.working-process-one__single-title {
    font-size: 24px;
    line-height: 28px;
    font-weight: 700;
    margin-bottom: 12px;
}

.working-process-one__single-title a {
    color: #222631;
    transition: all 200ms linear;
    transition-delay: 0.1s;

}

.working-process-one__single-title a:hover {
    color: var(--thm-base);
}

.working-process-one__single-text {
    margin: 0;
}

/***
=============================================
   Cta Two
=============================================
***/
.cta-one--cta-two {
    position: relative;
    display: block;
    background-position: center center;
    background-attachment: fixed;
    background-size: cover;
    background-repeat: no-repeat;
    padding: 120px 0px 205px;
}

.cta-one--cta-two::before {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    content: "";
    background: rgba(6, 7, 12, .75);
}

.cta-one--cta-two__wrapper {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.cta-one--cta-two__video-box {
    position: relative;
    display: block;
}

.cta-one--cta-two__video-box .icon {
    position: relative;
    display: inline-block;
    padding: 15px;
    border: 2px solid #57575b;
    border-radius: 5px;
}

.cta-one--cta-two__video-box .icon .link {
    position: relative;
    display: inline-block;
    width: 85px;
    height: 85px;
    line-height: 85px;
    text-align: center;
    color: var(--thm-base);
    font-size: 20px;
    background: #ffffff;
    border-radius: 5px;
    z-index: 1;
    transition: all 500ms ease;
}

.cta-one--cta-two__video-box .icon .link:hover {
    background-color: var(--thm-base);
    color: #fff;
}

.cta-one--cta-two__video-box .icon .border-animation {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 1px solid rgba(255, 255, 255, .2);
    animation: squares 2.9s linear 0s infinite;
    -webkit-animation: squares 2.9s linear 0s infinite;
    -ms-animation: squares 2.9s linear 0s infinite;
    -o-animation: squares 2.9s linear 0s infinite;
    -webkit-animation-play-state: running;
    animation-play-state: running;
    opacity: 0;
    border-radius: 5px;
}

.cta-one--cta-two__video-box .icon .border-animation.border-2 {
    -webkit-animation-delay: 1s;
    animation-delay: 1s;
}

.cta-one--cta-two__video-box .icon .border-animation.border-3 {
    -webkit-animation-delay: 2s;
    animation-delay: 2s;
}

/***
=============================================
   Pricing Plan One
=============================================
***/
.pricing-plan-one {
    position: relative;
    display: block;
    padding: 195px 0px 80px;
    margin-top: -115px;
    background: #f3f6f8;
}

.pricing-plan-one__left-bg {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-attachment: scroll;
    background-repeat: no-repeat;
    background-position: left center;
}

.pricing-plan-one__right-bg {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    background-attachment: scroll;
    background-repeat: no-repeat;
    background-position: right top;
}

.pricing-plan-one__content {
    position: relative;
    display: block;
    z-index: 1;
}

.pricing-plan-one__content .shape1 {
    position: absolute;
    left: -140px;
    top: -135px;
    z-index: -1;
}

.pricing-plan-one__content .shape2 {
    position: absolute;
    top: -250px;
    right: -105px;
    z-index: -1;
}

.pricing-plan-one__content .shape3 {
    position: absolute;
    left: -175px;
    bottom: 125px;
    z-index: -1;
}

.pricing-plan-one__content .shape4 {
    position: absolute;
    bottom: 30px;
    right: -123px;
    z-index: -1;
}

.pricing-plan-one__single {
    position: relative;
    display: block;
    background: #ffffff;
    border-radius: 7px;
    margin-bottom: 40px;
}

.pricing-plan-one__single .table-header {
    position: relative;
    display: block;
    background: #222631;
    padding: 42px 40px 40px;
    border-top-left-radius: 7px;
    border-top-right-radius: 7px;
    z-index: 1;

}

.table-header__bg {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-attachment: scroll;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    z-index: 2;
}

.pricing-plan-one__single .table-header::before {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: var(--thm-black);
    content: "";
    transform: scaleX(0.7) rotateX(20deg);
    transition: all 0.4s linear;
    z-index: -1;
    background: var(--thm-base);
    border-top-left-radius: 7px;
    border-top-right-radius: 7px;
    opacity: 0;
}

.pricing-plan-one__single:hover .table-header::before {
    transform: scaleX(1.0) rotateX(0deg);
    transition: all 0.4s linear;
    opacity: 1;
}

.pricing-plan-one__single .table-header p {
    color: #ffffff;
    font-size: 18px;
    line-height: 28px;
    font-weight: 500;
    margin: 0;
    margin-bottom: 2px;
}

.pricing-plan-one__single .table-header h3 {
    color: #ffffff;
    font-size: 35px;
    line-height: 45px;
    font-weight: 600;
    margin-bottom: 12px;
}

.pricing-plan-one__single .table-header h2 {
    position: relative;
    color: #ffffff;
    font-size: 85px;
    line-height: 1em;
    font-weight: 300;
    letter-spacing: -0.06em;
    padding-left: 20px;
}

.pricing-plan-one__single .table-header h2 span::before {
    position: absolute;
    top: 12px;
    left: 0px;
    font-size: 15px;
    line-height: 25px;
}

.pricing-plan-one__single .table-content {
    position: relative;
    display: block;
    padding: 35px 40px 30px;
}

.pricing-plan-one__single .table-content ul {
    position: relative;
    display: block;
    overflow: hidden;
}

.pricing-plan-one__single .table-content ul li {
    position: relative;
    display: block;
    padding-left: 20px;
    margin-bottom: 10px;
}

.pricing-plan-one__single .table-content ul li:last-child {
    margin-bottom: 0px;
}

.pricing-plan-one__single .table-content ul li::before {
    content: "";
    position: absolute;
    left: 0;
    top: 12px;
    bottom: 0;
    background: var(--thm-base);
    width: 7px;
    height: 7px;
    border-radius: 50%;
}

.pricing-plan-one__single .table-footer {
    position: relative;
    display: block;
    padding: 0px 40px 45px;
}

.pricing-plan-one__single .table-footer .thm-btn {
    background: transparent;
    border: 2px solid #e8e9ea;
    color: #222631;
}

.pricing-plan-one__single .table-footer .thm-btn::after {
    background: var(--thm-base);
}

.pricing-plan-one__single .table-footer .thm-btn:hover {
    color: #ffffff;
}

.pricing-plan-one--pricing {
    position: relative;
    display: block;
    padding: 235px 0px 80px;
    margin-top: -115px;
}

/***
=============================================
  Features Four
=============================================
***/
.features-four {
    position: relative;
    display: block;
    padding: 0px 0px 120px;
}

.features-four .row {
    margin-left: 0px;
    margin-right: 0px;
}

.features-four .row [class*=col-] {
    padding-left: 0px;
    padding-right: 0px;
}

.features-four__left {
    position: relative;
    display: block;
    background: #f7f7f7;
    max-width: 535px;
    width: 100%;
    padding: 120px 95px 120px;
}

.features-four__left-bg {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-attachment: scroll;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
}

.features-four__left-bottom {
    position: relative;
    display: block;
}

.features-four__left-text {
    margin: 0;
}

.features-four__left-btn {
    position: relative;
    display: block;
    margin-top: 50px;
}


.features-four__right {
    position: relative;
    display: block;
    margin-left: -50px;
}

.features-four__right ul {
    position: relative;
    display: block;
    overflow: hidden;
    border: 1px solid #ececec;
}

.features-four__right-single {
    position: relative;
    display: block;
    margin-top: 70px;
    padding-bottom: 70px;
}

.features-four__right-single::before {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 1px;
    background: #ececec;
}

.features-four__right-single:last-child:before {
    display: none;
}

.features-four__right-single-inner {
    position: relative;
    display: flex;
    align-items: center;
    padding: 0px 120px 0px;
}

.features-four__right-single .icon {
    position: relative;
    display: inline-block;
    padding-right: 30px;
}

.features-four__right-single .icon img {
    transition: all 500ms ease;
}

.features-four__right-single:hover .icon img {
    transform: rotateY(180deg);
}

.features-four__right-single .title {
    position: relative;
    display: block;
}

.features-four__right-single .title h4 {
    font-size: 20px;
    line-height: 30px;
    font-weight: 800;
    margin-bottom: 7px;
}

.features-four__right-single .title h4 a {
    color: var(--thm-black);
    transition: all 200ms linear;
    transition-delay: 0.1s;
}

.features-four__right-single .title h4 a:hover {
    color: var(--thm-base);
}

.features-four__right-single .title p {
    margin: 0;
}

/***
=============================================
    Partner Two
=============================================
***/
.partner-one--partner-two {
    position: relative;
    display: block;
    padding: 120px 0px 120px;
}

/***
=============================================
    About Three
=============================================
***/
.about-three {
    position: relative;
    display: block;
    overflow: hidden;
    background: #1e1e1e;
}

.about-three .container-fullwidth {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
}

.about-three__img-box {
    position: relative;
    display: block;
    width: 50%;
    float: left;
}

.about-three__img-box img {
    width: 100%;
}

.about-three__content-box {
    position: relative;
    display: block;
    width: 50%;
    max-width: 525px;
    float: left;
    padding-left: 120px;
}

.about-three__content-box .section-title__title {
    color: #ffffff;
}

.about-three__text {
    color: #ffffff;
    margin: 0;
}

.about-three-list {
    position: relative;
    display: block;
    overflow: hidden;
    margin-top: 36px;
}

.about-three-list li {
    position: relative;
    display: flex;
    align-items: center;
    margin-bottom: 13px;
}

.about-three-list li:last-child {
    margin-bottom: 0;
}

.about-three-list li .icon {
    position: relative;
    display: inline-block;
    width: 25px;
    height: 25px;
    line-height: 25px;
    text-align: center;
    background: var(--thm-base);
    color: #ffffff;
    font-size: 15px;
    border-radius: 50%;
}



.about-three-list li .text {
    position: relative;
    display: block;
}

.about-three-list li .text p {
    color: #bfbfbf;
    font-size: 20px;
    line-height: 32px;
    font-weight: 400;
    padding-left: 15px;
    margin: 0;
}

.about-three__content-box .buttons {
    position: relative;
    display: block;
    margin-top: 67px;
}

.about-three__content-box .buttons .thm-btn {
    background: transparent;
    border: 2px solid #303030;
    color: #bfbfbf;
}

.about-three__content-box .buttons .thm-btn::after {
    background: var(--thm-base);
}




/***
=============================================
    Services Three
=============================================
***/
.services-three {
    position: relative;
    display: block;
    background: #ffffff;
    padding: 120px 0px 20px;
}

.services-three .section-title {
    margin-bottom: 125px;
}

.services-three__single {
    position: relative;
    display: block;
    background: #f7f7f7;
    margin-bottom: 100px;
}


.services-three__single-bg-layer {
    position: absolute;
    content: "";
    top: 0;
    left: -101%;
    height: 100%;
    width: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    transition: all 0.6s ease;
    opacity: 0;
    z-index: -1;
}

.services-three__single-bg-layer::before {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: rgba(var(--thm-base-rgb), .85);
    content: "";
}

.services-three__single:hover .services-three__single-bg-layer {
    left: 0px;
    opacity: 1;
}

.services-three__single-inner {
    position: relative;
    display: block;
    padding: 100px 60px 60px;
    overflow: hidden;
    z-index: 1;
}

.services-three__single-icon {
    position: absolute;
    top: -65px;
    left: 60px;
    display: block;
    width: 130px;
    height: 130px;
    line-height: 130px;
    text-align: center;
    color: var(--thm-primary);
    font-size: 65px;
    background: #ffffff;
    border-radius: 50%;
    box-shadow: 0px 0px 60px 0px rgb(218, 218, 218);
    transition: all 200ms linear;
    transition-delay: 0.1s;
    z-index: 2;
}

.services-three__single:hover .services-three__single-icon {
    border-radius: 50%;
    box-shadow: 0px 10px 60px 0px rgb(217, 35, 0, 0.3);
}



.services-three__single-title {
    font-size: 22px;
    line-height: 28px;
    font-weight: 800;
}

.services-three__single-title a {
    color: #1b1d21;
    transition: all 400ms linear;
    transition-delay: 0.1s;
}

.services-three__single:hover .services-three__single-title a {
    color: #ffffff;
}

.services-three__single-text {
    margin: 0;
    margin-top: 15px;
    transition: all 400ms linear;
    transition-delay: 0.1s;
}

.services-three__single:hover .services-three__single-text {
    color: #ffffff;
}

.services-three__single__btn-box {
    position: relative;
    display: block;
    margin-top: 30px;
}

.services-three__single-btn {
    position: relative;
    display: inline-block;
    transition: all 400ms linear;
    transition-delay: 0.1s;
}

.services-three__single:hover .services-three__single-btn {
    transform: scale(0);
}

.services-three__single-btn a {
    position: relative;
    display: inline-block;
    width: 45px;
    height: 45px;
    line-height: 45px;
    text-align: center;
    color: var(--thm-base);
    background: #ffffff;
    border-radius: 7px;
    box-shadow: 0px 10px 30px 0px rgb(218, 218, 218);
}

.services-three__single-overly-btn {
    position: absolute;
    top: 0;
    transition: .5s;
    opacity: 1;
    transform: perspective(400px) scale(0.5);
    transform-origin: center;
    opacity: 0;
}

.services-three__single:hover .services-three__single-overly-btn {
    transform: perspective(400px) scale(1);
    opacity: 1;

}

.services-three__single:hover .services-three__single-overly-btn .about-three-btn {
    background: #ffffff;
    color: var(--thm-base);
    padding: 10px 30px 10px;
}

.services-three__single-icon-layer {
    position: absolute;
    display: block;
    bottom: 35px;
    right: 45px;
    font-size: 165px;
    color: #000000;
    opacity: 0.02;
    z-index: 0;
}





.services-three__single .services-three__single-overly-btn .thm-btn:hover {
    color: #fff;
}


/***
=============================================
    Counter Three
=============================================
***/
.counter-one--counter-three {
    position: relative;
    display: block;
    background-attachment: fixed;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    padding: 0px 0px 68px;
    margin-top: 0;
    z-index: 1;
}

.counter-one--counter-three .shape1 {
    position: absolute;
    top: 0;
    left: -275px;
    z-index: -1;
}

.counter-one--counter-three::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: #0c0c0c;
    opacity: 0.8;
}

.counter-one--counter-three .counter-one__wrapper {
    background: none;
}

.counter-one--counter-three .row {
    margin-left: -15px;
    margin-right: -15px;
}

.counter-one--counter-three .row [class*=col-] {
    padding-left: 15px;
    padding-right: 15px;
}

.counter-one--counter-three .counter-one__single {
    margin-bottom: 50px;
}

.counter-three__bottom {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
    flex-wrap: wrap;
    border-top: 1px solid #312f2b;
    padding-top: 80px;
}

.counter-three__bottom-text {
    position: relative;
    display: block;
}

.counter-three__bottom-text .section-title {
    margin: 0;
}

.counter-three__bottom-text .section-title__tagline .left {
    background: #ffffff;
}

.counter-three__bottom-text .section-title__tagline h4 {
    color: #ffffff;
}

.counter-three__bottom-text .section-title__title {
    color: #ffffff;
}

.counter-three__bottom-btn {
    position: relative;
    display: block;
}

/***
=============================================
    Case Studies Two
=============================================
***/
.case-studies-two {
    position: relative;
    display: block;
    padding: 120px 0px 120px;
    background-attachment: scroll;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center bottom;
}

.case-studies-two .section-title {
    margin-bottom: 58px;
}

.case-studies-two__single {
    position: relative;
    display: block;
    z-index: 1;
    margin-bottom: 40px;
}

.case-studies-two__single-text-box {
    position: relative;
    background: #f7f7f7;
    padding: 42px 50px 42px;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
}

.case-studies-two__single-text-box.style2 {
    position: relative;
    background: var(--thm-base);
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: scroll;
    z-index: -1;
}

.case-studies-two__single-left {
    position: relative;
    display: block;
}

.case-studies-two__single-text-box.style2 h2 {
    color: #ffffff;
}

.case-studies-two__single-text-box.style2 p {
    color: #ffffff;
}

.case-studies-two__single-left h2 {
    color: #1b1d21;
    font-size: 24px;
    line-height: 34px;
    font-weight: 800;
    margin-bottom: 5px;
}

.case-studies-two__single-left h2.padding-top3 {
    padding-top: 3px;
}

.case-studies-two__single-left p {
    margin: 0;
}

.case-studies-two__single-right {
    position: relative;
    display: block;
}

.case-studies-two__single-right-btn {
    position: relative;
    display: inline-block;
}

.case-studies-two__single-right-btn a {
    position: relative;
    display: inline-block;
    width: 55px;
    height: 55px;
    line-height: 55px;
    text-align: center;
    color: var(--thm-base);
    background: #ffffff;
    border-radius: 7px;
    box-shadow: 0px 10px 30px 0px rgb(218 218 218, 0.8);
    margin-top: 8px;
}



.case-studies-two__single-img {
    position: relative;
    display: block;
    overflow: hidden;
}

.case-studies-two__single-img::before {
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 1;
    display: block;
    content: '';
    width: 0;
    height: 0;
    background: rgba(255, 255, 255, .2);
    border-radius: 0%;
    transform: translate(-50%, -50%);
    opacity: 0;
}

.case-studies-two__single:hover .case-studies-two__single-img::before {
    -webkit-animation: circle .95s;
    animation: circle .95s
}

.case-studies-two__single-img img {
    width: 100%;
    transition: all 500ms ease;
}

.case-studies-two__single:hover .case-studies-two__single-img img {
    transform: scale(1.05);
}

.case-studies-two__btn {
    position: relative;
    display: block;
    text-align: center;
    margin-top: 80px;
}

.case-studies-two__btn .thm-btn {
    background: transparent;
    border: 2px solid #e8e9ea;
    color: #2e2e36;
    transition: all 200ms linear;
    transition-delay: 0.1s;
}

.case-studies-two__btn .thm-btn:hover {
    border-color: transparent;
    color: #fff;
}

.case-studies-two__btn .thm-btn::after {
    background: var(--thm-base);
}

/***
=============================================
    Chart One
=============================================
***/
.chart-one {
    position: relative;
    display: block;
    background: #ffffff;
    padding: 120px 0px 120px;
    border-top: 1px solid #ececec;
}

.chart-one__left {
    position: relative;
    display: block;
    max-width: 500px;
    width: 100%;
}

.chart-one__left-text {
    margin: 0;
}

.chart-one__btn {
    margin-top: 35px;
}

.chart-one__right {
    position: relative;
    display: block;
}

.chart-one__img {
    position: relative;
    display: block;
}

.chart-one__img img {
    width: 100%;
}



/***
=============================================
    Team Two
=============================================
***/
.team-two {
    position: relative;
    display: block;
    background: #f7f7f7;
    padding: 120px 0px 80px;
    z-index: 1;
}

.team-two__left-bg {
    position: absolute;
    top: 0;
    left: 0;
    background-attachment: scroll;
    background-position: top center;
    background-repeat: no-repeat;
    opacity: 0.07;
    z-index: -1;
}

.team-two__right-bg {
    position: absolute;
    top: 0;
    right: 0;
    background-attachment: scroll;
    background-repeat: no-repeat;
    background-position: top center;
    opacity: 0.02;
}

.team-two .section-title {
    margin-bottom: 59px;
}

.team-two__single {
    position: relative;
    display: block;
    margin-bottom: 92px;
}

.team-two__single-img {
    position: relative;
    display: block;
}

.team-two__single-img-box {
    position: relative;
    display: block;
    overflow: hidden;
}



.team-two__single-img-box::before {
    position: absolute;
    top: 50%;
    left: 50%;
    display: block;
    content: '';
    width: 0;
    height: 0;
    background: rgba(255, 255, 255, .2);
    border-radius: 50%;
    transform: translate(-50%, -50%);
    opacity: 0;
    z-index: 10;
}

.team-two__single:hover .team-two__single-img-box::before {
    -webkit-animation: circle .75s;
    animation: circle .75s;
}

.team-two__single-img .text {
    position: absolute;
    left: -17px;
    bottom: -55px;
    right: 0;
    color: var(--thm-black);
    opacity: 0.1;
    font-size: 95px;
    line-height: 0.9em;
    font-weight: 400;
    font-family: var(--thm-italianno-font);
    text-align: center;
}

.team-two__single-img .icon {
    position: absolute;
    bottom: 10px;
    right: 45px;
    z-index: 10;
}

.team-two__single-img .icon a {
    position: relative;
    display: inline-block;
    width: 40px;
    height: 40px;
    line-height: 40px;
    text-align: center;
    font-size: 10px;
    border-radius: 7px;
    background: #ffffff;
    color: var(--thm-base);
    box-shadow: 0px 10px 30px 0px rgb(193, 193, 193, 0.5);
    z-index: 1;
    transition: all 200ms linear;
    transition-delay: 0.1s;
}

.team-two__single-img .icon a:hover {
    background: var(--thm-base);
    color: #ffffff;
}

.team-two__single-title {
    position: relative;
    display: block;
    margin-top: 27px;
}

.team-two__single-title h2 {
    position: relative;
    margin-top: -35px;

}

.team-two__single-title h3 {
    font-size: 22px;
    line-height: 32px;
    font-weight: 800;
}

.team-two__single-title h3 a {
    color: var(--thm-black);
    transition: all 200ms linear;
    transition-delay: 0.1s;
}

.team-two__single-title h3 a:hover {
    color: var(--thm-base);
}

.team-two__single-title p {
    font-size: 16px;
    line-height: 26px;
    font-weight: 600;
    margin: 0;
}

.team-two__single-title .overly {
    position: absolute;
    left: 0px;
    right: 0px;
    bottom: -52px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: .5s;
    transform: scaleX(0);
    opacity: 0;
}

.team-two__single:hover .team-two__single-title .overly {
    bottom: -52px;
    transform: scaleX(1.0);
    opacity: 1;
}

.team-two__single-title .social-icon {
    position: relative;
    display: block;
    overflow: hidden;
    margin-top: 10px;
}

.team-two__single-title .social-icon li {
    position: relative;
    display: inline-block;
}

.team-two__single-title .social-icon li a {
    position: relative;
    display: inline-block;
    width: 35px;
    height: 35px;
    line-height: 35px;
    text-align: center;
    border-radius: 50%;
    background: #ffffff;
    color: #60626d;
    font-size: 15px;
    transition: all 200ms linear;
    transition-delay: 0.1s;
    box-shadow: 0px 10px 30px 0px rgb(193, 193, 193, 0.1);
}

.team-two__single-title .social-icon li a:hover {
    background: var(--thm-base);
    color: #ffffff;
}




/***
=============================================
    Blog Two
=============================================
***/
.blog-two {
    position: relative;
    display: block;
    padding: 120px 0px 80px;
}

.blog-two .section-title {
    margin-bottom: 58px;
}

.blog-two__single {
    position: relative;
    display: block;
    overflow: hidden;
    margin-bottom: 40px;
    z-index: 1;
}

.blog-two__single-bg-layer {
    position: absolute;
    content: "";
    top: 0;
    left: -101%;
    height: 100%;
    width: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    transition: all 0.6s ease;
    opacity: 0;
    z-index: 1;
}

.blog-two__single-bg-layer::before {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: rgba(var(--thm-base-rgb), .85);
    content: "";
}

.blog-two__single:hover .blog-two__single-bg-layer {
    left: 0px;
    opacity: 1;
}

.blog-two__single-text-box {
    position: relative;
    display: block;
    background: #ffffff;
    border: 1px solid #ededed;
    padding: 42px 35px 42px;
    transition: all 200ms linear;
    transition-delay: 0.1s;
}

.blog-two__single-title {
    position: relative;
    display: block;
    font-size: 22px;
    line-height: 32px;
    font-weight: 800;
    margin-bottom: 17px;
    z-index: 2;
}

.blog-two__single-title::before {
    position: absolute;
    top: 0;
    left: -35px;
    width: 3px;
    height: 100%;
    background: var(--thm-base);
    content: "";
    transition: all 200ms linear;
    transition-delay: 0.1s;
}

.blog-two__single:hover .blog-two__single-title::before {
    background: transparent;
}

.blog-two__single-title a {
    color: var(--thm-black);
    transition: all 200ms linear;
    transition-delay: 0.1s;
}

.blog-two__single-title a:hover {
    color: var(--thm-base);
}

.blog-two__single:hover .blog-two__single-title a {
    color: #ffffff;
}

.blog-two__single-text {
    position: relative;
    display: block;
    margin: 0;
    z-index: 2;
    transition: all 200ms linear;
    transition-delay: 0.1s;
}

.blog-two__single:hover .blog-two__single-text {
    color: #ffffff;
}

.blog-two__meta-info {
    position: relative;
    display: block;
    overflow: hidden;
    margin-top: 13px;
    z-index: 2;
}

.blog-two__meta-info li {
    position: relative;
    float: left;
    color: #74787c;
    font-size: 17px;
    line-height: 32px;
    font-weight: 400;
    margin-right: 30px;
    transition: all 200ms linear;
    transition-delay: 0.1s;
}

.blog-two__single:hover .blog-two__meta-info li {
    color: #ffffff;
}

.blog-two__meta-info li:last-child {
    margin-right: 0;
}

.blog-two__meta-info li i::before {
    color: #74787c;
    font-size: 17px;
    padding-right: 10px;
    transition: all 200ms linear;
    transition-delay: 0.1s;
}

.blog-two__single:hover .blog-two__meta-info li i::before {
    color: #ffffff;
}

.blog-two__meta-info li a {
    color: #74787c;
    transition: all 200ms linear;
    transition-delay: 0.1s;
}

.blog-two__single:hover .blog-two__meta-info li a {
    color: #ffffff;
}

/***
=============================================
    Features Five
=============================================
***/
.features-one--features-five {
    margin-top: 0;
    margin-bottom: -115px;
}

.features-one--features-five .features-one__single {
    border-radius: 0;
}

/***
=============================================
    Footer Three
=============================================
***/
.footer-one--footer-three {
    position: relative;
    display: block;
    background: var(--thm-black);
}

.footer-one--footer-three .footer-one__top {
    padding: 180px 0 85px;
}

.footer-one--footer-three .footer-contact-info-text {
    color: #ffffff;
    margin: 0;
    margin-bottom: 22px;
}

.footer-one--footer-three .footer-widget__about-logo {
    margin-bottom: 32px;
}

.footer-one--footer-three .footer-widget__company-list {
    padding-top: 5px;
}

.footer-one--footer-three .footer-widget__company {
    position: relative;
    display: block;
    margin-left: 42px;
}

.footer-one--footer-three .footer-widget__newsletter {
    position: relative;
    display: block;
    margin-left: -20px;
}

.footer-one--footer-three .footer-widget__newsletter-input-box input[type="email"] {
    width: 245px;
}

.footer-one--footer-three .footer-widget__newsletter .footer-widget__title {
    margin-bottom: 22px;
}

.footer-one--footer-three .footer-contact-info ul li .icon i::before {
    color: #ffffff;
}

.footer-one--footer-three .footer-contact-info ul li .text p {
    color: #ffffff;
}

.footer-one--footer-three .footer-contact-info ul li .text a {
    color: #ffffff;
    transition: all 200ms linear;
    transition-delay: 0.1s;
}

.footer-one--footer-three .footer-contact-info ul li .text a:hover {
    color: var(--thm-base);
}

.footer-one--footer-three .footer-widget__title {
    color: #ffffff;
}

.footer-one--footer-three .footer-widget__company-list li a {
    color: #ffffff;
    transition: all 200ms linear;
    transition-delay: 0.1s;
}

.footer-one--footer-three .footer-widget__company-list li a:hover {
    color: var(--thm-base);
}

.footer-one--footer-three .footer-widget__newsletter-text {
    margin: 0;
    margin-bottom: 25px;
    color: #ffffff;
}


.footer-widget__instagram {
    position: relative;
    display: block;
}

.footer-widget__instagram .instagram {
    position: relative;
    display: block;
    overflow: hidden;
    margin-left: -5px;
    margin-right: -5px;
    padding-top: 15px;
}

.footer-widget__instagram .instagram li {
    position: relative;
    float: left;
    padding: 0px 5px 20px;
}

.footer-widget__instagram .instagram li .inner {
    position: relative;
    display: block;
}

.footer-widget__instagram .instagram li .inner .img-box {
    position: relative;
    display: block;
    overflow: hidden;
}

.footer-widget__instagram .instagram li .inner .img-box img {
    width: 100%;
}

.footer-widget__instagram .instagram li .inner .img-box .overlay-content {
    position: absolute;
    top: 0px;
    left: 0px;
    bottom: 0px;
    right: 0px;
    opacity: 0;
    background: rgba(13, 13, 13, 0.65);
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    transform: skew(0deg, 0deg) scale(1.2, 1.2);
    transition: all 0.4s linear;
}

.footer-widget__instagram .instagram li:hover .img-box .overlay-content {
    opacity: 1;
    transform: translateY(0px);
}

.footer-widget__instagram .instagram li .img-box .overlay-content a {
    position: relative;
    display: inline-block;
    color: #ffffff;
    font-size: 20px;
    line-height: 20px;
    transform: scale(0);
    transition: all 500ms ease 100ms;
}

.footer-widget__instagram .instagram li:hover .img-box .overlay-content a {
    transform: scale(1.0);
    transition: all 700ms ease 600ms;
}

.footer-widget__instagram .instagram li:nth-child(5) {
    margin-bottom: 0;
}

.footer-widget__instagram .instagram li:nth-child(6) {
    margin-bottom: 0;
}

.footer-one--footer-three .footer-one__bottom {
    position: relative;
    display: block;
    background: transparent;
    border-top: 1px solid #3b3b3b;
    padding: 20px 0px 20px;
    border-radius: 0px;
}

.footer-one--footer-three--about {
    position: relative;
    display: block;
    background: #0f0f1d;
}

/***
=============================================
    Why Choose One
=============================================
***/
.why-choose-one {
    position: relative;
    display: block;
    padding: 120px 0px 120px;
}

.why-choose-one__content {
    position: relative;
    display: block;
}

.why-choose-one__list {
    position: relative;
    display: block;
    overflow: hidden;
}

.why-choose-one__list-single {
    position: relative;
    display: block;
    margin-bottom: 32px;
}

.why-choose-one__list-single:last-child {
    margin-bottom: 0;
}

.why-choose-one__list-single .icon {
    position: absolute;
    top: 0;
    left: 0;
}

.why-choose-one__list-single .icon {
    width: 35px;
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    border-radius: 50%;
    background-color: var(--thm-base);
}

.why-choose-one__list-single .icon i::before {
    font-size: 15px;
    line-height: 45px;
    color: #fff;
}

.why-choose-one__list-single .text {
    position: relative;
    display: block;
    padding-left: 65px;
}

.why-choose-one__list-single .text h3 {
    color: #2e2e36;
    font-size: 22px;
    line-height: 32px;
    font-weight: 800;
    margin-bottom: 7px;
}

.why-choose-one__list-single .text p {
    margin: 0;
}

.why-choose-one__img {
    position: relative;
    display: block;
    overflow: hidden;
    margin-left: 75px;
    border-radius: 15px;
}

.why-choose-one__img::before {
    position: absolute;
    top: 50%;
    left: 50%;
    display: block;
    content: '';
    width: 0;
    height: 0;
    background: rgba(255, 255, 255, .2);
    border-radius: 50%;
    transform: translate(-50%, -50%);
    opacity: 0;
    z-index: 10;
}

.why-choose-one__img:hover:before {
    -webkit-animation: circle .75s;
    animation: circle .75s;
}



.why-choose-one-team {
    position: relative;
    display: block;
    background-attachment: scroll;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
}

.why-choose-one-team-details {
    position: relative;
    display: block;
    padding: 0px 0px 120px;
}

.why-choose-one-team-details .why-choose-one__img {
    position: relative;
    display: block;
    overflow: hidden;
    border-radius: 7px;
}

.why-choose-one-team-details h2 {
    color: #2e2e36;
    font-size: 30px;
    line-height: 40px;
    font-weight: 800;
    margin-bottom: 16px;
}

.why-choose-one-team-details p {
    margin: 0;
    margin-bottom: 35px;
}



/***
=============================================
    Services Details
=============================================
***/
.services-details {
    position: relative;
    display: block;
    background: #ffffff;
    padding: 120px 0px 110px;
}

.services-details__sidebar {
    position: relative;
    display: block;
}

.services-details__services-list-box {
    position: relative;
    display: block;
    padding: 32px 40px 26px;
    border-radius: 7px;
    background-color: rgb(255, 255, 255);
    box-shadow: 0px 10px 60px 0px rgb(207, 207, 207, 0.5);
}

.services-details__sidebar-title {
    color: #2e2e36;
    font-size: 24px;
    line-height: 32px;
    font-weight: 800;
    margin-bottom: 23px;
}

.services-details__services-list {
    position: relative;
    display: block;
    overflow: hidden;
}

.services-details__services-list li {
    position: relative;
    display: block;
    font-size: 18px;
    line-height: 32px;
    font-weight: 500;
    padding-top: 10px;
    margin-bottom: 10px;
}

.services-details__services-list li:last-child {
    margin-bottom: 0;
}

.services-details__services-list li::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    background: #ededed;
    height: 1px;
    width: 100%;
}

.services-details__services-list li::after {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 1px;
    transform: scaleX(0);
    background: var(--thm-base);
    content: "";
    z-index: 2;
    transition: all 0.4s linear;
}

.services-details__services-list li:hover:after {
    transform: scaleX(1.0);
}

.services-details__services-list li a {
    position: relative;
    color: #74787c;
    display: block;
    padding: 13px 0px 4px;
    transition: all 200ms linear;
    transition-delay: 0.1s;
}

.services-details__services-list li a:hover {
    color: var(--thm-base);
}

.services-details__services-list li a i::before {
    position: absolute;
    top: 10px;
    bottom: 0;
    display: flex;
    align-items: center;
    right: 0;
    font-size: 15px;
}

.services-details__services-conatct-box {
    position: relative;
    display: block;
    padding: 32px 50px 40px;
    border-radius: 7px;
    background-color: #ffffff;
    box-shadow: 0px 10px 60px 0px rgb(207, 207, 207, 0.5);
    margin-top: 40px;
}


.services-details__services-conatct-box form input[type="email"],
.services-details__services-conatct-box form textarea {
    position: relative;
    display: block;
    background: #f4f4f5;
    width: 100%;
    height: 60px;
    border: none;
    color: #74787c;
    font-size: 15px;
    font-weight: 500;
    padding: 0 30px;
    margin-bottom: 10px;
    border-radius: 7px;
    transition: all 500ms ease;
    outline: none;
}

.services-details__services-conatct-box form textarea {
    height: 150px;
    padding: 15px 30px;
    margin-bottom: 10px;
}

.services-details__services-conatct-box .conatct-one {
    outline: none;
    border: none;
    padding: 0;
    border-radius: 7px;
}

.services-details__services-brochures-box {
    position: relative;
    display: block;
    background: #ffffff;
    box-shadow: 0px 10px 60px 0px rgb(207, 207, 207, 0.5);
    padding: 32px 50px 25px;
    margin-top: 40px;
    border-radius: 7px;
}

.brochures-btn {
    position: relative;
    display: block;
}

.brochures-btn .button {
    position: relative;
    display: inline-block;
    padding: 17px 25px 18px;
    background: #f4f4f5;
    border-radius: 7px;
    width: 100%;
    color: #2e2e36;
    font-size: 16px;
    line-height: 26px;
    font-weight: 700;
    font-family: var(--thm-manrope-font);
    transition: all 200ms linear;
    transition-delay: 0.1s;
    margin-bottom: 15px;
}

.brochures-btn .button:hover {
    color: #ffffff;
    background: var(--thm-base);
}

.brochures-btn .button .icon {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 25px;
    display: flex;
    align-items: center;
    font-size: 20px;
    color: #2e2e36;
    font-weight: 700;
    transition: all 200ms linear;
    transition-delay: 0.1s;
}

.brochures-btn .button:hover .icon {
    color: #ffffff;
}



.services-details-content {
    position: relative;
    display: block;
    margin-left: 68px;
}

.services-details__text-box1 {
    position: relative;
    display: block;
    margin-bottom: 40px;
}

.services-details__text-box1 h2 {
    color: #2e2e36;
    font-size: 40px;
    line-height: 50px;
    font-weight: 800;
    margin-bottom: 25px;
}

.services-details__text-box1 p {
    margin: 0;
}

.services-details-img1 {
    position: relative;
    display: block;
    overflow: hidden;
    border-radius: 7px;
}

.services-details-img1::before {
    position: absolute;
    top: 50%;
    left: 50%;
    display: block;
    content: '';
    width: 0;
    height: 0;
    background: rgba(255, 255, 255, .2);
    border-radius: 50%;
    transform: translate(-50%, -50%);
    opacity: 0;
    z-index: 10;
}

.services-details-img1:hover:before {
    -webkit-animation: circle .75s;
    animation: circle .75s;
}

.services-details-img1 img {
    position: relative;
    width: 100%;
    transition: all 0.5s ease 0s;
}

.services-details-img1:hover img {
    transform: scale(1.1);
}

.services-details__text-box2 {
    position: relative;
    display: block;
    margin-top: 33px;
}

.services-details__text-box2 h2 {
    color: #2e2e36;
    font-size: 27px;
    line-height: 32px;
    font-weight: 700;
    margin-bottom: 20px;
}

.services-details__text-box2 p {
    margin: 0;
}

.services-details__text-box2-list {
    position: relative;
    display: block;
    overflow: hidden;
    margin-top: 25px;
    margin-bottom: 47px;
}

.services-details__text-box2-list li {
    position: relative;
    display: block;
    margin-bottom: 13px;
}

.services-details__text-box2-list li:last-child {
    margin-bottom: 0;
}

.services-details__text-box2-list li .icon {
    position: absolute;
    top: 2px;
    left: 0;
    font-size: 25px;
    color: var(--thm-base);
}



.services-details__text-box2-list li .text {
    position: relative;
    display: block;
    padding-left: 40px;
}

.services-details__text-box2-list li .text p {
    margin: 0;
}

.services-details-img2 {
    position: relative;
    display: block;
    overflow: hidden;
    border-radius: 7px;
    margin-bottom: 30px;
}

.services-details-img2::before {
    position: absolute;
    top: 50%;
    left: 50%;
    display: block;
    content: '';
    width: 0;
    height: 0;
    background: rgba(255, 255, 255, .2);
    border-radius: 50%;
    transform: translate(-50%, -50%);
    opacity: 0;
    z-index: 10;
}

.services-details-img2:hover:before {
    -webkit-animation: circle .75s;
    animation: circle .75s;
}

.services-details-img2 img {
    position: relative;
    width: 100%;
    transition: all 0.5s ease 0s;
}

.services-details-img2:hover img {
    transform: scale(1.1);
}

.services-details__text-box3 {
    position: relative;
    display: block;
}

.services-details__text-box3-text1 {
    color: #2e2e36;
    font-size: 20px;
    line-height: 35px;
    font-weight: 600;
    margin: 0;
    font-family: var(--thm-manrope-font);
    margin-bottom: 15px;
}

.services-details__text-box3 p {
    margin: 0;
    margin-top: 15px;
}




/***
=============================================
    Team Details One
=============================================
***/
.team-details-one {
    position: relative;
    display: block;
    padding: 120px 0px 120px;
}

.team-details-one__left {
    position: relative;
    display: block;
    max-width: 525px;
    width: 100%;
}

.team-details-one__left-img {
    position: relative;
    display: block;
}

.team-details-one__left-title {
    position: relative;
    display: block;
    border-radius: 7px;
    background-color: #ffffff;
    box-shadow: 0px 10px 60px 0px rgb(207, 207, 207, 0.6);
    padding: 40px 0px 50px;
    margin-left: 45px;
    margin-right: 45px;
    margin-top: -60px;
}

.team-details-one__left-title h2 {
    font-size: 35px;
    line-height: 45px;
    font-weight: 800;
    margin-bottom: 4px;
}

.team-details-one__left-title h2 a {
    color: #2e2e36;
    transition: all 200ms linear;
    transition-delay: 0.1s;
}

.team-details-one__left-title h2 a:hover {
    color: var(--thm-base);
}

.team-details-one__left-title p {
    color: var(--thm-base);
    font-size: 15px;
    line-height: 25px;
    font-weight: 500;
    margin: 0;
}

.team-details-one__left-title .social-icon {
    position: relative;
    display: block;
    overflow: hidden;
    margin-top: 22px;
}

.team-details-one__left-title .social-icon li {
    position: relative;
    display: inline-block;
    margin-right: 4px;
}

.team-details-one__left-title .social-icon li:last-child {
    margin-right: 0;
}

.team-details-one__left-title .social-icon li a {
    position: relative;
    display: inline-block;
    width: 35px;
    height: 35px;
    line-height: 35px;
    background: #f4f4f5;
    border-radius: 50%;
    text-align: center;
    color: #74787c;
    font-size: 15px;
    transition: all 200ms linear;
    transition-delay: 0.1s;
}

.team-details-one__left-title .social-icon li a:hover {
    color: #ffffff;
    background: var(--thm-base);
}



.team-details-one__right {
    position: relative;
    display: block;
}

.team-details-one__right-top-text {
    position: relative;
    display: block;
}

.team-details-one__right-top-text h2 {
    color: #2e2e36;
    font-size: 35px;
    line-height: 45px;
    font-weight: 800;
    margin-bottom: 10px;
}

.team-details-one__right-top-text p {
    margin: 0;
}

.team-details-one__right-bottom {
    position: relative;
    display: block;
    margin-top: 42px;
}

.team-details-one__right-bottom .title {
    position: relative;
    display: block;
}

.team-details-one__right-bottom .title h3 {
    color: #2e2e36;
    font-size: 24px;
    line-height: 32px;
    font-weight: 800;
    margin-bottom: 13px;
}

.team-details-one__right-bottom .title p {
    margin: 0;
}


.progress-box {
    position: relative;
    display: block;
    max-width: 375px;
    width: 100%;
    margin-top: 45px;
}

.progress-block {
    position: relative;
    display: block;
}



.progress-block .inner-box {
    position: relative;
    text-align: center;
    margin: 0 auto 40px;
}

.progress-block .graph-outer {

    display: inline-block;
    text-align: center;
    background: #fff;
    border-radius: 50%;
}

.progress-block .graph-outer .count-box {
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    margin-top: -30px;
    color: #2e2e36;
    font-size: 45px;
    font-weight: 800;
    transform: translateY(-27%);
}

.progress-block .graph-outer .count-text {
    position: relative;
    display: inline-block;
    color: #2e2e36;
    font-size: 45px;
    line-height: 45px;
    font-weight: 800;
    font-family: var(--thm-manrope-font);
    letter-spacing: -.03em;
}

.progress-block .graph-outer .count-Parsent {
    font-size: 20px;
    transform: rotate(18deg);
    position: relative;
    display: inline-block;
    font-weight: 800;
}

.progress-block .graph-outer .title {
    position: relative;
    display: block;
}

.progress-block .graph-outer .title h5 {
    color: #2e2e36;
    font-size: 16px;
    line-height: 26px;
    font-weight: 700;
}



/***
=============================================
    Portfolio Grid
=============================================
***/
.case-studies-one--portfolio-grid {
    position: relative;
    display: block;
    padding: 120px 0px 90px;
    background: #ffffff;
    border-bottom: 1px solid #ededed;
}

.case-studies-one--portfolio-grid__top {
    position: relative;
    display: block;
    margin-bottom: 50px;
}

.case-studies-one--portfolio-grid__menu-box {
    position: relative;
    display: block;
    overflow: hidden;
}

.case-studies-one--portfolio-grid__menu-box ul {
    position: relative;
    display: block;
    overflow: hidden;
}

.case-studies-one--portfolio-grid__menu-box ul .active {
    color: #ffffff;
    background-color: var(--thm-base);
}

.case-studies-one--portfolio-grid__menu-box ul li {
    position: relative;
    float: left;
    color: #ffffff;
    font-size: 16px;
    line-height: 28px;
    font-weight: 600;
    color: #2e2e36;
    padding: 2px 15px 2px;
    cursor: pointer;
    transition: all 0.4s linear;
    border-radius: 5px;
    margin-right: 2px;
}

.case-studies-one--portfolio-grid__menu-box ul li:last-child {
    margin-right: 0;
}

.case-studies-one--portfolio-grid__menu-box ul li:hover {
    color: #ffffff;
    background: var(--thm-base);
}

.case-studies-one--portfolio-grid__menu-box li .count {
    display: none;
}

.case-studies-one--portfolio-grid .case-studies-one__single {
    position: relative;
    display: block;
}

.case-studies-one--portfolio-grid .case-studies-one__single::before {
    height: 85%;
}

.case-studies-one--portfolio-grid .case-studies-one__single::after {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 0;
    border-radius: 5px;
    transition: .5s;
    transform: perspective(400px) scale(0);
    transform-origin: bottom center;
    z-index: 1;
    content: "";
    height: 85%;
    border-radius: 7px;
    background-image: linear-gradient(0deg, rgb(249, 77, 28) 0%, rgba(135, 56, 39, 0.5) 53%, rgba(34, 38, 49, 0) 100%);
}

.case-studies-one--portfolio-grid .case-studies-one__single:hover::after {
    opacity: 1;
    transform: perspective(400px) scale(1.0);
}

.case-studies-one--portfolio-grid .case-studies-one__single::before {
    transition: all 200ms linear;
    transition-delay: 0.1s;
}

.case-studies-one--portfolio-grid .case-studies-one__single:hover::before {
    opacity: 0;
}




/***
=============================================
    Faq One
=============================================
***/
.faq-one {
    position: relative;
    display: block;
    background: #ffffff;
    padding: 120px 0px 120px;
}

.faq-one .section-title {
    margin-bottom: 59px;
}

.faq-one__content-box {
    position: relative;
    display: block;
}

.faq-one__content-box .accrodion {
    position: relative;
    display: block;
    border-radius: 7px;
    background-color: rgb(255, 255, 255);
    box-shadow: 0px 10px 60px 0px rgb(211, 211, 211);
    margin-bottom: 10px;
    transition: all 500ms ease;
}

.faq-one__content-box .accrodion.last-chiled {
    margin-bottom: 0;
}

.faq-one__content-box .accrodion.active::before {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    content: "";
    width: 5px;
    height: 100%;
    background: var(--thm-base);
    border-radius: 7px;
    transition: all 500ms ease;
}

.faq-one__content-box .accrodion .accrodion-title {
    position: relative;
    display: block;
    padding: 15px 40px 15px;
    cursor: pointer;
    transition: all 200ms linear;
    transition-delay: 0.1s;
}

.faq-one__content-box .accrodion.active .accrodion-title {
    padding: 15px 40px 5px;
}

.faq-one__content-box .accrodion .accrodion-title h4 {
    position: relative;
    color: #1b2336;
    font-size: 18px;
    line-height: 32px;
    font-weight: 800;
    transition: all 500ms ease;
}

.faq-one__content-box .accrodion .accrodion-title h4::before {
    content: "\e919";
    font-family: 'icomoon' !important;
    font-weight: 900;
    font-size: 12px;
    color: var(--thm-base);
    ;
    position: absolute;
    top: 50%;
    right: 0;
    text-align: center;
    transform: translateY(-50%);
    transition: all 500ms ease;
}

.faq-one__content-box .accrodion.active .accrodion-title h4::before {
    content: "\e901";
    font-family: 'icomoon' !important;
    color: var(--thm-base);
}

.faq-one__content-box .accrodion .accrodion-content {
    position: relative;
    padding: 0px 40px 30px;
}

.faq-one__content-box .accrodion .accrodion-content .inner {
    position: relative;
    display: block;
}

.faq-one__content-box .accrodion .accrodion-content .inner p {
    margin: 0;
}

.faq-one__right {
    position: relative;
    display: block;
}

.faq-one__right-img {
    position: relative;
    display: block;
    border-radius: 7px;
}

.faq-one__right-img img {
    width: 100%;
}

.faq-one__right-img::before {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: #111119;
    opacity: 0.75;
    content: "";
    border-radius: 7px;
}

.faq-one__right-img-overlay {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;
}

.faq-one__right-img .section-title__tagline {
    margin-bottom: 5px;
}

.faq-one__right-img .section-title {
    margin-bottom: 35px;
}

.faq-one__right-img .section-title .section-title__title {
    color: #ffffff;
    font-size: 35px;
    line-height: 45px;
    font-weight: 600;
}


/***
=============================================
    Contact Form One
=============================================
***/
.contact-form-one {
    position: relative;
    display: block;
    background: #fafafa;
    padding: 120px 0px 120px;
    background-attachment: scroll;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
}

.contact-form-one .section-title {
    margin-bottom: 59px;
}

.contact-form-one .contact-form {
    position: relative;
    display: block;
}

.contact-form-one .contact-form {
    position: relative;
    display: block;
    width: 100%;
}

.contact-form-one .contact-form form {
    position: relative;
    display: block;
}

.contact-form-one .contact-form form .input-box {
    position: relative;
    display: block;
    margin-bottom: 30px;
}

.contact-form-one .contact-form form input[type="text"],
.contact-form-one .contact-form form input[type="email"],
.contact-form-one .contact-form form textarea {
    position: relative;
    display: block;
    background: #ffffff;
    border: 1px solid #ffffff;
    width: 100%;
    height: 60px;
    color: #2e2e36;
    font-size: 15px;
    font-weight: 700;
    font-style: normal;
    padding-left: 40px;
    padding-right: 30px;
    border-radius: 7px;
    box-shadow: 0px 10px 60px 0px rgb(211, 211, 211, 0.5);
    transition: all 500ms ease;
    font-family: var(--thm-manrope-font);
}

.contact-form-one .contact-form form textarea {
    height: 185px;
    padding-top: 10px;
    padding-left: 40px;
    padding-right: 30px;
}

.contact-form-one .contact-form form input[type="text"]:focus {
    outline: none;
}

.contact-form-one .contact-form form input[type="email"]:focus {
    outline: none;
}

.contact-form-one .contact-form form textarea {
    outline: none;
}

.contact-form-one .contact-form form input[type="text"]::-webkit-input-placeholder {
    color: #2e2e36;
}

.contact-form-one .contact-form form input[type="text"]:-moz-placeholder {
    color: #2e2e36;
}

.contact-form-one .contact-form form input[type="text"]::-moz-placeholder {
    color: #2e2e36;
}

.contact-form-one.contact-form form input[type="text"]:-ms-input-placeholder {
    color: #2e2e36;
}

.contact-form-one .contact-form form input[type="email"]::-webkit-input-placeholder {
    color: #2e2e36;
}

.contact-form-one .contact-form form input[type="email"]:-moz-placeholder {
    color: #2e2e36;
}

.contact-form-one .contact-form form input[type="email"]::-moz-placeholder {
    color: #2e2e36;
}

.contact-form-one .contact-form form input[type="email"]:-ms-input-placeholder {
    color: #2e2e36;
}

.contact-form-one .contact-form form textarea::-webkit-input-placeholder {
    color: #2e2e36;
}

.contact-form-one .contact-form form textarea:-moz-placeholder {
    color: #2e2e36;
}

.contact-form-one .contact-form form textarea::-moz-placeholder {
    color: #2e2e36;
}

.contact-form-one .contact-form form textarea:-ms-input-placeholder {
    color: #2e2e36;
}




.contact-form-one .input-box .bootstrap-select:not([class*="col-"]):not([class*="form-control"]):not(.input-group-btn) {
    width: 100% !important;
}

.contact-form-one .input-box .bootstrap-select>.dropdown-toggle::after {
    display: none;
}

.contact-form-one .input-box .bootstrap-select>.dropdown-toggle {
    height: 60px;
    outline: none !important;
    border: 1px solid #ffffff;
    background-color: #ffffff !important;
    margin: 0;
    padding: 0;
    padding-left: 30px;
    padding-right: 30px;
    color: #2e2e36 !important;
    font-size: 15px;
    line-height: 60px;
    font-weight: 700;
    padding-left: 40px !important;
    padding-right: 30px !important;
    border-radius: 7px !important;
    box-shadow: 0px 10px 60px 0px rgb(211, 211, 211, 0.5) !important;
    transition: all 500ms ease;
    font-family: var(--thm-manrope-font) !important;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-size: 16px 12px;
    background-position: right 25.75px center;
}

.contact-form-one .button-box {
    position: relative;
    display: block;
}

.contact-form-one .button-box button {
    outline: none;
    border: none;
    padding: 0;
}

/***
=============================================
    Category One
=============================================
***/
.category-one {
    position: relative;
    display: block;
    background: #ffffff;
    padding: 120px 0px 90px;
}

.category-one__single {
    position: relative;
    display: block;
    background: #fafafa;
    border-radius: 7px;
    padding: 40px 0px 40px;
    margin-bottom: 30px;
    z-index: 1;
    transition: all 200ms linear;
    transition-delay: 0.1s;
}

.category-one__single::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 0;
    background: var(--thm-base);
    opacity: 1.0;
    transition: .5s;
    transform: perspective(400px) scaleY(0);
    transform-origin: bottom;
    z-index: -1;
}

.category-one__single:hover:before {
    opacity: 1;
    transform: perspective(400px) scaleY(1.0);
}

.category-one__single .shape1 {
    position: absolute;
    left: 25px;
    bottom: 20px;
    z-index: -1;
    opacity: 0;
    transition: all 200ms linear;
    transition-delay: 0.1s;
}

.category-one__single .shape1 {
    opacity: 1;
}

.category-one__single .shape2 {
    position: absolute;
    top: 35px;
    left: 40px;
    z-index: -1;
    opacity: 0;
    transition: all 200ms linear;
    transition-delay: 0.1s;
}

.category-one__single:hover .shape2 {
    opacity: 1;
}

.category-one__single .shape3 {
    position: absolute;
    right: 20px;
    bottom: 20px;
    z-index: -1;
    opacity: 0;
    transition: all 200ms linear;
    transition-delay: 0.1s;
}

.category-one__single:hover .shape3 {
    opacity: 1;
}

.category-one__single-icon {
    position: relative;
    display: inline-block;
    margin-bottom: 6px;
}

.category-one__single-icon span::before {
    font-size: 55px;
    color: #2e2e36;
    transition: all 200ms linear;
    transition-delay: 0.1s;
}

.category-one__single:hover .category-one__single-icon span::before {
    color: #ffffff;
}

.category-one__single-text {
    position: relative;
    display: block;
}

.category-one__single-text h3 {
    font-size: 22px;
    line-height: 32px;
    font-weight: 700;
}

.category-one__single-text h3 a {
    color: #2e2e36;
    transition: all 200ms linear;
    transition-delay: 0.1s;
}

.category-one__single:hover .category-one__single-text h3 a {
    color: #ffffff;
}



/***
=============================================
    Jobs One
=============================================
***/
.jobs-one {
    position: relative;
    display: block;
    background: #fafafa;
    padding: 120px 0px 120px;
    background-attachment: scroll;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
}

.jobs-one__content-box {
    position: relative;
    display: block;
}

.jobs-one__single {
    position: relative;
    display: block;
    border-radius: 7px;
    background-color: #ffffff;
    box-shadow: 5px 8.66px 30px 0px rgb(215, 215, 215);
    padding: 32px 40px 32px;
    margin-bottom: 30px;
    z-index: 1;
}

.jobs-one__single::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 0;
    background: var(--thm-base);
    opacity: 1.0;
    transition: .5s;
    transform: perspective(400px) scaleY(0);
    transform-origin: bottom;
    z-index: -1;
}

.jobs-one__single:hover:before {
    opacity: 1;
    transform: perspective(400px) scaleY(1.0);
}

.jobs-one__single-title {
    color: #2e2e36;
    font-size: 24px;
    line-height: 34px;
    font-weight: 700;
    margin-bottom: 8px;
    transition: all 200ms linear;
    transition-delay: 0.1s;
}

.jobs-one__single:hover .jobs-one__single-title {
    color: #ffffff;
}

.jobs-one__single-title span {
    color: #696969;
    font-size: 16px;
    line-height: 26px;
    font-weight: 500;
    padding-left: 5px;
    transition: all 200ms linear;
    transition-delay: 0.1s;
}

.jobs-one__single:hover .jobs-one__single-title span {
    color: #ffffff;
}

.jobs-one__single-text {
    margin: 0;
    transition: all 200ms linear;
    transition-delay: 0.1s;
}

.jobs-one__single:hover .jobs-one__single-text {
    color: #ffffff;
}

.jobs-one__single-btn {
    position: relative;
    display: block;
    margin-top: 20px;
}

.jobs-one__single-btn .thm-btn {
    background: #ffffff;
    border: 1px solid #d7d7d7;
    color: #2e2e36;
    padding: 10px 30px 10px;
    transition: all 200ms linear;
    transition-delay: 0.1s;
}

.jobs-one__single-btn .thm-btn::after {
    background: transparent;
}

.jobs-one__content-box .jobs-one__btn {
    position: relative;
    display: block;
    margin-top: 40px;
}


/***
=============================================
    Blog Standard Css
=============================================
***/
.blog-standard-one {
    position: relative;
    display: block;
    background: #ffffff;
    padding: 120px 0px 60px;
}

.blog-standard-one__left {
    position: relative;
    display: block;
    max-width: 730px;
    width: 100%;
}

.blog-standard-one__single {
    position: relative;
    display: block;
    margin-bottom: 60px;
}

.blog-standard-one__single-img {
    position: relative;
    display: block;
    overflow: hidden;
    border-radius: 10px;
}

.blog-standard-one__single-img::before {
    position: absolute;
    content: "";
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%;
    opacity: 0;
    background: var(--thm-base);
    transition-delay: .1s;
    transition-timing-function: ease-in-out;
    transition-duration: .5s;
    transition-property: all;
    opacity: 1;
    transform-origin: bottom;
    transform-style: preserve-3d;
    transform: scaleY(0);
    z-index: 1;
}

.blog-details__left .blog-standard-one__single-img::before {
    display: none;
}

.blog-standard-one__single:hover .blog-standard-one__single-img::before {
    opacity: 0.70;
    transform: scaleY(1.0);
}

.blog-standard-one__single-img img {
    width: 100%;
    transform: scale(1);
    transition: transform .4s ease;
}

.blog-standard-one__single:hover .blog-standard-one__single-img img {
    transform: scale(1.05);
}

.blog-standard-one__single .meta-info {
    position: relative;
    display: block;
    overflow: hidden;
    margin-top: 32px;
    margin-bottom: 14px;
}

.blog-standard-one__single .meta-info li {
    position: relative;
    display: inline-block;
    margin-right: 20px;
}

.blog-standard-one__single .meta-info li:last-child {
    margin-right: 0;
}

.blog-standard-one__single .meta-info li a {
    color: var(--thm-gray);
    font-size: 16px;
    line-height: 26px;
    font-weight: 400;
    transition: all 200ms linear;
    transition-delay: 0.1s;
}

.blog-standard-one__single .meta-info li a:hover {
    color: var(--thm-base);
}

.blog-standard-one__single .meta-info li a i::before {
    position: relative;
    display: inline-block;
    padding-right: 5px;
}

.blog-standard-one__single-title {
    font-size: 30px;
    line-height: 40px;
    font-weight: 700;
}

.blog-standard-one__single-title a {
    color: #2e2e36;
    transition: all 200ms linear;
    transition-delay: 0.1s;
}

.blog-standard-one__single-title a:hover {
    color: var(--thm-base);
}

.blog-standard-one__single-text {
    margin: 0;
    margin-top: 16px;
}

.blog-standard-one__single-btn {
    position: relative;
    display: block;
    margin-top: 35px;
}

.blog-standard-one__single-btn .thm-btn {
    background: #f4f4f5;
    color: #2e2e36;
}

.blog-standard-one__single-btn .thm-btn::after {
    background: var(--thm-base);
}

.blog-standard-one__single-btn .thm-btn:hover {
    color: #ffffff;
}

.blog-standard-one__single-img.style2 {
    position: relative;
    display: block;
    z-index: 1;
}

.blog-standard-one__single-img.style2::before {
    display: none;
}

.blog-standard-one__single:hover .blog-standard-one__single-img.style2 img {
    transform: scale(1.0);
}

.blog-standard-one__single-img.style2::after {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: rgba(37, 37, 46, 0.75);
    content: "";
    z-index: 1;
}

.blog-standard-one__single-img.style2 .video-box {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    flex-wrap: wrap;
}

.blog-standard-one__single-img.style2 .video-box a {
    position: relative;
    display: inline-block;
    width: 90px;
    height: 90px;
    line-height: 90px;
    border-radius: 50%;
    text-align: center;
    background: var(--thm-base);
    font-size: 15px;
    color: #ffffff;
    z-index: 2;
}

.blog-standard-one__single-img.style2 .video-box a::before,
.blog-standard-one__single-img.style2 .video-box a::after {
    width: 90px;
    height: 90px;
    border-radius: 50%;
    background: transparent;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -webkit-animation-delay: .9s;
    animation-delay: .9s;
    content: "";
    position: absolute;
    box-shadow: 0 0 0 0 rgb(255 255 255 / 60%);
    -webkit-animation: ripple 3s infinite;
    animation: ripple 3s infinite;
    transition: all .4s ease;
}

.blog-standard-one__single-img.style2 .video-box a::after {
    -webkit-animation-delay: .6s;
    animation-delay: .6s;
}

.blog-standard-one__single-style2 {
    position: relative;
    display: block;
    background: #f4f4f5;
    padding: 10px 50px 50px;
    border-radius: 10px;
}

.blog-standard-one__single-style2 .blog-standard-one__single-btn .thm-btn {
    background-color: rgb(255, 255, 255);
    box-shadow: 5px 8.66px 30px 0px rgb(215, 215, 215, 0.4);
}

.blog-standard-one__single-style3 {
    position: relative;
    display: block;
    background-position: center center;
    background-attachment: scroll;
    background-size: cover;
    background-repeat: no-repeat;
    border-radius: 10px;
    padding: 10px 50px 50px;
    z-index: 2;
}

.blog-standard-one__single-style3::before {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: rgba(37, 37, 46, 0.85);
    content: "";
    border-radius: 10px;
    z-index: -1;
}

.blog-standard-one__single-btn-style2 {
    position: relative;
    display: block;
    margin-top: 35px;
}

.blog-standard-one__single-style3 .meta-info li a {
    color: #ffffff;
}

.blog-standard-one__single-style3 .blog-standard-one__single-title a {
    color: #ffffff;
    z-index: 1;
    transition: all 200ms linear;
    transition-delay: 0.1s;
}

.blog-standard-one__single-style3 .blog-standard-one__single-title a:hover {
    color: var(--thm-base);
}





.sidebar {
    position: relative;
    display: block;
    max-width: 330px;
    width: 100%;
}

.sidebar-search-box {
    position: relative;
    display: block;
    margin-bottom: 52px;
}

.sidebar-search-box form.search-form {
    position: relative;
    display: block;
    width: 100%;
}

.sidebar-search-box .search-form input[type="text"] {
    position: relative;
    display: block;
    width: 100%;
    height: 70px;
    background-color: #f4f4f5;
    border: 0px solid #f4f4f5;
    color: #2e2e36;
    font-size: 18px;
    font-weight: 500;
    padding-left: 30px;
    padding-right: 65px;
    border-radius: 7px;
    transition: all 500ms ease 0s;
}

.sidebar-search-box .search-form button {
    position: absolute;
    top: 10px;
    right: 10px;
    width: 50px;
    height: 50px;
    font-size: 15px;
    line-height: 50px;
    background: var(--thm-base);
    box-shadow: 0px 10px 30px 0px rgb(249, 77, 28, 0.3);
    display: block;
    text-align: center;
    border-radius: 7px;
    border: 0px solid #e7e7e8;
    transition: all 500ms ease 0s;
    padding: 0;
    outline: none;
    border: none;
}

.sidebar-search-box .search-form button i {
    font-size: 15px;
    color: #ffffff;
}

.sidebar-search-box .search-form input[type="text"]:focus {
    outline: none;
    border: none;
}

.sidebar-search-box .search-form input::-webkit-input-placeholder {
    color: #2e2e36;
}

.sidebar-search-box .search-form input:-moz-placeholder {
    color: #2e2e36;
}

.sidebar-search-box .search-form input::-moz-placeholder {
    color: #2e2e36;
}

.sidebar-search-box .search-form input:-ms-input-placeholder {
    color: #2e2e36;
}


.sidebar__single {
    position: relative;
    display: block;
    margin-bottom: 42px;
}

.sidebar__single-category {
    position: relative;
    display: block;
}

.sidebar__single-title {
    position: relative;
    display: block;
    color: #2e2e36;
    font-size: 24px;
    line-height: 32px;
    font-weight: 700;
    padding-bottom: 24px;
    margin-bottom: 25px;
}

.sidebar__single-title::before {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 1px;
    background: #e6e6e6;
    content: "";
}

.sidebar__single-category-list {
    position: relative;
    display: block;
    overflow: hidden;
}

.sidebar__single-category-list li {
    position: relative;
    display: block;
    font-size: 18px;
    line-height: 32px;
    font-weight: 500;
    margin-bottom: 10px;
}

.sidebar__single-category-list li:last-child {
    margin-bottom: 0;
}

.sidebar__single-category-list li a {
    position: relative;
    color: #2e2e36;
    display: block;
    transition: all 200ms linear;
    transition-delay: 0.1s;
}

.sidebar__single-category-list li a:hover {
    color: var(--thm-base);
}

.sidebar__single-category-list li a i::before {
    position: absolute;
    top: 0px;
    bottom: 0;
    display: flex;
    align-items: center;
    right: 0;
    font-size: 15px;
}

.sidebar__single-latest-news {
    position: relative;
    display: block;
}

.sidebar__single-latest-news .sidebar__single-title {
    margin-bottom: 29px;
}

.sidebar__single-latest-news-single {
    position: relative;
    display: block;
    margin-bottom: 13px;
}

.sidebar__single-latest-news-img {
    position: absolute;
    top: 6px;
    left: 0;
}

.sidebar__single-latest-news-text {
    position: relative;
    display: block;
    padding-left: 95px;
}

.sidebar__single-latest-news-text h4 {
    font-size: 17px;
    line-height: 25px;
    font-weight: 600;
}

.sidebar__single-latest-news-text h4 a {
    color: #2e2e36;
    transition: all 200ms linear;
    transition-delay: 0.1s;
}

.sidebar__single-latest-news-text h4 a:hover {
    color: var(--thm-base);
}

.sidebar__single-latest-news-text .meta-info {
    position: relative;
    display: block;
    overflow: hidden;
}

.sidebar__single-latest-news-text .meta-info li {
    position: relative;
    display: inline-block;
}

.sidebar__single-latest-news-text .meta-info li a {
    color: var(--thm-gray);
    font-size: 15px;
    line-height: 25px;
    font-weight: 400;
    transition: all 200ms linear;
    transition-delay: 0.1s;
}

.sidebar__single-latest-news-text .meta-info li a:hover {
    color: var(--thm-base);
}

.sidebar__single-latest-news-text .meta-info li a i::before {
    position: relative;
    display: inline-block;
    padding-right: 10px;
}



.sidebar__single-contact-box {
    position: relative;
    display: block;
    background: var(--thm-base);
    padding: 42px 40px 0px;
    border-radius: 7px;
    z-index: 1;
    margin-top: 54px;
}

.sidebar__single-contact-box .shape1 {
    position: absolute;
    bottom: 0;
    right: 0;
    z-index: -1;
}

.sidebar__single-contact-box .shape2 {
    position: absolute;
    bottom: 0;
    right: 0;
    z-index: -1;
}

.sidebar__single-contact-box h2 {
    color: #ffffff;
    font-size: 30px;
    line-height: 37px;
    font-weight: 700;
}

.sidebar__single-contact-box-btn {
    position: relative;
    display: block;
    margin-top: 23px;
    margin-bottom: 35px;
}

.sidebar__single-contact-box-btn .thm-btn {
    background: #ffffff;
    color: #2e2e36;
    padding: 5px 30px 5px;
}

.sidebar__single-contact-box-btn .thm-btn:hover {
    color: #ffffff;
}

.sidebar__single-contact-box-btn .thm-btn i::before {
    position: relative;
    font-size: 14px;
    top: 0px;
    padding-left: 5px;
}




.sidebar__single-popular-tags {
    position: relative;
    display: block;
}

.sidebar__single-popular-tags ul {
    position: relative;
    display: block;
    overflow: hidden;
    margin-left: -5px;
    margin-right: -5px;
    margin-top: 35px;
}

.sidebar__single-popular-tags ul li {
    position: relative;
    float: left;
    margin: 0px 5px 10px;
}

.sidebar__single-popular-tags ul li a {
    position: relative;
    display: block;
    background: #f4f4f5;
    color: var(--thm-gray);
    font-size: 15px;
    line-height: 24px;
    font-weight: 600;
    text-transform: none;
    padding: 5px 10px;
    border-radius: 5px;
    transition: all 200ms linear;
    transition-delay: 0.1s;
}

.sidebar__single-popular-tags ul li:hover a {
    background: var(--thm-base);
    color: #ffffff;
}

/***
=============================================
    Blog Details Css
=============================================
***/
.blog-details {
    position: relative;
    display: block;
    background: #ffffff;
    padding: 120px 0px 120px;
}

.blog-details__left {
    position: relative;
    display: block;
    max-width: 730px;
    width: 100%;
}

.blog-details__text1 {
    position: relative;
    display: block;
}

.blog-details__text1 p {
    margin: 0;
}

.blockquote-box {
    position: relative;
    display: block;
    background: #f4f4f5;
    padding: 42px 50px 43px;
    margin-top: 35px;
}

.blockquote-box::before {
    position: absolute;
    top: 0;
    left: 0;
    width: 4px;
    height: 100%;
    background: var(--thm-base);
    content: "";
}

.blockquote-box blockquote {
    position: relative;
    display: block;
    margin: 0;
}

.blockquote-box blockquote h3 {
    color: #2e2e36;
    font-size: 24px;
    line-height: 34px;
    font-weight: 700;
    margin-bottom: 14px;
}

.blockquote-box blockquote p {
    position: relative;
    display: block;
    color: #2e2e36;
    font-size: 18px;
    line-height: 28px;
    font-weight: 700;
    padding-left: 60px;
    margin: 0;
    font-family: var(--thm-manrope-font);
}

.blockquote-box blockquote p::before {
    content: "";
    position: absolute;
    top: 13px;
    left: 0;
    width: 40px;
    height: 3px;
    background: var(--thm-base);
}


.blog-details__text2 {
    position: relative;
    display: block;
    margin-top: 35px;
}

.blog-details__text2 p {
    margin: 0;
}


.tag-social-share-box {
    position: relative;
    overflow: hidden;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-content: center;
    flex-wrap: wrap;
    margin-top: 41px;
}

.tag-social-share-box .single-box {
    position: relative;
    display: block;
    overflow: hidden;
}

.tag-social-share-box .single-box .title {
    position: absolute;
    top: 0;
    left: 0;
    overflow: hidden;
}

.tag-social-share-box .single-box .title.right {
    text-align: right;
}

.tag-social-share-box .single-box .title h3 {
    color: #2e2e36;
    font-size: 17px;
    line-height: 27px;
    font-weight: 700;
}

.tag-social-share-box .tag-list {
    position: relative;
    display: block;
    overflow: hidden;
    padding-left: 117px;
}

.tag-social-share-box .tag-list li {
    position: relative;
    float: left;
    margin-right: 5px;
}

.tag-social-share-box .tag-list li:last-child {
    margin-right: 0;
}

.tag-social-share-box .tag-list li a {
    position: relative;
    display: inline-block;
    background: #f4f4f5;
    color: #121e2d;
    font-size: 15px;
    line-height: 30px;
    font-weight: 400;
    padding: 0 15px;
    border-radius: 5px;
    transition: all 200ms linear;
    transition-delay: 0.1s;
}

.tag-social-share-box .tag-list li a:hover {
    color: #ffffff;
    background: var(--thm-base);
}

.tag-social-share-box .social-share {
    position: relative;
    overflow: hidden;
    padding-left: 72px;
}

.tag-social-share-box .social-share li {
    position: relative;
    float: left;
    margin-right: 20px;
}

.tag-social-share-box .social-share li:last-child {
    margin-right: 0;
}

.tag-social-share-box .social-share li a {
    position: relative;
    display: inline-block;
    color: var(--thm-gray);
    font-size: 18px;
    line-height: 30px;
    transition: all 200ms linear;
    transition-delay: 0.1s;
}

.tag-social-share-box .social-share li a:hover {
    color: var(--thm-base);
}





.blog-details__author-box {
    position: relative;
    display: block;
    background: #f4f4f5;
    padding: 40px 50px 40px;
    border-radius: 7px;
    margin-top: 60px;
}

.blog-details__author-img {
    position: absolute;
    top: 0;
    left: 0px;
}



.blog-details__author-text-box {
    position: relative;
    display: block;
    padding-left: 200px;
}

.blog-details__author-box-inner {
    position: relative;
    display: block;
}

.blog-details__author-text-box h3 {
    color: #2e2e36;
    font-size: 22px;
    line-height: 32px;
    font-weight: 700;
    margin-bottom: 7px;
}

.blog-details__author-text-box p {
    margin: 0;
}

.blog-details__author-text-box .social-share {
    position: relative;
    display: block;
    overflow: hidden;
    margin-top: 5px;
}

.blog-details__author-text-box .social-share li {
    position: relative;
    float: left;
    margin-right: 20px;
}

.blog-details__author-text-box .social-share li a {
    position: relative;
    display: inline-block;
    color: var(--thm-gray);
    font-size: 15px;
    line-height: 25px;
    transition: all 200ms linear;
    transition-delay: 0.1s;
}

.blog-details__author-text-box .social-share li a:hover {
    color: var(--thm-base);
}





.blog-details__post {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    flex-direction: row;
    margin-top: 44px;
}

.blog-details__post-single {
    position: relative;
    display: block;
    margin-bottom: 13px;
}

.blog-details__post-single-img {
    position: absolute;
    top: 6px;
    left: 0;
}

.blog-details__post-single-text {
    position: relative;
    display: block;
    padding-left: 95px;
}

.blog-details__post-single-text h4 {
    font-size: 17px;
    line-height: 25px;
    font-weight: 700;
}

.blog-details__post-single-text h4 a {
    color: #2e2e36;
    transition: all 200ms linear;
    transition-delay: 0.1s;
}

.blog-details__post-single-text h4 a:hover {
    color: var(--thm-base);
}

.blog-details__post-single-text .meta-info {
    position: relative;
    display: block;
    overflow: hidden;
}

.blog-details__post-single-text .meta-info li {
    position: relative;
    display: inline-block;
}

.blog-details__post-single-text .meta-info li a {
    color: var(--thm-gray);
    font-size: 15px;
    line-height: 25px;
    font-weight: 400;
    transition: all 200ms linear;
    transition-delay: 0.1s;
}

.blog-details__post-single-text .meta-info li a:hover {
    color: var(--thm-base);
}

.blog-details__post-single-text .meta-info li a i::before {
    position: relative;
    display: inline-block;
    padding-right: 10px;
}




.comment-box {
    position: relative;
    display: block;
    overflow: hidden;
    padding-top: 53px;
}

.comment-box .title {
    position: relative;
    display: block;
    padding-bottom: 27px;
}

.comment-box .title h3 {
    color: #2e2e36;
    font-size: 24px;
    line-height: 32px;
    font-weight: 700;
}

.comment-box .outer-box {
    position: relative;
    display: block;
}

.comment-box .single-comment {
    position: relative;
    display: block;
    margin-bottom: 28px;
}

.comment-box .single-comment.comment-reply {
    border-bottom: none;
    margin-left: 60px;
}

.comment-box .single-comment-box {
    position: relative;
    display: block;
    padding-left: 100px;
}

.comment-box .single-comment-box .img-holder {
    position: absolute;
    left: 0;
    top: 16px;
}

.comment-box .single-comment-box .img-holder img {
    width: 100%;
    border-radius: 50%;
}

.comment-box .single-comment-box .text-holder {
    position: relative;
    display: block;
    padding-left: 30px;
}

.comment-box .single-comment-box .text-holder .top {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
}

.comment-box .single-comment-box .text-holder .top .name {
    position: relative;
    display: block;
}

.comment-box .single-comment-box .text-holder .top .name h3 {
    color: #2e2e36;
    font-size: 20px;
    line-height: 30px;
    font-weight: 700;
    margin: 0 0 2px;
}

.comment-box .single-comment-box .text-holder .top .date {
    position: relative;
    display: block;
}

.comment-box .single-comment-box .text-holder .top .date p {
    color: #2e2e36;
    font-size: 15px;
    line-height: 25px;
    margin: 0;
}

.comment-box .single-comment-box .text-holder .top .name h5 {
    color: #001d23;
    font-size: 12px;
    line-height: 18px;
    font-weight: 500;
}

.comment-box .single-comment-box .text-holder .top .name h5 span:before {
    position: relative;
    display: inline-block;
    padding-right: 6px;
    font-size: 12px;
}

.comment-box .single-comment-box .text-holder .top .reply {
    position: relative;
    display: block;
}

.comment-box .single-comment-box .text-holder .top .reply a span:before {
    position: relative;
    display: inline-block;
    padding-right: 5px;
    font-size: 14px;
}

.comment-box .single-comment-box .text-holder .text {
    position: relative;
    display: block;
    padding: 5px 0 0px;
}

.comment-box .single-comment-box .text-holder .text p {
    margin: 0;
}

.comment-box .single-comment-box .text-holder .reply a {
    color: #2e2e36;
    font-size: 15px;
    line-height: 25px;
    font-weight: 600;
    font-family: var(--thm-manrope-font);
    transition: all 200ms linear;
    transition-delay: 0.1s;
}

.comment-box .single-comment-box .text-holder .reply a:hover {
    color: var(--thm-base);
}

.comment-box .single-comment-box .text-holder .reply span::before {
    position: relative;
    display: inline-block;
    top: 2px;
    font-size: 13px;
    line-height: 10px;
    font-weight: 700;
    padding-left: 10px;
}



.add-comment-box {
    position: relative;
    display: block;
    overflow: hidden;
    padding-top: 42px;
}

.add-comment-box .title {
    position: relative;
    display: block;
    padding-bottom: 27px;
}

.add-comment-box .title h3 {
    color: #2e2e36;
    font-size: 24px;
    line-height: 34px;
    font-weight: 700;
}

.add-comment-box #add-comment-form {
    position: relative;
    display: block;
    padding: 41px 50px 50px;
    background: #f4f4f5;
    border-radius: 7px;
}

.add-comment-box #add-comment-form .input-box {
    position: relative;
    display: block;
}

.add-comment-box #add-comment-form .input-box .icon {
    position: absolute;
    top: 18px;
    right: 30px;
    font-size: 16px;
    color: var(--thm-base);
}

.add-comment-box #add-comment-form .input-box .icon span::before {
    position: relative;
    display: inline-block;
    font-weight: 700;
}

.add-comment-box #add-comment-form input[type="text"],
.add-comment-box #add-comment-form input[type="email"],
.add-comment-box #add-comment-form textarea {
    position: relative;
    display: block;
    background: #ffffff;
    border: 0px solid #eeeeee;
    width: 100%;
    height: 65px;
    color: #2e2e36;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    padding: 0 35px;
    padding-right: 50px;
    border-radius: 7px;
    margin-bottom: 10px;
    transition: all 500ms ease;
    font-family: var(--thm-manrope-font);
    outline: none;
}

.add-comment-box #add-comment-form textarea {
    height: 140px;
    padding: 20px 35px;
    padding-right: 50px;
}

.add-comment-box #add-comment-form .button-box {
    position: relative;
    display: block;
}

.add-comment-box #add-comment-form .button-box button {
    outline: none;
    padding: 0;
    border: none;
}

/***
=============================================
    Contact Box Css
=============================================
***/
.contact-box {
    position: relative;
    display: block;
    padding: 0px 0px 90px;
}

.contact-box__single {
    position: relative;
    display: block;
    margin-bottom: 30px;
}

.contact-box__single-icon {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    width: 90px;
    height: 90px;
    margin-left: auto;
    margin-right: auto;
    line-height: 90px;
    border-radius: 50%;
    color: #ffffff;
    font-size: 40px;
    text-align: center;
    background: #15b1c0;
    box-shadow: 0px 10px 30px 0px rgb(21, 177, 192, 0.5);
    z-index: 1;
}

.contact-box__single-text {
    position: relative;
    display: block;
    border-radius: 5px;
    background-color: rgb(255, 255, 255);
    box-shadow: 0px 0px 30px 0px rgb(234, 234, 234, 0.5);
    padding: 75px 0px 40px;
    margin-top: -45px;
}

.contact-box__single-text h2 {
    font-size: 24px;
    line-height: 34px;
    font-weight: 700;
    margin-bottom: 8px;
}

.contact-box__single-text h2 a {
    color: #2e2e36;
    transition: all 200ms linear;
    transition-delay: 0.1s;
}

.contact-box__single-text h2 a:hover {
    color: #15b1c0;
}

.contact-box__single-text p {
    margin: 0;
}

.contact-box__single.style2 {
    position: relative;
    display: block;
}

.contact-box__single.style2 .contact-box__single-icon {
    background: #f94d1c;
    box-shadow: 0px 10px 30px 0px rgb(249, 77, 28, 0.5);
}

.contact-box__single.style2 .contact-box__single-text h2 a:hover {
    color: #f94d1c;
}



.contact-box__single.style2 .contact-box__single-text p {
    margin: 0;
}

.contact-box__single.style2 .contact-box__single-text p a {
    color: #74787c;
    transition: all 200ms linear;
    transition-delay: 0.1s;
}

.contact-box__single.style2 .contact-box__single-text p a:hover {
    color: #f94d1c;
}

.contact-box__single.style3 .contact-box__single-icon {
    background: #d92300;
    box-shadow: 0px 10px 30px 0px rgb(217, 35, 0, 0.5);
}

.contact-box__single.style3 .contact-box__single-text h2 a:hover {
    color: #d92300;
}

.contact-box__single.style3 .contact-box__single-text p a {
    color: #74787c;
    transition: all 200ms linear;
    transition-delay: 0.1s;
}

.contact-box__single.style3 .contact-box__single-text p a:hover {
    color: #d92300;
    ;
}


/***
=============================================
    Google Map
=============================================
***/
.contact-page-google-map {
    position: relative;
    display: block;
    z-index: 3;
}

.contact-page-google-map__one {
    position: relative;
    display: block;
    border: none;
    height: 670px;
    width: 100%;
}



/***
=============================================
    Contact Page
=============================================
***/

.contact-page {
    position: relative;
    display: block;
}

.contact-page .section-title {
    margin-bottom: 59px;
}

.contact-page__inner {
    position: relative;
    display: block;
    border-radius: 12px;
    background: #ffffff;
    padding: 120px 210px 120px;
    margin-top: -100px;
    z-index: 5;
}



.contact-page .contact-page__inner form input[type="text"],
.contact-page .contact-page__inner form input[type="email"] {
    color: #2e2e36;
    font-size: 18px;
    font-weight: 700;
    width: 100%;
    height: 75px;
    background: #f4f4f5;
    border: 2px solid transparent;
    padding: 0 40px;
    margin-bottom: 30px;
    border-radius: 5px;
    outline: none;
    transition: all 200ms linear;
    transition-delay: 0.1s;
}

.contact-page .contact-page__inner form textarea {
    color: #2e2e36;
    font-size: 18px;
    font-weight: 700;
    width: 100%;
    height: 255px;
    border: 2px solid transparent;
    background: #f4f4f5;
    padding: 30px 40px 30px;
    border-radius: 5px;
    margin-bottom: 20px;
    outline: none;
    transition: all 200ms linear;
    transition-delay: 0.1s;
}

.contact-page .contact-page__inner form input[type="text"]:focus {
    color: #2e2e36;
    border-color: var(--thm-base);
    background: #ffffff;
}

.contact-page .contact-page__inner form input[type="email"]:focus {
    color: #2e2e36;
    border-color: var(--thm-base);
    background: #ffffff;
}

.contact-page .contact-page__inner form textarea:focus {
    color: #2e2e36;
    border-color: var(--thm-base);
    background-color: #ffffff;
}

.contact-page .contact-page__inner form input[type="text"]::-webkit-input-placeholder {
    color: #2e2e36;
}

.contact-page .contact-page__inner form input[type="text"]:-moz-placeholder {
    color: #2e2e36;
}

.contact-page .contact-page__inner form input[type="text"]::-moz-placeholder {
    color: #2e2e36;
}

.contact-page .contact-page__inner form input[type="text"]:-ms-input-placeholder {
    color: #2e2e36;
}

.contact-page .contact-page__inner form input[type="email"]::-webkit-input-placeholder {
    color: #2e2e36;
}

.contact-page .contact-page__inner form input[type="email"]:-moz-placeholder {
    color: #2e2e36;
}

.contact-page .contact-page__inner form input[type="email"]::-moz-placeholder {
    color: #2e2e36;
}

.contact-page .contact-page__inner form input[type="email"]:-ms-input-placeholder {
    color: #2e2e36;
}

.contact-page .contact-page__inner form textarea::-webkit-input-placeholder {
    color: #2e2e36;
}

.contact-page .contact-page__inner form textarea:-moz-placeholder {
    color: #2e2e36;
}

.contact-page .contact-page__inner form textarea::-moz-placeholder {
    color: #2e2e36;
}

.contact-page .contact-page__inner form textarea:-ms-input-placeholder {
    color: #2e2e36;
}

.contact-page__inner .contact-page__btn {
    position: relative;
    display: block;
}

.contact-page__inner .contact-page__btn button {
    position: relative;
    display: block;
    outline: none;
    border: none;
    padding: 0;
    margin: 0 auto;
}





/***
=============================================
    Error Page
=============================================
***/
.error-page {
    position: relative;
    display: block;
    padding: 120px 0px 120px;
    background: #ffffff;
}

.error-page__inner {
    position: relative;
    display: block;
}

.error-page__img {
    position: relative;
    display: block;
    width: 1100px;
}

.error-page__img img {
    width: 100%;
}

.error-page__content {
    position: relative;
    display: block;
    margin-top: 106px;
}

.error-page__content h2 {
    color: #2e2e36;
    font-size: 85px;
    line-height: 1em;
    font-weight: 700;
    margin-bottom: 27px;
}

.error-page__content h3 {
    color: #2e2e36;
    font-size: 30px;
    line-height: 40px;
    font-weight: 700;
}

.error-page__btn {
    position: relative;
    display: block;
    margin-top: 51px;
}



/***
=============================================
    History Timeline
=============================================
***/
.history-timeline {
    position: relative;
    display: block;
    padding: 120px 0px 120px;
}

@media(min-width: 1200px) {

    .history-timeline .container {
        max-width: 1000px;
    }
}

.history-timeline .section-title {
    margin-bottom: 60px;
}

.history-timeline .section-title__text {
    margin: 0;
    margin-top: 20px;
}

.history-timeline__card {
    position: relative;
    display: block;
}

.history-timeline__card .row {
    padding-top: 110px;
}

.history-timeline__card:after {
    content: '';
    width: 1px;
    background-color: #e9e9ea;
    position: absolute;
    transform: translateX(-50%);
    top: 0;
    bottom: 260px;
    left: 50%;
}

.history-timeline__year {
    position: relative;
    display: block;
    color: var(--thm-base);
    font-size: 52px;
    line-height: 0.8em;
    font-weight: 400;
    text-align: right;
    padding-right: 75px;
}

.history-timeline__year::before {
    content: "";
    position: absolute;
    top: 5px;
    width: 15px;
    height: 15px;
    right: -15px;
    background: var(--thm-base);
    border-radius: 50%;
    transform: translateY(50%) translateX(50%);
    left: auto;
    z-index: 1;
}

.history-timeline__year::after {
    content: "";
    position: absolute;
    top: -45px;
    right: -15px;
    width: 65px;
    height: 65px;
    border-radius: 50%;
    border: 2px solid var(--thm-base);
    transform: translateY(50%) translateX(50%);
    z-index: 1;
}

.history-timeline__year.style2 {
    position: relative;
    display: block;
    text-align: -webkit-auto;
    padding-left: 75px;
    padding-right: 0;
    text-align: left;
}

.history-timeline__year.style2::before {
    content: "";
    position: absolute;
    top: 5px;
    width: 15px;
    height: 15px;
    right: auto;
    left: -30px;
    background: var(--thm-base);
    border-radius: 50%;
    transform: translateY(50%) translateX(50%);
}

.history-timeline__year.style2::after {
    content: "";
    position: absolute;
    top: -45px;
    right: auto;
    left: -14px;
    width: 65px;
    height: 65px;
    border-radius: 50%;
    border: 3px solid var(--thm-base);
    transform: translateY(50%) translateX(-50%);
}

.history-timeline__content {
    position: relative;
    display: block;
    max-width: 463px;
    width: 100%;
    padding-left: 75px;
}

.history-timeline__content.style2 {
    position: relative;
    display: block;
    max-width: 463px;
    width: 100%;
    padding-right: 75px;
    padding-left: 0;
    text-align: right;
}

.history-timeline__content-title {
    color: #2e2e36;
    font-size: 22px;
    line-height: 32px;
    font-weight: 800;
}

.history-timeline__content-text {
    margin: 0;
    margin-top: 9px;
}

.history-timeline__img {
    position: relative;
    display: block;
    margin-top: 30px;
}

.history-timeline__img img {
    width: 100%;
}

































/* Tiny Scrollbar */
#scrollbar1 {
    width: 270px;
    margin: 0;
}

#scrollbar1 .viewport {
    position: relative;
    width: 270px;
    height: 530px;
    overflow: hidden;
}

#scrollbar1 .overview {
    list-style: none;
    position: absolute;
    left: 15px;
    top: 0;
    padding: 0;
    margin: 0;
    max-width: 500px;
    width: 100%;
    height: 100%;
}

#scrollbar1 .scrollbar {
    position: absolute;
    top: 0;
    right: 0px;
    background: #e7e7e7;
    width: 4px;
}

#scrollbar1 .track {
    background: transparent;
    height: 100%;
    width: 4px;
    position: relative;
    padding: 0;
}

#scrollbar1 .thumb {
    background: #fa401b;
    background-image: none;
    height: 20px;
    width: 4px;
    cursor: pointer;
    overflow: hidden;
    position: absolute;
    top: 0;
    left: 0px;
    opacity: 1;
    z-index: 3;
}

#scrollbar1 .thumb .end {
    background: #353742 no-repeat 50% 0;
    overflow: hidden;
    height: 0px;
    width: 4px;
}

#scrollbar1 .disable {
    display: none;
}



.services-single__text-box3 {
    position: relative;
    display: block;
}

.services-single__text-box3-left {
    position: relative;
    display: block;
    max-width: 270px;
    width: 100%;
    margin-left: -15px;
}

.service-details-features-item {
    position: relative;
    display: block;
    padding: 25px 20px 20px;
    transition: all 500ms ease;
}

.service-details-features-item:hover {
    box-shadow: 0px 8px 18px 0px rgba(214, 214, 214, 0.45);
}


.service-details-features-item .icon {
    position: relative;
    display: block;
    margin-bottom: 8px;
}

.service-details-features-item .icon span::before {
    color: #000000;
    font-size: 55px;
}

.service-details-features-item .title {
    position: relative;
    display: block;
}

.service-details-features-item .title h3 {
    color: #0c0d24;
    font-size: 24px;
    line-height: 30px;
    font-weight: 700;
    margin: 0 0 11px;
}

.service-details-features-item .title p {
    color: #0c0d24;
    font-size: 20px;
    line-height: 26px;
    font-weight: 400;
    margin: 0;
}



.service-details-features-content {
    position: relative;
    display: block;
    margin-top: -7px;
}

.service-details-features-content__text1 {
    color: #0c0d24;
    margin: 0;
    margin-bottom: 20px;
}

.service-details-features-content__text2 {
    color: #0c0d24;
    margin: 0;
    margin-bottom: 34px;
}

.service-details-features-content__img {
    position: relative;
    display: block;
}

.service-details-features-content__img img {
    width: 100%;
}
